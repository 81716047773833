import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthService, SessionHelper } from '../../../core';
import { ConfigService } from '../../../config/config.service';
import { COMPARE_TOOL_TOKEN_OVERRIDE, OC_Firm_Tokens } from './compare-tool';
import { IToken } from '../../../models/portfolio';
import { LoginAsService } from '../../../services/loginas.service';
import { catchError } from 'rxjs/operators';
import { SplitIoService } from '../../../core/feature-flag';

@Injectable()
export class CompareInterceptor implements HttpInterceptor {
  private readonly _loginAsService = inject(LoginAsService);
  private readonly _authService = inject(AuthService);
  private readonly _sessionHelper = inject(SessionHelper);
  private readonly _splitIoService = inject(SplitIoService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('X-Api-Origin') === 'OrionConnect' || req.url.includes('orionadvisor')) {
      return this._handleLoggedIn(req, next);
    }
    return next.handle(req);
  }

  private _handleLoggedIn(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestHeaderALClientId: number = Number(req.headers.get('x-api-al-client'));
    const currentTokenOCFirmId = this._authService.ocFirmId;

    const baseApiUrl = `${ConfigService.settings.orionAdvisorEndPoint}api/v1/`;
    const url = req.url.startsWith('/') ? req.url.slice(1) : req.url;
    let headers = req.headers;

    let tokenObservable: Observable<string> = of(this._authService.getToken().orion_access_token);

    // If the ALClientId from the request header doesn't match the ALClientId from the user's token
    // then get a token for the new ALClientId.
    if (this._splitIoService.featureFlags['TEXP_compare_tool_token_11699'] && !!requestHeaderALClientId && currentTokenOCFirmId !== requestHeaderALClientId) {
      tokenObservable = this.getOrionConnectToken(requestHeaderALClientId)
        .pipe(
          map((newToken: IToken) => newToken.orion_access_token),
        );
    }

    return tokenObservable
      .pipe(
        switchMap((token: string) => {
          if (headers.get('Authorization') === null) {
            headers = new HttpHeaders().set('Authorization', `Session ${token}`);
          } else if (headers.get('Authorization').toLowerCase() === 'bearer') {
            headers = headers.set('Authorization', `Bearer ${token}`);
          }

          const request = req.clone({
            url: url.startsWith('http') ? url : `${baseApiUrl}${url}`,
            headers: headers,
          });

          return next.handle(request);
        }),
      );
  }

  /**
   * Retrieves the token associated with an ALClientId.  If a token has already been resolved, the cached
   * version is returned.  Otherwise, a new token is requested and cached.
   * @param alClientId
   */
  getOrionConnectToken(alClientId: number): Observable<IToken> {
    const ocFirmTokens: OC_Firm_Tokens = this._sessionHelper.get<OC_Firm_Tokens>(COMPARE_TOOL_TOKEN_OVERRIDE) ?? <OC_Firm_Tokens>{};

    if (!!ocFirmTokens[alClientId]) {
      return of(ocFirmTokens[alClientId]);
    }

    return this._loginAsService.getFirmToken(alClientId)
      .pipe(
        tap((token: IToken) => {
          ocFirmTokens[alClientId] = token;
          this._sessionHelper.set(COMPARE_TOOL_TOKEN_OVERRIDE, ocFirmTokens);
        }),
        catchError(err => {
          return throwError(() => new HttpErrorResponse({
            error: err,
            status: 500,
            statusText: `An OrionConnect token could not be created.  Please check that access has been granted to the OrionConnect firm (${alClientId}).`,
          }));
        }),
      );
  }
}
