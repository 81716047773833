import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent } from "../../core/base.component";
import { EntityType } from "../../libs/preference.enums";
import { Table, TableModule } from "primeng/table";
import { EntityAction, IEntityMessage } from "../../shared/entity-editor";
import { IRelatedEntity, ITradeBlockDetail } from "../../models/tradeBlockDetails";
import { TradeBlockDetailsEditorComponent } from "./trade-block-details-editor.component";
import { MenuItem } from "primeng/api";
import { forkJoin, Subscription } from "rxjs";
import { TradeBlockDetailsService } from "../../services/trade-block-details.service";
import { TradeBlockReasonsService } from "../../services/trade-block-reasons.service";
import { ITradeBlockReason } from "../../models/trade-block-reasons";
import { ContextMenuModule } from "primeng/contextmenu";
import { RadioButtonModule } from "primeng/radiobutton";
import { FormsModule } from "@angular/forms";
import { DatePipe, NgIf } from "@angular/common";
import { SkeletonModule } from "primeng/skeleton";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";

@Component({
  selector: 'eclipse-trade-block-details-list',
  templateUrl: './trade-block-details-list.component.html',
  styleUrls: ['./trade-block-details-list.component.scss'],
  imports: [
    ContextMenuModule,
    TableModule,
    RadioButtonModule,
    FormsModule,
    DatePipe,
    SkeletonModule,
    DialogModule,
    TradeBlockDetailsEditorComponent,
    NgIf,
    ButtonModule,
    InputTextModule
  ],
  standalone: true
})
export class TradeBlockDetailsListComponent extends BaseComponent {
  @ViewChild('tradeBlockDetailsEditor') tradeBlockDetailsEditor: TradeBlockDetailsEditorComponent;
  @Input() entityId: number;
  @Input() entityType: EntityType;
  @ViewChild('dt') dt: Table;
  @ViewChild('tradeBlockDetailSummaryTemplate') tradeBlockDetailSummaryTemplate: TemplateRef<any>;
  @Output() onTradeBlockDetailTouched: EventEmitter<IEntityMessage> = new EventEmitter<IEntityMessage>();
  tradeBlockDetailsDialog = false; // Show the Trade Block Detail Editor dialog
  tradeBlockDetailsActiveFilter: 'Active' | 'All' = 'Active'; // Filter to show only Active trade block details or All trade block details.
  filteredTradeBlockDetails: ITradeBlockDetail[] = []; // Trade block details after the selected filter has been applied.
  selectedTradeBlockDetail: ITradeBlockDetail; // The trade block detail selected by the user.
  // entities related to the current entity.  Ex. if current entity is a portfolio, then relatedEntities has the portfolio,
  // all accounts assigned to the portfolio
  relatedEntities: IRelatedEntity[];
  loading: boolean = false; // Flag indicating the entity's trade block details are being loaded
  dataSubscription: Subscription; // Subscription for the entity trade block details load
  tradeBlockReasons: ITradeBlockReason[] = []; //Trade block reasons
  private _tradeBlockDetails: ITradeBlockDetail[] = []
  //All trade block details for the current entity (and related entities)
  public get tradeBlockDetails(): ITradeBlockDetail[] {
    return this._tradeBlockDetails;
  }
  public set tradeBlockDetails(value: ITradeBlockDetail[]) {
    this._tradeBlockDetails = value;
    this.setFilteredTradeBlockDetails();
  }
  constructor(private readonly _tradeBlockDetailsService: TradeBlockDetailsService, private readonly _tradeBlockReasonsService: TradeBlockReasonsService) {
    super();
  }
  /**
   * Load the tradeBlockDetails after the component and child components have rendered in case the entityId/entityType were bound.
   */
  ngAfterViewInit() {
    this.getTradeBlockDetails();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
  }

  changeActiveFilter() {
    this.setFilteredTradeBlockDetails();
  }

  /**
   * Opens the trade block detail editor with an existing trade block detail.
   * @param tradeBlockDetailToEdit
   */
  editTradeBlockDetail(tradeBlockDetailToEdit: ITradeBlockDetail) {
    this.tradeBlockDetailsDialog = true;
    this.tradeBlockDetailsEditor.editTradeBlockDetail(tradeBlockDetailToEdit);
  }

  /**
   * Deletes a trade block detail from the list.
   * @param tradeBlockDetailToDelete
   */
  deleteTradeBlockDetail(tradeBlockDetailToDelete: ITradeBlockDetail): void {
    this._tradeBlockDetailsService.deleteTradeBlockDetails([tradeBlockDetailToDelete.id])
      .subscribe(() => {
        const tradeBlockDetails = this.tradeBlockDetails.filter(tbd => tbd.id !== tradeBlockDetailToDelete.id);
        this.tradeBlockDetails = tradeBlockDetails;
        this.onTradeBlockDetailTouched.emit({
          relatedType: tradeBlockDetailToDelete.relatedType,
          relatedId: tradeBlockDetailToDelete.relatedTypeId,
          entityAction: EntityAction.TradeBlockDetails
        });
      });
  }

  getTradeBlockDetails(entity?: { entityId: number; entityType: EntityType }){
    this.dataSubscription?.unsubscribe();
    this.tradeBlockDetails = [];

    // if an entity is provided via the params rather than via binding, load the params entity.
    if (entity?.entityId && entity?.entityType) {
      this.entityId = entity.entityId;
      this.entityType = entity.entityType;
    }

    if (!this.entityId || !this.entityType) {
      return;
    }
    this.loading = true;
    // load the trade block details and the related entities
    this.dataSubscription = forkJoin([this._tradeBlockDetailsService.getTradeBlockDetailsByEntity(this.entityId, this.entityType),
      this._tradeBlockDetailsService.getRelatedEntities(this.entityId, this.entityType),
      this._tradeBlockReasonsService.getTradeBlockReasons()])
      .subscribe(([responseTradeBlockDetails, responseRelatedEntities, responseTradeBlockReasons]: [ITradeBlockDetail[], IRelatedEntity[], ITradeBlockReason[]]) => {
        this.tradeBlockDetails = responseTradeBlockDetails;
        this.tradeBlockReasons = responseTradeBlockReasons;
        this.mapTradeBlockReasons(this.tradeBlockDetails, responseTradeBlockReasons);
        this.relatedEntities = responseRelatedEntities;
        this.loading = false;
      });
  }
  /**
   * Filters the trade block details that are displayed in the table.
   */
  setFilteredTradeBlockDetails() {
    let filtered = this.tradeBlockDetails;

    this.filteredTradeBlockDetails = filtered?.filter(tbd =>
      this.tradeBlockDetailsActiveFilter === 'All' || tbd.isDeleted === false
    ) || [];
  }

  hideDialog() {
    this.tradeBlockDetailsDialog = false;
    this.dataSubscription?.unsubscribe();
  }

  /**
   * Creates a new trade block detail and opens the trade block detail editor with it.
   */
  addNewTradeBlockDetail() {
    const firmId = this._sessionHelper.getUser().firmId;
    this.tradeBlockDetailsDialog = true;
    this.tradeBlockDetailsEditor.addNewTradeBlockDetail();
  }

  /**
   * Sorts trade block details.  Newest start date comes first.
   * @param tradeBlockDetailsToSort
   */
  sortTradeBlockDetails(tradeBlockDetailsToSort: ITradeBlockDetail[]): ITradeBlockDetail[] {
    return tradeBlockDetailsToSort.sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      } else if (a.startDate < b.startDate) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Trade block detail editor has added/changed a trade block detail.
   * If the trade block detail did not exist before, it's added to the top of the array.
   * If a trade block detail already existed, update it in the list.
   * @param changedTradeBlockDetails
   */
  onTradeBlockDetailChanged(changedTradeBlockDetails: ITradeBlockDetail[]): void {
    let newTradeBlockDetails = Object.assign([], this.tradeBlockDetails);
    changedTradeBlockDetails.forEach(changedTradeBlockDetail => {
      const existingTradeBlockDetail = newTradeBlockDetails.find(tbd => tbd.id === changedTradeBlockDetail.id);
      if (!!existingTradeBlockDetail) {
        newTradeBlockDetails = newTradeBlockDetails.map(tbd => tbd.id !== changedTradeBlockDetail.id ? tbd : changedTradeBlockDetail);
      } else {
        newTradeBlockDetails.unshift(changedTradeBlockDetail); // New trade block detail was added, push it on top
      }
      newTradeBlockDetails = this.sortTradeBlockDetails(newTradeBlockDetails);
    });
    this.tradeBlockDetails = newTradeBlockDetails;
    this.tradeBlockDetailsDialog = false;
    if (!!changedTradeBlockDetails && changedTradeBlockDetails.length > 0) {
      this.mapTradeBlockReasons(this.tradeBlockDetails, this.tradeBlockReasons);
      this.onTradeBlockDetailTouched.emit({
        relatedType: changedTradeBlockDetails[0].relatedType,
        relatedId: changedTradeBlockDetails[0].relatedTypeId,
        entityAction: EntityAction.TradeBlockDetails
      });
    }
  }

  private mapTradeBlockReasons(tradeBlockDetails: ITradeBlockDetail[], tradeBlockReasons: ITradeBlockReason[]) {
    tradeBlockDetails.map(tbd => {
      const reason = tradeBlockReasons.find(tbr => tbr.id === tbd.tradeBlockReasonId);
      if (reason){
        tbd.tradeBlockReasonName = reason.name;
      }
    });
  }

  removeTradeBlockDetailsForEntity(entityType: EntityType) {
    const idsToDelete = this.tradeBlockDetails
      .filter(tbd => tbd.isDeleted === false && tbd.relatedType === entityType)
      .map(tbd => tbd.id);
    this._tradeBlockDetailsService.deleteTradeBlockDetails(idsToDelete)
      .subscribe(() => {});
  }

  addTradeBlockDetailsForEntity(entityType: EntityType, entityId: number, description: string){
    let manualReason = this.tradeBlockReasons.find(tbr => tbr.name === 'Manual');
    let detailToAdd = <ITradeBlockDetail>{
      relatedType: entityType,
      relatedTypeId: +entityId,
      startDate: new Date(),
      tradeBlockReasonId: manualReason.id,
      description: description
    };
    this._tradeBlockDetailsService.createTradeBlockDetails([detailToAdd])
      .subscribe((result) =>{});
  }
}
