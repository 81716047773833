import { IPortfolioAnalytics } from '../../models/portfolio';
import { AnalyticsStatus } from '../../libs/app.constants';
import moment from 'moment';
import { ANALYTICS_TRIGGER_NAME } from '../../libs/analytics.constants';
import { IAnalyticsTradeToolList } from '../../models/analytics';
import { OptionFilterType } from '../../models/tradetools';
import * as constants from '../../libs/app.constants';
import { IAnalyticsNotification } from '../../models/notification';

export class AnalyticsHelper {
  public static getDurationTimer(startDate: Date): string {
    // Get the current date and time
    const now = new Date();

    // Calculate the difference between the current date and time and the start date
    const diff = now.getTime() - startDate.getTime();

    // Convert the difference in milliseconds to seconds
    const seconds = Math.floor(diff / 1000) ?? 0;

    // Calculate the hours, minutes, and seconds from the total seconds
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

    // Format the hours, minutes, and seconds as a string
    const durationString = `${hours}:${minutes}:${remainingSeconds}`;

    return durationString;
  }

  public static getAnalyticsStatus(portfolioAnalytics: IPortfolioAnalytics[]): number {
    portfolioAnalytics = portfolioAnalytics ?? [];
    const needAnalyticsStatusSet = new Set();

    for (const portfolioAnalytic of portfolioAnalytics) {
      const analyticsStatus = Number(portfolioAnalytic?.needAnalytics);
      if (analyticsStatus !== undefined) {
        needAnalyticsStatusSet.add(analyticsStatus);
      }
    }

    if (!needAnalyticsStatusSet.size) {
      return null;
    }

    if (needAnalyticsStatusSet.has(AnalyticsStatus.Running)) {
      return AnalyticsStatus.Running;
    }

    if (needAnalyticsStatusSet.has(AnalyticsStatus.NeedAnalytics)) {
      return AnalyticsStatus.NeedAnalytics;
    }

    if (needAnalyticsStatusSet.has(AnalyticsStatus.Failed)) {
      return AnalyticsStatus.Failed;
    }

    return AnalyticsStatus.Good;
  }

  public static getRejectReason(portfolioAnalytics: IPortfolioAnalytics[], analyticsStatus: number): string {
    if (analyticsStatus !== AnalyticsStatus.Failed) {
      return '';
    }

    return portfolioAnalytics.find(
      portfolioAnalytic => portfolioAnalytic.needAnalytics === AnalyticsStatus.Failed
    )?.failedReason ?? '';
  }

  public static getStartDate(portfolioAnalytics: IPortfolioAnalytics[]): Date {
    return (portfolioAnalytics.length === 1
      ? AnalyticsHelper.convertUTCDateToLocalDate(portfolioAnalytics[0].editedDate)
      : AnalyticsHelper.getMaxTimeForMultiplePortfolio(portfolioAnalytics));
  }

  public static updatePortfolioDetails(portfolioAnalytics: IPortfolioAnalytics[], portfolioFlags: IPortfolioAnalytics[]): void {
    const portfolioFlagsMap = new Map(portfolioFlags.map((flag) => [flag.portfolioId, flag]));

    for (const portfolioAnalytic of portfolioAnalytics) {
      if (portfolioFlagsMap.has(portfolioAnalytic.portfolioId)) {
        const portfolioFlag = portfolioFlagsMap.get(portfolioAnalytic.portfolioId);
        portfolioAnalytic.needAnalytics = portfolioFlag.needAnalytics;
        portfolioAnalytic.failedReason = portfolioFlag.failedReason;
        portfolioAnalytic.editedDate = portfolioFlag.editedDate;
      }
    }
  }

  public static isClearInterval(isAnalyticsCompleted: boolean, portfolioAnalytics: IPortfolioAnalytics[]): boolean {
    const analyticsStatus = this.getAnalyticsStatus(portfolioAnalytics);
    return isAnalyticsCompleted && analyticsStatus !== AnalyticsStatus.Running;
  }

  public static convertUTCDateToLocalDate(date: Date): Date {
    return moment.utc(date ?? new Date()).toDate();
  }

  public static isShowSpinnerByTrigger(trigger: string): boolean {
    const triggers = [
      ANALYTICS_TRIGGER_NAME.REFRESH_ANALYTICS,
      ANALYTICS_TRIGGER_NAME.FULL_IMPORT,
      ANALYTICS_TRIGGER_NAME.PARTIAL_IMPORT,
      ANALYTICS_TRIGGER_NAME.BANNER_RUN_ANALYTICS_NOW,
      ANALYTICS_TRIGGER_NAME.DELTA_IMPORT,
    ];
    return triggers.includes(trigger);
  }

  public static isFullAnalytics(trigger: string): boolean {
    const triggers = [
      ANALYTICS_TRIGGER_NAME.REFRESH_ANALYTICS,
      ANALYTICS_TRIGGER_NAME.FULL_IMPORT,
      ANALYTICS_TRIGGER_NAME.PARTIAL_IMPORT,
      ANALYTICS_TRIGGER_NAME.DELTA_IMPORT,
    ];
    return triggers.includes(trigger);
  }

  public static getPortfolioAnalyticsDetailsFromTradeToolList(
    analyticsTradeToolList: IAnalyticsTradeToolList[],
    tradeFilterMethod: string): IPortfolioAnalytics[] {

    let portfolioAnalytics = [] as IPortfolioAnalytics[];
    if (!analyticsTradeToolList?.length) {
      return portfolioAnalytics;
    }
    if (tradeFilterMethod === OptionFilterType.Model || tradeFilterMethod === OptionFilterType.TradeGroup) {
      portfolioAnalytics = this.getPortfolioAnalyticsDetailsForModelAndTradeGroup(analyticsTradeToolList);
    } else {
      portfolioAnalytics = this.getPortfolioAnalyticsDetailsForPortfolioAndAccount(analyticsTradeToolList);
    }
    return portfolioAnalytics;
  }

  public static getNextButtonStatus(portfolioAnalytics: IPortfolioAnalytics[]): boolean {
    const analyticsStatus = this.getAnalyticsStatus(portfolioAnalytics);
    const isStatusForSingleItem = portfolioAnalytics.length === 1;

    return analyticsStatus && (analyticsStatus === constants.AnalyticsStatus.NeedAnalytics
      || analyticsStatus === constants.AnalyticsStatus.Running
      || (isStatusForSingleItem && analyticsStatus === constants.AnalyticsStatus.Failed));
  }

  public static getPortfolioAnalyticsDetailsForModelAndTradeGroup(analyticsTradeToolList: IAnalyticsTradeToolList[]): IPortfolioAnalytics[] {
    const portfolioAnalytics = [] as IPortfolioAnalytics[];
    for (const tradeToolElement of analyticsTradeToolList) {
      if (tradeToolElement?.portfolioFlags?.length) {
        const portfolioFlags = this.getPortfolioFlagObjects(tradeToolElement.portfolioFlags, portfolioAnalytics);
        portfolioAnalytics.push(...portfolioFlags);
      }
    }
    return portfolioAnalytics;
  }

  public static isValidNotification(analyticsNotification: IAnalyticsNotification): boolean {
    return analyticsNotification?.trigger && (analyticsNotification?.portfolioFlags?.length > 0 || analyticsNotification?.portfolioIds?.length > 0);
  }

  public static createDummyPortfolioFlags(analyticsNotification: IAnalyticsNotification): IPortfolioAnalytics[] {
    const portfolioIds = analyticsNotification.portfolioIds;
    const analyticsStatus = analyticsNotification.analyticsStatus;
    const analyticsStartDate = analyticsNotification.analyticsStartDate;

    return portfolioIds.map(portfolioId => ({
      portfolioId,
      needAnalytics: analyticsStatus,
      editedDate: analyticsStartDate
    } as IPortfolioAnalytics));
  }

  public static filterPortfolioIdsByStatuses(portfolioAnalytics: IPortfolioAnalytics[], statuses: number[]): number[] {
    return portfolioAnalytics
      .filter(portfolio => AnalyticsHelper.isPortfolioFlagStatusIncluded(portfolio, statuses))
      .map(portfolio => portfolio.portfolioId);
  }

  private static isPortfolioFlagStatusIncluded(portfolioFlag: IPortfolioAnalytics, statuses: number[]): boolean {
    return statuses.includes(Number(portfolioFlag.needAnalytics));
  }

  private static getPortfolioAnalyticsDetailsForPortfolioAndAccount(analyticsTradeToolList: IAnalyticsTradeToolList[]): IPortfolioAnalytics[] {
    const portfolioAnalytics = [] as IPortfolioAnalytics[];
    for (const tradeToolElement of analyticsTradeToolList) {
      if (tradeToolElement?.needAnalytics !== undefined) {
        const portfolioAnalytic = {
          failedReason: tradeToolElement.failedReason,
          needAnalytics: tradeToolElement.needAnalytics,
          portfolioId: tradeToolElement.portfolioId ? tradeToolElement.portfolioId : tradeToolElement.id,
          editedDate: tradeToolElement.analyticsEditedDate
        } as IPortfolioAnalytics;
        const portfolioFlags = this.getPortfolioFlagObjects([portfolioAnalytic], portfolioAnalytics);
        portfolioAnalytics.push(...portfolioFlags);
      }
    }
    return portfolioAnalytics;
  }

  private static getPortfolioFlagObjects(portfolioFlags: IPortfolioAnalytics[], portfolioAnalytics: IPortfolioAnalytics[]): IPortfolioAnalytics[] {
    const portfolioAnalyticsSet = new Set(portfolioAnalytics.map(portfolio => portfolio.portfolioId));
    return portfolioFlags.filter(portfolioFlag => !portfolioAnalyticsSet.has(portfolioFlag.portfolioId));
  }

  private static getMaxTimeForMultiplePortfolio(portfolioAnalytics: IPortfolioAnalytics[]): Date {
    const dates = portfolioAnalytics
      .filter(analyticsDetail => analyticsDetail.needAnalytics === AnalyticsStatus.Running)
      .map(analyticsDetail => AnalyticsHelper.convertUTCDateToLocalDate(analyticsDetail.editedDate));
    const dateObjects = dates.map(date => date);
    return new Date(Math.max.apply(null, dateObjects));
  }
}
