import { InjectionToken } from '@angular/core';

export type FeatureFlagGroup = {
  group: string;
  flags: Array<FeatureFlagGroup | string>;
};

export type FeatureFlagRegistry = (FeatureFlagGroup | string)[];

const FEATURE_FLAG_TOKEN: string = 'FEATURE_FLAGS';

/**
 * Injection token for providing the feature flag registry.
 */
export const FEATURE_FLAGS = new InjectionToken<FeatureFlagRegistry>(FEATURE_FLAG_TOKEN, {
  providedIn: 'root',
  factory: () => FeatureFlags
});

/**
 * A list of feature flags that will be resolved at the start of the application
 * (after the user has been authenticated, but before they enter the main application).
 * Switching firms, switching users, or refreshing the page will cause this list to be re-resolved.
 *
 * Grouping the flags is optional and subjective, but highly encouraged.
 */
const FeatureFlags: FeatureFlagRegistry = [
  {
    group: 'Accounts',
    flags: [
      'trading_eclipse_portfolio_status_icon_189133',
      'Eclipse_last_position_date_1576',
      'Eclipse_Trading_Account_Grid_Outsourced_INVST_6284',
      'MS_sleeve_transfer_accounts_4823',
      'Astro_Optimization_Stack_Bar_b10577',
      'Astro_Investor_Preference_From_Account_Grid_U10822',
      'columns_showing_object_b12444'
    ]
  },
  {
    group: 'Trade Tools',
    flags: [
      'TEXP_account_number_column_12441',
      {
        group: 'Tactical',
        flags: [
          'trading_tactical_rtp_f17146',
          'erroring_rebalancing_b11055',
          'unsaved_trades_pop_up_b11828',
          'swap_tool_error_b11465',
          'trading_tactical_recent_sessions_12456',
        ]
      },
      {
        group: 'OptionTrading',
        flags: [
          'current_contract_qty_not_calculating_b11320'
        ]
      },
      {
        group: 'QuickTrade',
        flags: [
          'eclipse_quicktrade_sma_astro_9983',
          'Quick_Trade_Import_blocked_accounts_s11384'
        ]
      },
      {
        group: 'TLH',
        flags: [
          'tlh_alternate_preference_s1784'
        ]
      },
      {
        group: 'CashNeed',
        flags: [
          'excess_cash_distributions_s10208'
        ]
      }
    ]
  },
  {
    group: 'TradeEx',
    flags: [
      'Eclipse_Trading_MassEdit_1568',
      'solicited_discretionary_enhancements_u2852',
      'Eclipse_Trading_quodd_rewrite_INVST_10717'
    ]
  },
  {
    group: 'Custom Imports',
    flags: [
      'Eclipse_CustomImports_UIAccess_194800',
    ],
  },
  {
    group: 'Network',
    flags: [
      'TEXP_ui_log_all_requests_10559'
    ]
  },
  {
    group: 'Notification',
    flags: [
      'progress_bar_enhancements_s10602'
    ]
  },
  {
    group: 'PortfolioDetails',
    flags: [
        'TEXP_portfolio_details_gain_loss_7520',
        'TEXP_portfolio_details_holdings_10542',
        'TEXP_portfolio_details_cash_7519',
        'TEXP_holdings_new_columns_10914',
        'TEXP_compare_tool_token_11699',
        'TEXP_portfolio_details_model_7522',
        'Eclipse_Multi-Trade_Block_Workflow_11537'
    ]
  },
  {
    group:'Menu',
    flags: [
      'Eclipse_DataStatus_UI_Access_INVST_10349',
      'Eclipse_DataStatus_Updates_UI_Access_INVST_12598',
      'Eclipse_SetAsideAudit_11027',
    ]
  },
  {
    group:'TradeInstances',
    flags: [
      'auto_rebal_account_level_b10975'
    ]
  },
  {
    group: 'Account Preferences',
    flags: [
      'Sync_Eclipse_Cash_To_Astro'
    ]
  },
  {
    group: 'OrderList',
    flags: [
      'TEXP_total_buy_sell_amounts_12094'
    ]
  },
  {
    group: 'PortfolioList',
    flags: [
      'portfolios_ytd_not_showing_b9946',
      'TEXP_set_aside_maximum_portfolios_11459',
      'TEXP_set_aside_minimum_portfolios_11458'
    ]
  },
  {
    group: 'Search',
    flags: [
      'TEXP_search_results_11698',
    ]
  },
  {
    group: 'Analytics',
    flags: [
      'TEXP_analytics_banner_error_12478'
    ]
  },
  {
    group: 'Restricted Plans',
    flags: [
      'TEXP_rp_expense_ratio_12728',
    ]
  }
];
