import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "../../core/base.component";
import { ITradeBlockDetail, IRelatedEntity } from "../../models/tradeBlockDetails";
import { SessionHelper } from "../../core";
import { EntityType } from "../../libs/preference.enums";
import { orderBy, uniqBy } from "lodash";
import { TradeBlockDetailsService } from "../../services/trade-block-details.service";
import { tap } from "rxjs/operators";
import { ITradeBlockReason } from "../../models/trade-block-reasons";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { FormsModule } from "@angular/forms";
import { JsonPipe, NgIf } from "@angular/common";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TooltipModule } from "primeng/tooltip";
@Component({
  selector: 'eclipse-trade-block-details-editor',
  templateUrl: './trade-block-details-editor.component.html',
  imports: [
    DropdownModule,
    CalendarModule,
    FormsModule,
    NgIf,
    InputTextareaModule,
    TooltipModule,
    JsonPipe
  ],
  standalone: true
})
export class TradeBlockDetailsEditorComponent extends BaseComponent {
  @Output() onTradeBlockDetailChanged: EventEmitter<ITradeBlockDetail[]> = new EventEmitter<ITradeBlockDetail[]>(); //Emits when a trade block detail has been added or updated.
  @Input() entityId: number; // Id of the current entity
  @Input() entityType: EntityType; // EntityType of the current entity
  private _tradeBlockReasons: ITradeBlockReason[];
  @Input()
  public get tradeBlockReasons(): ITradeBlockReason[] {
    return this._tradeBlockReasons;
  }
  public set tradeBlockReasons(value: ITradeBlockReason[]) {
    this._tradeBlockReasons = value;
  }
  private _selectedReason: ITradeBlockReason = <ITradeBlockReason>{}; // The selected trade block reason the trade block detail is assigned to.
  public get selectedReason(): ITradeBlockReason {
    return this._selectedReason;
  }
  public set selectedReason(value: ITradeBlockReason) {
    if (value){
      this._selectedReason = value;
      this.tradeBlockDetail.tradeBlockReasonId = value.id;
    }
  }
  private _relatedEntities: IRelatedEntity[]; // Entities related to the current entity
  @Input()
  public get relatedEntities(): IRelatedEntity[] {
    return this._relatedEntities;
  }
  public set relatedEntities(value: IRelatedEntity[]) {
    this._relatedEntities = value;
    this.groupEntities();
  }

  private _selectedEntity: IRelatedEntity; // The selected level (entity) the trade block detail is assigned to.
  public get selectedEntity(): IRelatedEntity {
    return this._selectedEntity;
  }
  public set selectedEntity(value: IRelatedEntity) {
    this._selectedEntity = value;
    this.tradeBlockDetail.relatedTypeId = value?.id;
    this.tradeBlockDetail.relatedType = value?.entityType;
    this.tradeBlockDetail.entityName = value?.name;
    this.tradeBlockDetail.canEdit = value && this._tradeBlockDetailsService.canUserEditTradeBlockDetail(this.tradeBlockDetail, this._sessionHelper.getUser(), this.entityType);
  }
  isSaving: boolean = false; // Flag indicating the trade block detail being saved
  submitted = false; // Flag indicating the user has tried to save this trade block detail.  Used to display validation.
  groupedEntityTypes = []; // Array of entity types grouped together
  tradeBlockDetail: ITradeBlockDetail = <ITradeBlockDetail>{}; // The trade block detail being edited
  isDateRangeValid: boolean = true;
  entityTypes = EntityType;

  constructor(private readonly _tradeBlockDetailsService: TradeBlockDetailsService, public readonly _sessionHelper: SessionHelper) {
    super('', _sessionHelper);
  }

  /**
   * Groups entities together by type.
   * Example:  [Portfolio1, Account1, Account2, Portfolio2] =>
   *  [
   *    Portfolios: [Portfolio1, Portfolio2],
   *    Accounts: [Account1, Account2]
   *  ]
   */
  groupEntities(): void {
    // Get the list of related entities.
    let entities = [];

    entities = this._relatedEntities?.filter(e => {
      return this._tradeBlockDetailsService.canUserEditEntity(e.entityType); // user must have the edit privilege for each entity type
    });

    // Get the unique list of entity types
    let uniqueEntityTypes = uniqBy(entities?.map(e => ({
      entityType: e.entityType,
      entityTypeName: e.entityTypeName,
    })), 'entityType');
    // Order the unique types (Team, Portfolio, Account)
    uniqueEntityTypes = orderBy(uniqueEntityTypes, 'entityType');
    this.groupedEntityTypes = [];
    // Add each of the unique entity types as a group, then fill its `items` array with related entities of that type
    uniqueEntityTypes.forEach(entityType => {
      const et = {
        label: entityType.entityTypeName,
        value: entityType.entityType,
        items: [],
      };
      et.items = (this.relatedEntities.filter(e => e.entityType === et.value));
      this.groupedEntityTypes.push(et);
    });
  }
  /**
   * Loads a trade block detail for editing
   * @param editingTradeBlockDetail
   */
  editTradeBlockDetail(editingTradeBlockDetail: ITradeBlockDetail): void {
    this.submitted = false;
    this.tradeBlockDetail = {...editingTradeBlockDetail};
    this.selectedEntity = this.relatedEntities?.find(entity => editingTradeBlockDetail.relatedTypeId === entity.id && editingTradeBlockDetail.relatedType === entity.entityType);
    this.selectedReason = this.tradeBlockReasons.find(tbr => tbr.id === this.tradeBlockDetail.tradeBlockReasonId);
  }

  /**
   * Creates a new trade block detail object for editing.
   * The current entity is selected as the default level.
   */
  addNewTradeBlockDetail() {
    this.submitted = false;
    this.tradeBlockDetail = <ITradeBlockDetail>{
      relatedType: this.entityType,
      relatedTypeId: +this.entityId,
      startDate: new Date(),
      tradeBlockReasonId: 0
    };
    this.tradeBlockDetail.canEdit = this._tradeBlockDetailsService.canUserEditTradeBlockDetail(this.tradeBlockDetail, this._sessionHelper.getUser(), this.entityType);
    // Find the current entity in the related entities list and select it.
    this.selectedEntity = this.relatedEntities?.find(s => s.id === +this.entityId && s.entityType === this.entityType);
    if (this.selectedReason){
      this.selectedReason = <ITradeBlockReason>{};
    }
  }

  /**
   * Returns true if the trade block detail has all required information.
   */
  isTradeBlockDetailValid(): boolean {
    const startDate: Date = !!this.tradeBlockDetail?.startDate ? new Date(this.tradeBlockDetail.startDate) : null;
    const endDate: Date = !!this.tradeBlockDetail?.endDate ? new Date(this.tradeBlockDetail.endDate) : null;
    this.isDateRangeValid = (!endDate || endDate > startDate);

    return !!this.tradeBlockDetail
      && !!this.tradeBlockDetail.description?.length
      && !!this.tradeBlockDetail.relatedTypeId
      && !!this.tradeBlockDetail.startDate
      && !!this.tradeBlockDetail.tradeBlockReasonId
      && this.tradeBlockDetail.tradeBlockReasonId > 0
      && this.isDateRangeValid;
  }

  /**
   * Saves changes made to the trade block detail.
   * Emits an onTradeBlockDetailChanged event after successfully saving.
   */
  saveTradeBlockDetail() {
    this.submitted = true;

    if (!this.isTradeBlockDetailValid()) {
      return false;
    }

    // Keep a copy of the selected level's name for later use
    const entityName = this.selectedEntity?.name;
    this.tradeBlockDetail.startDate = <any>this.tradeBlockDetail.startDate.toLocaleDateString();
    if (this.tradeBlockDetail.endDate) {
      this.tradeBlockDetail.endDate = <any>this.tradeBlockDetail.endDate.toLocaleDateString();
    }
    const action = this.tradeBlockDetail.id > 0 ? this._tradeBlockDetailsService.updateTradeBlockDetails([this.tradeBlockDetail]) : this._tradeBlockDetailsService.createTradeBlockDetails([this.tradeBlockDetail]);
    this.isSaving = true;
    action
      .pipe(tap(tradeBlockDetails => {
        tradeBlockDetails.forEach(tbd => {
          // Entity name (ex. "Default Team") is not handled by CRUD methods, so we have to set it ourselves based on what
          // is currently selected in the dropdown.
          tbd.entityName = entityName;
          // Process the trade block detail to set the entity levels, format dates, etc.
          this._tradeBlockDetailsService.processTradeBlockDetails(tbd, this._sessionHelper.getUser(), this.entityId, this.entityType);
        });
      }))
      .subscribe({
        next: tradeBlockDetails => {
          this.onTradeBlockDetailChanged.emit(tradeBlockDetails);
        },
        complete: () => {
          this.submitted = false;
          this.isSaving = false;
        },
      });
  }
}
