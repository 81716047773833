import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core/http.client';


@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  private errorLogEndPoint = 'v1/dataimport/errorlogs';
  private batchLogEndPoint = 'v1/dataimport/batchJobsLogs';
  private analyticsErrorLogEndPoint = 'v1/postimport/errorlogs';
  private exportLogsEndPoint = 'v2/dataimport/export/ImportHistory';
  private exportReverseSyncEndPoint = 'v2/dataimport/export/ReverseSyncHistory';
  private erroredAccountResyncEndpoint = 'v2/dataimport/action/ResyncImportErroredAccounts';
  private portfolioAndAccountErrorsEndpoint = 'v2/dataerrors/GetAllPortfolioAccounts'

  constructor(private _httpClient: OEHttpClient) { }

  /** Error Logs summary*/
  getErrorLogs(type) {
    if (!type) {
      return this._httpClient.getData(this.errorLogEndPoint);
    } else {
      return this._httpClient.getData(`${this.errorLogEndPoint}?type=${type}`);
    }
  }

  /** Analytics error Logs summary*/
  getAnalyticsErrorLogs() {
    return this._httpClient.getData(this.analyticsErrorLogEndPoint);
  }

  /** Import batch Logs summary*/
  getImportErrorLogs() {
    return this._httpClient.getData(this.batchLogEndPoint);
  }

  exportImportLog(id: number): any {
    return this._httpClient.postData(this.exportLogsEndPoint, id);
  }

  exportReverseSyncLogs(ids: number[]): any {
    return this._httpClient.postData(this.exportReverseSyncEndPoint, ids);
  }

  /** Import batch Logs detail summary*/
  getImportErrorLogDetails(batchJobId) {
    return this._httpClient.getData(`${this.batchLogEndPoint}/details?batchJobId=${batchJobId}`);
  }

  /** resync accounts with errors */
  initateErroredAccountResync() {
    return this._httpClient.postData(`${this.erroredAccountResyncEndpoint}`, null);
  }

  getDataErrorsForPortfoliosAndAccounts() {
    return this._httpClient.getData(this.portfolioAndAccountErrorsEndpoint);
  }
}
