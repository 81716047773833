<section class="flex flex-column gap-3" *ngIf="tradeBlockDetail">
  <div>
    <label for="level">Level</label>
    <p-dropdown [disabled]="tradeBlockDetail.id > 0" name="level" id="level" [options]="groupedEntityTypes" [(ngModel)]="selectedEntity" optionLabel="name"
                [style]="{'width': '100%'}" [group]="true" ngDefaultControl
                placeholder="Select a Level" filterBy="name" [required]="true">
      <ng-template let-group pTemplate="group">
        <span>{{group.label}}</span>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <span style="color: var(--text-secondary);">{{item.entityTypeName}}:</span> <span
        class="m-l-5">{{item.name}}</span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <span>{{item.name}}</span>
      </ng-template>
    </p-dropdown>
    <label for="reason">Type</label>
    <p-dropdown [disabled]="tradeBlockDetail.id > 0" name="reason" id="reason" [options]="tradeBlockReasons" [(ngModel)]="selectedReason" optionLabel="name"
                [style]="{'width': '100%'}" ngDefaultControl
                placeholder="Select a Type" filterBy="name" [required]="true">
    </p-dropdown>
    <small class="p-error" *ngIf="submitted && !selectedReason?.id">Type is required.</small>
  </div>

  <div>
    <label for="description">Description</label>
    <textarea id="description" pInputTextarea placeholder="Enter a description" maxlength="512" [(ngModel)]="tradeBlockDetail.description"
              required autofocus rows="4" [required]="true"></textarea>
    <div class="flex justify-content-end">
      <small style="color: var(--text-secondary);">{{tradeBlockDetail?.description?.length || 0}} / 512</small>
    </div>
    <small class="p-error" *ngIf="submitted && !tradeBlockDetail?.description?.length">Description is required.</small>
  </div>

  <div class="flex flex-row justify-content-between gap-1">
    <div class="flex flex-column">
      <label for="startDate">Start Date<i class="fas fa-question-circle m-l-5" aria-hidden="true"
                                          pTooltip="Date this Trade Block was made active."></i></label>
      <p-calendar name="startDate" id="startDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select Date"
                  [showClear]="false" appendTo="body" ngDefaultControl [required]="true"
                  [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="tradeBlockDetail.startDate"
                  [readonlyInput]="true"></p-calendar>
    </div>
    <div class="flex flex-column">
      <label for="endDate">End Date<i class="fas fa-question-circle m-l-5" aria-hidden="true"
                                      pTooltip="(Optional) Date this Trade Block is intended to end."></i></label>
      <p-calendar name="endDate" id="endDate" [showIcon]="true" [monthNavigator]="true" placeholder="Select Date"
                  [showClear]="true" appendTo="body" ngDefaultControl
                  [yearNavigator]="true" [yearRange]="getYearRange()" [(ngModel)]="tradeBlockDetail.endDate"
                  [readonlyInput]="true"></p-calendar>
    </div>
    <small class="p-error" *ngIf="submitted && !isDateRangeValid">End Date must be after Start Date.</small>
  </div>
</section>
