import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityEditorService, IEntityEditorComponent, IEntityEditorConfig } from '../../../../shared/entity-editor';

@Component({
  selector: 'eclipse-account-preferences-editor',
  templateUrl: './account-preferences-editor.component.html',
  styleUrls: []
})
export class AccountPreferencesEditorComponent implements IEntityEditorComponent, AfterViewInit {
  @ViewChild('entityhost', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _visible: boolean = false;
  @Input()
  public get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  accountPreferenceComponentRef: ComponentRef<any>;
  componentInstance: any;
  entityEditorConfig: IEntityEditorConfig;
  componentCloseSubscription: Subscription;


  constructor(private readonly _entityEditorService: EntityEditorService) {
  }

  async ngAfterViewInit(): Promise<void> {
    this.accountPreferenceComponentRef = await this.createComponent();
    if (!this.accountPreferenceComponentRef) {
      return;
    }
    this.componentInstance = this.accountPreferenceComponentRef.instance;
    this.componentInstance.hostedInEditor = true;
    this.componentInstance.accountId = Number(this.entityEditorConfig?.data?.id);
    this.componentCloseSubscription = this.componentInstance.onClose?.subscribe(() => {
      this.visible = false;
    });
  }

  private async createComponent(): Promise<ComponentRef<any>> {
    this.viewContainerRef.clear();
    if (!this.entityEditorConfig.skipAddingQueryParameter) {
      this._entityEditorService.addQueryParameter();
    }

    const { AccountPreferencesComponent } = await import('./accountpreferences.component');
    return this.viewContainerRef.createComponent(AccountPreferencesComponent);
  }
}
