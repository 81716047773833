import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SessionHelper } from './session.helper';
import { IRolePrivilege } from '../models/users.models';
import { ITabNav } from '../viewModels/tabnav';
import * as Consts from '../libs/app.constants';
import moment from 'moment-timezone';
import $ from 'jquery';
import { ITradeBlock } from '../models/tom';
import { IImageDimension } from '../models/tomreport';
import { IModel } from '../models/modeling/model';
import { ANALYTICS_TRIGGER_NAME } from '../libs/analytics.constants';
import { take } from 'rxjs/operators';

export class Utils {
  /**
   * Returns the current route name from Router.url
   * @url is current url from router. ex: Router.url
   */
  public static activeRoute(activatedRoute: ActivatedRoute): string {
    let routeName;
    activatedRoute.url.subscribe(route => {
      routeName = route.length > 0 ? route[0].path : undefined;
    });
    return routeName;
  }

  /**
   * Returns the given param value from URL querystring
   * @activatedRoute is instance of the ActivatedRoute
   * @param is optional parameter which is the name of the querystring parameters, default is 'id'
   */
  public static getRouteParam<T>(activatedRoute: ActivatedRoute, param: string = 'id'): T {
    let value: T;
    activatedRoute.params
      .pipe(
        // take 1 will take the first value from the stream and complete the observable.
        take(1)
      )
      .subscribe(params => {
        // eslint-disable-next-line eqeqeq
        if (params[param] != undefined) {
          value = <T>params[param];
        }
      });
    return value;
  }

  /**
   * Returns the given query param value from URL querystring
   * @activatedRoute is instance of the ActivatedRoute
   * @param is optional parameter which is the name of the query parameters, default is 'id'
   * Example:
   * * getQueryParam<number>(route) for foo/bar/baz?filter=9&id=1
   * => 1
   * getQueryParam<number>(route, 'filter') for foo/bar/baz?filter=9&id=1
   * => 9
   */
  public static getQueryParam<T>(activatedRoute: ActivatedRoute, param: string = 'id'): T {
    let value: T;

    activatedRoute.queryParams
      .pipe(
        // take 1 will take the first value from the stream and complete the observable.
        take(1)
      )
      .subscribe(params => {
        if (params[param] !== undefined) {
          value = <T>params[param];
        }
      });
    return value;
  }

  /**
   * Converts the IRolePrivilege to ITabNav object and retuns its instance.
   * @permission is instance of IRolePrivilege object
   */
  public static convertToTabNav(permission: IRolePrivilege): ITabNav {
    const tabNav = <ITabNav>{};
    // eslint-disable-next-line eqeqeq
    if (permission == undefined) {
      return tabNav;
    }
    tabNav.canRead = permission.canRead;
    tabNav.canAdd = permission.canAdd;
    tabNav.canUpdate = permission.canUpdate;
    tabNav.canDelete = permission.canDelete;
    return tabNav;
  }

  /**
   * Get the IRolePrivilege object from session
   * @privilegeCode is string type of privilege code
   */
  public static getPermission(privilegeCode: string = '') {
    const sessionHelper = new SessionHelper();
    if (!Utils.isNull(privilegeCode)) {
      return sessionHelper.getPermission(privilegeCode);
    }
    return <IRolePrivilege>{};
  }

  /**
   * Get the IRolePrivilege object from session
   * @privilegeCode is string type of privilege code
   */
  public static getPrefsPermission() {
    const sessionHelper = new SessionHelper();
    const privileges = sessionHelper.getPrivileges(true, 'PREF');
    return !Utils.isNull(privileges) ? privileges[0] : <IRolePrivilege>{};
  }

  /**
   * Returns the name of the type of object ('array', 'string', etc)
   * @param obj
   */
  public static toType(obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
  }

  /**
   * Checks if an object is null or undefined
   * @param value
   */
  public static isNull(value: any) {
    // eslint-disable-next-line eqeqeq
    if (value == undefined) {
      return true;
      // eslint-disable-next-line eqeqeq
    } else if (typeof (value) == 'number') {
      return value === 0;
    } else if (typeof (value) === 'string') {
      // eslint-disable-next-line eqeqeq
      return value == null || value === '';
    } else if (Utils.toType(value) === 'array') {
      return value.length <= 0;
    } else if (typeof (value) === 'object') {
      // eslint-disable-next-line eqeqeq
      return value == null;
    }
    return false;
  }

  /**
   * Sort the array of objects by given field name
   * @arrayList is array of objects type T
   * @fieldName is field of name type T
   */
  public static sortBy<T>(arrayList: Array<T>, fieldName: string = 'name') {
    if (Utils.isNull(arrayList)) {
      return arrayList;
    }
    arrayList.sort((a, b) => {
      const aType = Utils.toType(a[fieldName]);
      if (aType === 'number' && a[fieldName] > b[fieldName]) {
        return 1;
      }
      if (aType === 'string' && a[fieldName].trim().toLowerCase() > b[fieldName].trim().toLowerCase()) {
        return 1;
      }
      if (aType === 'number' && a[fieldName] < b[fieldName]) {
        return -1;
      }
      if (aType === 'string' && a[fieldName].trim().toLowerCase() < b[fieldName].trim().toLowerCase()) {
        return -1;
      }
      if (aType === 'date' && a[fieldName] < b[fieldName]) {
        return -1;
      }
      if (aType === 'date' && a[fieldName] > b[fieldName]) {
        return 1;
      }
      return 0;
    });
    return arrayList;
  }

  /**
   * Sort the array of objects by given field name
   * @arrayList is array of objects type T
   * @fieldName is field of name type T
   */
  public static sortByDesc<T>(arrayList: Array<T>, fieldName: string = 'name') {
    if (Utils.isNull(arrayList)) {
      return arrayList;
    }
    arrayList.sort((a, b) => {
      if (typeof (a[fieldName]) === 'number' && a[fieldName] > b[fieldName]) {
        return -1;
      }
      if (typeof (a[fieldName]) === 'string' && a[fieldName].trim() > b[fieldName].trim()) {
        return -1;
      }
      if (typeof (a[fieldName]) === 'number' && a[fieldName] < b[fieldName]) {
        return 1;
      }
      if (typeof (a[fieldName]) === 'string' && a[fieldName].trim() < b[fieldName].trim()) {
        return 1;
      }
      return 0;
    });
    return arrayList;
  }

  /**
   * Compares two numeric values.  Values may be of number or string types (the latter being
   * cast as a number).
   * @param valueA
   * @param valueB
   */
  public static compareNumericValues(valueA: any, valueB: any) {
    if (valueA == null) {
      return -1;
    } else if (valueB == null) {
      return 1;
    } else if (!valueA.substring || !valueB.substring) {
      return valueA - valueB;
    } else if (valueA.length < 1 || valueB.length < 1) {
      return valueA - valueB;
    } else if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    }
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  /**
   * Get current date
   * @days (number) - increase or decrease number of days to current date
   * @includeTime (boolean) - whether to include time or not
   */
  public static getDate(days = 0, includeTime = false) {
    const date = new Date();
    if (days < 0 || days > 0) {
      date.setDate(date.getDate() + days);
    }
    if (!includeTime) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
    return date;
  }

  /** formates the date to given format, default format is MM/dd/yyyy */
  public static formatDate(date, format = 'MM/dd/yyyy') {
    let value = null;
    if (Utils.isInValidDate(date)) {
      value = '';
    } else {
      value = new DatePipe('en-US').transform(date, format);
    }
    return value;
  }

  public static getDateFromUTCDate(dateTime) {
    const regex = /(\d+)/g;
    let date: string[];
    // eslint-disable-next-line eqeqeq
    if (dateTime != null && dateTime != undefined) {
      date = dateTime ? dateTime.match(regex) : [];
      if (date.length > 2) {
        return `${date[1]}/${date[2]}/${date[0]}`;
      }
    }
    return '';
  }

  public static getDateFromUTC(params) {
    return Utils.getDateFromUTCDate(params.value);
  }

  /*** date formate MM/dd/yyyy */
  public static dateRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params == '0000-00-00 00:00:00') {
      return '';
    }
    return new DatePipe('en-US').transform(params.value, 'MM/dd/yyyy');
  }

  /*** Time formate hh:mm:ss a */
  public static timeRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params == '0000-00-00 00:00:00') {
      return '';
    }
    return new DatePipe('en-US').transform(params.value, 'hh:mm:ss a');
  }

  public static dateTimeRenderer(params) {
    return Utils.dateTimeValueGetter(params.value);
  }

  /*** To render Date && Time */
  public static dateTimeValueGetter(dateTimeString) {
    if (dateTimeString === '0000-00-00 00:00:00' || dateTimeString === '0000-00-00' || dateTimeString == null || dateTimeString === '0') {
      return '';
    }
    return new DatePipe('en-US').transform(dateTimeString, 'MM/dd/yyyy hh:mm:ss a');
  }

  /** Currency format */
  public static currencyCellRenderer(params, precision?: number) {
    return Utils.formatCurrency(params.value, precision || Consts.decimalEnum.decimalsForDollar);
  }

  public static formatCurrency(value: number, decimals: number): string {
    if (!value) {
      return '$ 0.00';
    }
    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals
    });
    return currencyFormat.format(value);
  }

  public static toFixed(value: number, digits: number = 0) {
    const val = (`${value}`).toLowerCase();
    const digitsRight = val.lastIndexOf('.') < 0 ? 0 : +(`${value}`).slice(val.lastIndexOf('.') + 1);
    const scientific = val.lastIndexOf('e') < 0 ? 0 : +(`${value}`).slice(val.lastIndexOf('e') + 2);
    const scientificNegative = val.lastIndexOf('e-') < 0 ? 0 : +(`${value}`).slice(val.lastIndexOf('e') + 1);
    const dgts = Math.max(0, digitsRight - (scientificNegative || scientific));
    return (dgts > digits) ? value.toFixed(digits) : value.toString();
  }

  /** To render percentage values */
  public static percentageCellRenderer(params, precision?: number) {
    precision = precision || Consts.decimalEnum.decimalsForPercent || 0;
    if (!params.value) {
      return `${(0).toFixed(precision)} %`;
    }
    const multiplier = Math.pow(10, precision);
    const roundedVal = Math.round(params.value * multiplier) / multiplier;  /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return `${roundedVal.toFixed(precision)}%`; /** To fix decimals up to 4 digits i.e 3.47 --> 3.4700 */
  }

  /** To format percentage values */
  public static percentageValueFormatter(params) {
    if (params.value === undefined) {
      return null;
    }
    const precision = Consts.decimalEnum.decimalsForPercent || 0;
    if (!params.value) {
      return `${(0).toFixed(precision)}%`;
    }
    const multiplier = Math.pow(10, precision);
    const roundedVal = Math.round(params.value * multiplier) / multiplier;  /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return `${roundedVal.toFixed(precision)}%`; /** To fix decimals up to 4 digits i.e 3.47 --> 3.4700 */
  }

  /** To render shares  */
  public static sharesCellRenderer(params, precision?: number) {
    precision = precision || Consts.decimalEnum.decimalsForShares || 0;
    if (!params.value) {
      return (0).toFixed(precision);
    }
    const multiplier = Math.pow(10, precision);
    const roundedVal = Math.round(params.value * multiplier) / multiplier;  /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return roundedVal.toFixed(precision); /** To fix decimals up to 4 digits i.e 3.47 --> 3.4700 */
  }

  /** convert Enum values to strings */
  public static convertEnumValuesToString(obj) {
    Object.keys(obj).forEach(function (key) {
      if (isNaN(+key)) {
        Object.defineProperty(obj, key, {
          value: key,
          enumerable: true
        });
      }
    });
    return obj;
  }

  public static amountCellInputValidator(event, input) {
    if (event.key === 'Backspace' || event.key === '') {
      return true;
    } else if (event.key === 'Period' || event.key === '.') {
      if (input.value.indexOf('.') !== -1) {
        event.preventDefault();
        return false;
      }
      return true;
    } else if (!isFinite(+event.key)) { // numeric keys will have their value (1 = '1', etc).
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  /** Convert the string/s to integer/s if any within Number array*/
  public static toPrimitiveInt(selectedIds: number[]) {
    const result: number[] = [];
    for (let i = 0; i < selectedIds.length; i++) {
      // eslint-disable-next-line radix
      result[i] = parseInt(selectedIds[i].toString());
    }
    return result;
  }

  /** Compare and remove the duplicate records from objectArrays */
  public static removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};

    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (const j in lookupObject) {
      newArray.push(lookupObject[j]);
    }
    return newArray;
  }

  /*** To render Yes or No */
  public static YesOrNoRender(params) {
    // eslint-disable-next-line eqeqeq
    if (params != null && params != undefined) {
      if (params.value === 0) {
        return 'No';
      } else if (params.value === 1) {
        return 'Yes';
      } else {
        return '';
      }
    } else {
      return null;
    }
  }

  /** To render True or False */
  public static TrueOrFalseRenderer(params) {
    // eslint-disable-next-line eqeqeq
    if (params != null && params != undefined) {
      if (params.value === 0) {
        return 'False';
      } else if (params.value === 1) {
        return 'True';
      } else {
        return '';
      }
    } else {
      return null;
    }
  }

  /** Deep clones object by stringifying the object and then converting to any JSON */
  public static deepClone(params) {
    // eslint-disable-next-line eqeqeq
    if (params != null && params != undefined) {
      return JSON.parse(JSON.stringify(params));
    }
    return null;
  }

  /** Grid resize */
  public static windowSize() {
    const height = $(window).height();

    $('.grid-height-autosize').css({
      height: height - 272,
      width: '100%'
    });

    $('.grid-height-autosizeTwo').css({
      height: height - 272,
      width: '100%'
    });

    $('.grid-height-autosizeThr').css({
      height: height - 200,
      width: '100%'
    });

    $('.grid-height-autosizefour').css({
      height: height - 320,
      width: '100%'
    });

    $('.grid-height-autosizefive').css({
      height: height - 400,
      width: '100%'
    });

    $('.grid-height-autosizesix').css({
      height: height - 242,
      width: '100%'
    });

    $('.grid-height-autosizeseven').css({
      height: height - 450,
      width: '100%'
    });

    $('.grid-height-autosizeeight').css({
      height: height - 550 > 300 ? height - 550 : 300,
      width: '100%'
    });
  }

  /** Grid resize */
  public static WindowOnresize() {
    const that = this;
    $(window).on('resize', function () {
      if (that.windowSize) {
        that.windowSize();
      }
    });
  }

  /**
   * Prepare an array of ids for the simple  request based on bucket size
   * @param ids
   * @param bucketSize
   */
  public static getRecordsBasedOnBucketSize(ids: number[], bucketSize) {
    // eslint-disable-next-line eqeqeq
    if (bucketSize != undefined && bucketSize != null) {
      const apiArray = [];
      for (let index = 0; index < Math.ceil(ids.length / bucketSize); index++) {
        const startIndex = (index * bucketSize);
        const endIndex = ((index + 1) * bucketSize);
        apiArray.push(ids.slice(startIndex, endIndex));
      }
      return apiArray;
    }
  }

  /** To round decimals up to 4 digits  */
  public static roundDecimals(val, precision) {
    const multiplier = Math.pow(10, precision || 0);
    const roundedVal = Math.round(val * multiplier) / multiplier;  /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return roundedVal.toFixed(Consts.decimalEnum.decimalsForPercent); /** To fix decimals up to 4 digits i.e 3.47 --> 3.4700 */
  }

  public static roundDecimal(val: number, precision: number = 2): number {
    return parseFloat(Utils.roundDecimals(val, precision));
  }

  public static roundNumber(val, scale = 0) {
    const multiplier = Math.pow(10, scale);
    const roundedVal = Math.round(val * multiplier) / multiplier;  /** To round decimals up to 4 digits i.e 3.47627234 --> 3.4763 */
    return roundedVal;
  }

  /** Customzing   the gain loss amount in currency format for -ve values.
   * we will show -ve value as (value), for eg: -2.345 = (2.345)
   */
  public static amountFormatCurrencyCellRenderer(params, formatTitle: boolean = true) {
    const eSpan = document.createElement('span');
    if (params.value !== null && params.value !== undefined) {
      const currencyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

      if (params.value < 0) {
        eSpan.innerHTML = `(${currencyFormat.format((-1 * params.value))})`;
      } else {
        eSpan.innerHTML = currencyFormat.format(params.value);
      }
      if (formatTitle) {
        eSpan.title = currencyFormat.format(params.value);
      } else {
        eSpan.title = params.value;
      }

      return eSpan;
    }
    return null;
  }

  public static amountCurrencyValueFormatter(params) {
    if (params.value === null || params.value === undefined) {
      return null;
    }
    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });

    if (params.value < 0) {
      return `(${currencyFormat.format((-1 * params.value))})`;
    } else {
      return currencyFormat.format(params.value);
    }
  }

  public static amountFormatCurrencyCellRendererTLH(params, formatTitle: boolean = true) {
    const eSpan = document.createElement('span');
    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    if (params.value !== null && params.value !== undefined) {
      if (params.value < 0) {
        eSpan.innerHTML = `(${currencyFormat.format((-1 * params.value))})`;
      } else {
        eSpan.innerHTML = currencyFormat.format(params.value);
      }
      if (formatTitle) {
        eSpan.title = currencyFormat.format(params.value);
      } else {
        eSpan.title = params.value;
      }
    } else {
      eSpan.innerHTML = currencyFormat.format(0);
      if (formatTitle) {
        eSpan.title = currencyFormat.format(0);
      }
    }
    return eSpan;
  }

  /**
   * Converts a date string to a UTC date.
   * @param dateString
   */
  public static getUTCDateAndTime(dateString, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(new Date(dateString)).tz('UTC').format(format);
  }

  /** GroupBy based on two properties */
  /**Sample Call:
   groupBy(list, function (item) {
   return [item.modelId, item.securityId];
   }); */
  public static groupBy(array, f) {
    const groups = {};
    array.forEach(function (o) {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  public static getMACStatusTextById(macStatus: number, type: string): string {
    if (type === Consts.MacEntityTypes.Model) {
      if (macStatus === Consts.MACWeightingStatus.Yes.value) {
        return Consts.MACWeightingStatus.Yes.text;
      } else if (macStatus === Consts.MACWeightingStatus.No.value) {
        return Consts.MACWeightingStatus.No.text;
      }
    } else {
      if (macStatus === Consts.MACWeightingStatus.None.value) {
        return Consts.MACWeightingStatus.None.text;
      } else if (macStatus === Consts.MACWeightingStatus.Portfolio.value) {
        return Consts.MACWeightingStatus.Portfolio.text;
      } else if (macStatus === Consts.MACWeightingStatus.Model.value) {
        return Consts.MACWeightingStatus.Model.text;
      } else if (macStatus === Consts.MACWeightingStatus.Sleeve.value) {
        return Consts.MACWeightingStatus.Sleeve.text;
      }
    }
  }

  public static getYesNoForBoolean(boolValue: boolean) {
    if (boolValue) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  public static getYesNoOrBlankForBoolean(value?: boolean): string {
    if (value === null || typeof value === 'undefined') {
      return '';
    } else if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  public static checkForDuplicatesInArray(inputArray: any[]) {
    for (let i = 0; i <= inputArray.length; i++) {
      for (let j = i; j <= inputArray.length; j++) {
        // eslint-disable-next-line eqeqeq
        if (i != j && inputArray[i] == inputArray[j]) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Refreshes the page forcefully
   */
  public static refreshPage() {
    window.location.reload();
  }

  /**
   * Returns true if the Ctrl key is held (for Windows), Command key is held on Mac
   * @param event
   */
  public static isCtrlKey(event) {
    // Ctrl for Windows/Linux based keyboards = ctrlKey
    // Command for Mac based keyboards = metaKey
    return event.ctrlKey || event.metaKey;
  }

  public static validateBlocksTradeExecutionType(blocks: ITradeBlock[]) {
    const validateResponse = {
      validBlockIds: [],
      invalidBlockIds: []
    };
    if (blocks && blocks.length) {
      blocks.forEach(block => {
        if (block.tradeExecutionTypeId) {
          if (block.tradeExecutionTypeId === 1) { // 1 is FIX FLYER
            validateResponse.validBlockIds.push(block.id);
          } else {
            validateResponse.invalidBlockIds.push(block.id);
          }
        }
      });
    }
    return validateResponse;
  }

  public static validateEnteredKey(event: any, eInput: any) {
    return Utils.isValidDecimalInputKey(event, eInput.value) ? null : false;
  }

  public static labelRenderer(text: any) {
    const eCell = document.createElement('div');
    eCell.style.cssText = 'height:20px;';
    const eLabel = document.createTextNode(text ? text : '');
    eCell.appendChild(eLabel);
    return eCell;
  }

  public static numberEditor(params: any, self: any) {
    const eInput = document.createElement('input');
    eInput.className = 'form-control grid-input';
    eInput.value = params.data[params.colDef.field] ? params.data[params.colDef.field] : null;

    eInput.addEventListener('blur', () => {
      params.data[params.colDef.field] = (isNaN(parseFloat(eInput.value)) ? null : parseFloat(eInput.value));
    });

    eInput.addEventListener('keypress', (event) => {
      return self.validateEnteredKey(event, eInput);
    });
    return eInput;
  }

  /**
   * Adds a number of days to a given date (now if not provided)
   * @param days
   * @param date
   */
  public static addDays(days: number, date: Date = null) {
    const dt = date ? new Date(date.valueOf()) : new Date();
    dt.setDate(dt.getDate() + days);
    return dt;
  }

  public static dateFormats = ['MM-DD-YYYY', 'MM-DD-YYYY HH:mm:ss', 'MM/DD/YYYY', 'MM/DD/YYYY HH:mm:ss', 'YYYY-MM-DD'];

  /**
   * Converts a date string to a date object using a static list of formats
   * @param date
   */
  public static stringToDate(date: string): Date {
    return moment(date, Utils.dateFormats).toDate();
  }

  /**
   * This method is used to get image dimensions height and width of passed file
   * @param file
   * @returns {Promise<{ width: number, height: number}>}
   * Return the width and height of image
   */
  public static getImageDimensions(file: string): Promise<IImageDimension> {
    return new Promise(function (resolve, reject) {
      try {
        const i = new Image();
        i.onload = function () {
          resolve({width: i.width, height: i.height});
        };
        i.onerror = function () {
          resolve({width: 0, height: 0});
          ;
        };
        i.src = file;
      } catch (error) {
        reject(error);
      }
    });
  }

  public static isValidIntegerInputKey(event: KeyboardEvent, preventDefault: boolean = true): boolean {
    if (!Utils.isNumericKey(event).valid) {
      if (preventDefault) {
        event.preventDefault();
      }
      return false;
    }
    return true;
  }

  public static isValidDecimalInputKey(event: KeyboardEvent, currentValue: string | number, maxDecimalPlaces: number = null): boolean {
    const valid = Utils.isNumericKey(event, true);

    currentValue = currentValue ? currentValue.toString() : '';
    const splitNumber = currentValue.split('.');

    if (!valid.valid) {
      event.preventDefault();
      return false;
    }
    if (event.key === '.' && splitNumber.length > 1) { // only one decimal point allowed
      event.preventDefault();
      return false;
    } else if (maxDecimalPlaces !== null // limiting decimal places
      && splitNumber.length > 1 && splitNumber[1].length >= maxDecimalPlaces) {  // has more digits after decimal than allowed
      event.preventDefault();
      return false;
    } else if (!Utils.isNumericKey(event).valid) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public static isValidPercentInputKey(event: KeyboardEvent, currentValue: string | number, maxDecimalPlaces: number = null): boolean {
    if (!Utils.isValidDecimalInputKey(event, currentValue, maxDecimalPlaces)) {
      event.preventDefault();
      return false;
    } else if (Number(currentValue + event.key) > 100) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public static isNumericKey(event: KeyboardEvent, allowPeriod: boolean = true): { valid: boolean; numericValue: number; value: string } {
    switch (event.key) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
        return {valid: true, numericValue: +event.key, value: event.key};
      case '.':
        return {valid: allowPeriod, numericValue: undefined, value: event.key};
      default:
        return {valid: false, numericValue: undefined, value: undefined};
    }
  }

  public static abbreviateCurrency(value: number, fractionSize: number = 2): string {
    const DECIMAL_SEPARATOR = '.';
    const THOUSANDS_SEPARATOR = ',';
    const PADDING = '000000';
    const CURRENCY = '$';

    let result: number = value;
    let amount: string = '';

    let sign = '';
    if (result < 0) {
      sign = '-';
      result *= -1;
    }
    if (result >= 1000) {
      amount = 'k';
      if (result >= 1000000) {
        result = result / 1000000;
        if (result >= 1000) {
          result = result / 1000;
          amount = 'b';
        } else {
          amount = 'm';
        }
      }
    }

    if (result || result === 0) {
      result = +(result.toFixed(fractionSize));
    }

    if (amount === 'k') {
      if (result >= 1000000) {
        result = result / 1000000;
      } else if (result >= 1000) {
        result = result / 1000;
      }
    } else if (amount === 'm') {
      if (result >= 1000) {
        result = result / 1000;
      }
    }

    let [integer, fraction = ''] = (result || '0').toString()
      .split(DECIMAL_SEPARATOR);

    fraction = fractionSize > 0
      ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);

    if (sign === '-') {
      return `(${CURRENCY}${integer}${fraction})`;
    } else {
      return CURRENCY + integer + fraction;
    }
  }

  public static currencyScale(value: number): string {
    let result: number = value;
    if (result < 0) {
      result *= -1;
    }
    let amount: string = '';

    if (result >= 1000) {
      amount = 'k';
      if (result >= 1000000) {
        result = result / 1000000;
        if (result >= 1000) {
          result = result / 1000;
          amount = 'b';
        } else {
          amount = 'm';
        }
      }
    }

    if (result || result === 0) {
      result = +(result.toFixed(2));
    }
    if (amount === 'k') {
      if (result >= 1000000) {
        amount = 'm';
        result = result / 1000000;
      } else if (result >= 1000) {
        result = result / 1000;
      }
    } else if (amount === 'm') {
      if (result >= 1000) {
        result = result / 1000;
        amount = 'b';
      }
    }
    return amount;
  }

  public static getNeedAnalyticsStatus(needAnalyticsStatus: number): string {
    let status;
    if (needAnalyticsStatus === Consts.AnalyticsStatus.Good) {
      status = Consts.AnalyticsStatusValue.Good;
    } else if (needAnalyticsStatus === Consts.AnalyticsStatus.NeedAnalytics) {
      status = Consts.AnalyticsStatusValue.NeedAnalytics;
    } else if (needAnalyticsStatus === Consts.AnalyticsStatus.Failed) {
      status = Consts.AnalyticsStatusValue.Failed;
    } else {
      status = Consts.AnalyticsStatusValue.Running;
    }
    return status;
  }

  public static convertIntoBooleanValue(value: any): boolean {
    if (!value || value === 'false' || value === false || value === '0' || value === 0) {
      value = false;
    } else {
      value = true;
    }
    return value;
  }

  public static getBooleanValue(value: any): boolean {
    let result = null;
    if (value === true || value === 'true') {
      result = true;
    } else if (value === false || value === 'false') {
      result = false;
    }
    return result;
  }

  public static getModelStatusId(model: IModel): number {
    return model.currentStatusId ? model.currentStatusId : model.statusId;
  }

  public static getNumberWithDefaultValueZero(amount: number | string): number {
    return Number(amount) || 0;
  }

  public static isFullAnalytics(trigger: string): boolean {
    return trigger === ANALYTICS_TRIGGER_NAME.REFRESH_ANALYTICS || trigger === ANALYTICS_TRIGGER_NAME.PARTIAL_IMPORT || trigger === ANALYTICS_TRIGGER_NAME.FULL_IMPORT;
  }

  /*** Convert start date to  UTC start date Time */
  public static getUTCStartDateAndTime(date: Date): any {
    const dateString = moment(date).format('YYYY-MM-DD 00:00:00');// start of the day
    return moment(dateString).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
  }

  /*** Convert end date to  UTC end date Time */
  public static getUTCEndDateAndTime(date: Date): any {
    const dateString = moment(date).format('YYYY-MM-DD 23:59:59');
    return moment(dateString).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
  }

  /** Formats a UTC date, ignoring the local browser's timezone
   * 2000-01-01T06:00:00.000Z (MM/DD/YYYY format) becomes 01/01/2000
   * 2000-01-01T00:00:00.000Z (MM/DD/YYYY format) becomes 01/01/2000
   * **/
  public static formatUTCDate(utcDate: Date | string, format: string): string {
    let value = null;
    if (Utils.isInValidDate(utcDate)) {
      value = '';
    } else {
      value = moment(utcDate).format(format);
    }
    return value;
  }

  public static isInValidDate(date: Date | string): boolean {
    const dateValue = new Date(date);
    return !date || date === '0' || date === '' || date === '0000-00-00 00:00:00' || date === '0000-00-00'
      || date === '0000-00-00T00:00:00Z' || isNaN(dateValue.getMonth());
  }

// return a UTC date, ignoring the local browser's timezone
  public static getUTCDate(utcDate: Date | string): Date {
    const date = moment(utcDate, 'YYYY-MM-DD');
    return date && date.isValid() ? date.utc().toDate() : null;
  }

  public static getFieldValue(object: any, fieldName: string): any {
    return object && object[fieldName] ? object[fieldName] : null;
  }

  /**
   * Opens a route in a new tab.  Use this method to open a new tab and
   * continue using the current session (so the user doesn't have to log in again).
   * @param window
   * @param router
   * @param url URL relative to the site root.  Ex. 'eclipse/model/view/1234'
   */
  public static openUrlInNewTab(window: Window, router: Router, url: string): void {
    // create a url tree representing the new url relative to the root of the app.
    const urlTree = router.createUrlTree([url]);
    // remove the current url (relative to the root):  http://root/#/current/route => http://root/#
    const baseUrl = window.location.href.replace(router.url, '');
    // open the new url in a blank tab
    window.open(baseUrl + urlTree.toString(), '_blank');
  }

  // Regex for encoding HTML components
  private static simpleHTMLTagEncodeRegex = new RegExp(/[<>]/, 'g');

  /**
   * Escapes text that may contain HTML elements
   * @param queryToEscape
   */
  public static escapeUnsafeText(queryToEscape: string) {
    return queryToEscape
      .replace(Utils.simpleHTMLTagEncodeRegex,
        tag => ({
          '<': '&lt;',
          '>': '&gt;',
        }[tag]));
  }

  public static hasReadPermission(privilege: IRolePrivilege): boolean {
    return privilege && privilege.canRead;
  }

  public static hasTradeToolRight(privilege: string) {
    const privilegeResult = this.getPermission(privilege);
    return !!privilegeResult && privilegeResult?.canRead;
  }

  public static hasTradeToolCanCreateRight(privilege: string) {
    const privilegeResult = this.getPermission(privilege);
    return !!privilegeResult && privilegeResult?.canAdd;
  }

  public static getPercentage(value: number, totalValue: number): number {
    return Number((value * 100 / totalValue).toFixed(Consts.tacticalDecimalEnum.percentageDecimalForDisplay));
  }

  public static getAmount(percent: number, value: number): number {
    return Number((percent * value / 100).toFixed(Consts.tacticalDecimalEnum.amountDecimalForDisplay));
  }

  public static removeSubstringInRange(string: string, startIndex: number, endIndex: number): string {
    return string.substring(0, startIndex) + string.substring(endIndex);
  };

  public static currencyValueForTooltip(params) {
    if (params.value !== null || params.value !== undefined) {
      if (params.value === -0) {
        params.value = 0;
      }
      const currencyFormat = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return `$${currencyFormat.format(params.value)}`;
    }
    return null;
  }

  /**
   * Calculates the percentage of a dividend relative to a divisor.
   *
   * @param {number} inputDividend The dividend value.
   * @param {number} inputDivisor The divisor value.
   * @returns {number} The calculated percentage, rounded to the specified number of decimal places.
   *                  Returns 0 if the divisor is zero to avoid division by zero errors.
   */
  public static calculatePercentage(inputDividend: number, inputDivisor: number): number {
    const divisor = !isNaN(inputDivisor) && !!inputDivisor ? inputDivisor : 0;
    if (divisor === 0) {
      //don't allow divide by zero errors
      return 0;
    }
    const dividend = !isNaN(inputDividend) && !!inputDividend ? inputDividend : 0;
    const percentage = (dividend / divisor) * 100;
    return Number(percentage.toFixed(Consts.tradeDecimalEnum.amountDecimal));
  }
}
