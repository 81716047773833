import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from '@ag-grid-community/angular';
import { IStatusPanelParams, Events } from '@ag-grid-community/core';

@Component({
  template: `
    <div class="ag-status-name-value">
      <span>Total Buys:&nbsp;</span>
      <span class="ag-status-name-value-value">{{ totalBuys | currency }}</span>
    </div>
    <div class="ag-status-name-value">
      <span>Total Sells:&nbsp;</span>
      <span class="ag-status-name-value-value">{{ totalSells | currency }}</span>
    </div>
  `
})
export class BuySellTotalsComponent implements IStatusPanelAngularComp {
  private params!: IStatusPanelParams;
  public count: number | null = null;
  totalBuys = 0;
  totalSells = 0;
  agInit(params: IStatusPanelParams): void {
    this.params = params;

    this.params.api.addEventListener(Events.EVENT_SELECTION_CHANGED, this.onSelectionChanged.bind(this));
    this.params.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.onSelectionChanged.bind(this));
  }

  onSelectionChanged(): void {
    this.totalBuys = this.params.api.getSelectedNodes()?.filter(node => !node.group && node.data.action === 'BUY' && node.data.isEnabled)
      .map(s => s.data.estimateAmount).reduce((acc, curr) => acc + curr, 0);
    this.totalSells = this.params.api.getSelectedNodes()?.filter(node => !node.group && node.data.action === 'SELL' && node.data.isEnabled)
      .map(s => s.data.estimateAmount).reduce((acc, curr) => acc + curr, 0);
  }
}
