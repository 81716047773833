import { Injectable } from '@angular/core';
import {OEHttpClient} from '../core';

@Injectable({
  providedIn: 'root'
})
export class RebalanceService {

  private _portfolioEndPoint = 'portfolio/portfolios';
  private _rebalanceEndPoint = 'portfolio/portfolioRebalance';
  private _rebalancerEndPointOnLambda = 'rebalancer/rebalance/lambda/';
  private _rebalancerEndPoint = 'rebalancer/rebalance/';
  private _rebalancerDownloadEndPoint = 'rebalancer/rebalance/download';

  constructor(private _httpClient: OEHttpClient) {

  }

  getRebalanceDetail(id) {
    return this._httpClient.getData(`${this._rebalanceEndPoint}/${id}`);
  }

  rebalancePortfolio(portfolioId, rebalanceTypeId, accountId, amount, isAccessLambda , type , isRegistrationLevel) {
    const endPoint = isAccessLambda ? this._rebalancerEndPointOnLambda : this._rebalancerEndPoint;

    return this._httpClient.getData(`${endPoint}portfolio/${portfolioId
       }?accountId=${accountId}&amount=${amount}&rebalanceType=${rebalanceTypeId}&isAccessLambda=${isAccessLambda
       }&type=${type}&isRegistrationLevel=${isRegistrationLevel}`);
  }

  assignModel(modelId, portfolioId) {
    return this._httpClient.getData(`${this._rebalancerEndPoint}assignModel/${modelId}/portfolio/${portfolioId}`);
  }
  assignModelToSleeveAccount(modelId, accountId) {
    return this._httpClient.postData(`modeling/models/${modelId}/sleeves` , {id : accountId});
  }

  /** Get portfolio accounts based on portfolio Id */
  getPortfolioAccounts(portfolioId: number) {
    return this._httpClient.getData(`${this._portfolioEndPoint}/${portfolioId}/accounts/simple`);
  }

  /** To get Rebalance options */
  getRebalanceOptions() {
    return this._httpClient.getData('rebalancer/rebalanceOptions');
  }

}
