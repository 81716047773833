import { Component, forwardRef, inject, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  EditorEntityType,
  EntityAction,
  EntityEditorComponent,
  EntityEditorService,
  IEntityEditorComponent
} from '../../../shared/entity-editor';
import * as Consts from '../../../libs/app.constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoginAsService } from '../../../services/loginas.service';
import { PortfolioService } from '../../../services/portfolio.service';
import { PreferenceService } from '../../../services/preference.service';
import { WINDOW } from '../../../providers/window.provider';
import { Utils as Util } from '../../../core/functions';
import { IEntityChangedEvent } from '../../../shared/portfoliotree/portfolio-tree-item';
import { EntityType } from '../../../libs/preference.enums';
import { SplitIoService } from '../../../core/feature-flag';
import { MenuItem } from 'primeng/api';
import { PortfolioEditorService } from './portfolio-editor.service';
import {
  PortfolioAccountsComponent,
  PortfolioCompareToolComponent,
  PortfolioGainLossComponent,
  PortfolioHoldingsComponent,
  PortfolioModelComponent,
  PortfolioNotesComponent,
  PortfolioSummaryComponent
} from './';
import { catchError, filter, map, merge, Observable, of, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { PortfolioEditorPage, PortfolioEntity } from './types';
import { IAccount, IPortfolio, IPortfolioDetails, IToken } from '../../../models/portfolio';
import { ConfigService } from '../../../config/config.service';
import { Accounts } from '../../../shared/accounts/assign.accounts';
import { PortfolioCashComponent } from './portfolio-cash.component';
import { IAnalyticsNotification } from '../../../models/notification';
import { AnalyticsStatus } from '../../../libs/app.constants';
import { PortfolioTradeBlockDetailsComponent } from './portfolio-trade-block-details.component';

@Component({
  selector: 'eclipse-portfolio-editor',
  templateUrl: './portfolio-editor.component.html',
  styleUrls: ['./portfolio-editor.component.scss'],
  providers: [PortfolioEditorService],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioEditorComponent extends EntityEditorComponent implements IEntityEditorComponent {
  public accountAccessPermission: boolean = false;
  public accountEditPermission: boolean = false;
  public portfolioEditPermission: boolean = false;
  public modelAccessPermission: boolean = false;
  public readonly _portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  public portfolio$: Observable<PortfolioEntity> = this._portfolioEditorService.portfolio$;
  compareToolEnabled: boolean = false;
  compareToolEnabledPreference: boolean = false;
  showConfirmReverseSyncPortfolios: boolean = false;
  showReverseSyncPortfoliosMessage: boolean = false;
  shouldShowPortfolioSyncFromOrionConfirmationDialog: boolean;
  shouldEnableRsyncStatusOkButton: boolean;
  shouldShowRsyncStatusDialog: boolean;
  rSyncErrorMessage: string;
  rsyncStatus: string;
  syncFromOrionPortfoliosCount: number;
  showExcludeFilterPopup: boolean = false;
  portfolioIdsToExclude: number[];
  showAssignPopup: boolean;
  deleteCheck: boolean;
  displayConfirm: boolean;
  deletedPortfoliosCount: number = 0;
  unDeletedPortfoliosCount: number = 0;
  deleteMessage: boolean = false;
  deletedPortfolioId: number = 0;
  portfolioCashEnabled: boolean = false;
  gainLossFF: boolean = false;
  holdingsFF: boolean = false;
  isSaveButtonDisabled: boolean = false;
  closeOnSuccess: boolean;
  multiTradeBlockFF: boolean = false;

  @ViewChild(forwardRef(() => Accounts)) assignComponent: Accounts;

  protected EditorPages: { [key in string]: { name: string; component: any } } =
    {
      Summary: { name: PortfolioEditorPage.Summary, component: PortfolioSummaryComponent },
      Cash: { name: PortfolioEditorPage.Cash, component: PortfolioCashComponent },
      Accounts: { name: PortfolioEditorPage.Accounts, component: PortfolioAccountsComponent },
      Model: { name: PortfolioEditorPage.Model, component: PortfolioModelComponent },
      TradeBlock: { name: PortfolioEditorPage.TradeBlock, component: PortfolioTradeBlockDetailsComponent },
      Notes: { name: PortfolioEditorPage.Notes, component: PortfolioNotesComponent },
      GainLoss: { name: PortfolioEditorPage.GainLoss, component: PortfolioGainLossComponent },
      Holdings: { name: PortfolioEditorPage.Holdings, component: PortfolioHoldingsComponent },
      TradeHistory: { name: PortfolioEditorPage.TradeHistory, component: null },
      AuditHistory: { name: PortfolioEditorPage.AuditHistory, component: null },
      SleeveMaintenance: { name: PortfolioEditorPage.SleeveMaintenance, component: null },
      Preferences: { name: PortfolioEditorPage.Preferences, component: null },
      CompareTool: { name: PortfolioEditorPage.CompareTool, component: PortfolioCompareToolComponent },
    };
  private readonly _router: Router = inject(Router);
  private readonly _loginAsService: LoginAsService = inject(LoginAsService);
  private readonly _portfolioService: PortfolioService = inject(PortfolioService);
  private readonly _preferenceService: PreferenceService = inject(PreferenceService);
  private readonly _entityEditorService: EntityEditorService = inject(EntityEditorService);
  private readonly _splitIoService: SplitIoService = inject(SplitIoService);
  private readonly _window: Window = inject<Window>(WINDOW);
  private reverseSyncPortfolioPreference: boolean = false;
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly canReadNotes: boolean = !!Util.getPermission(Consts.PRIV_NOTES)?.canRead;
  private _portfolioNotesCount = 0;
  isPortfolioDetailsModelFFEnabled: boolean = false;

  constructor() {
    super();
    const accountPriv = Util.getPermission(Consts.PRIV_ACCOUNTS);
    this.accountAccessPermission = !!accountPriv?.canRead;
    this.accountEditPermission = !!accountPriv?.canUpdate;
    this.portfolioEditPermission = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;

    this.modelAccessPermission = !!Util.getPermission(Consts.PRIV_MODELS)?.canRead;

    this.gainLossFF = !!this._splitIoService.featureFlags['TEXP_portfolio_details_gain_loss_7520'];
    this.holdingsFF = !!this._splitIoService.featureFlags['TEXP_portfolio_details_holdings_10542'];
    this.multiTradeBlockFF = !!this._splitIoService.featureFlags['Eclipse_Multi-Trade_Block_Workflow_11537'];
    this.portfolioCashEnabled = !!this._splitIoService.featureFlags['TEXP_portfolio_details_cash_7519'];
    this.isPortfolioDetailsModelFFEnabled = !!this._splitIoService.featureFlags['TEXP_portfolio_details_model_7522'];

    this._preferenceService.getPreferenceByName('reverseSyncPortfolios')
      .subscribe(result => {
        this.reverseSyncPortfolioPreference = Util.convertIntoBooleanValue(result);
      });

    const compareToolFlag$ = this._splitIoService.flagEnabled('Eclipse_CompareTool_257656')
      .pipe(
        switchMap(flag => {
          this.compareToolEnabled = !!flag;
          if (this.isOrionAdmin) {
            return of(true);
          } else {
            return this._preferenceService.getPreferenceByName('compareToolEnabled')
              .pipe(
                map(result => Util.convertIntoBooleanValue(result))
              );
          }
        }),
        tap(result => this.compareToolEnabledPreference = result)
      );

    this.sidebarMenuItems$ = merge(this._portfolioEditorService.portfolio$, this._portfolioEditorService.notes$, this._activePage.asObservable(), compareToolFlag$)
      .pipe(
        takeUntil(this.destroyed$),
        map(() => this.getSidebarMenuItems())
      );

    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(evt => evt instanceof NavigationEnd && evt.urlAfterRedirects.indexOf('editEntity') < 0))
      .subscribe(() => {
        this.visible = false;
      });

    this._portfolioEditorService.portfolioNotes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notes => {
        this._portfolioNotesCount = notes.length;
      });
    this._portfolioEditorService.isSaveButtonDisabled
      .subscribe((value: boolean) => {
        this.isSaveButtonDisabled = value;
      });
  }

  public get isFooterVisible(): boolean {
    return ![
      PortfolioEditorPage.Accounts,
      PortfolioEditorPage.CompareTool,
      PortfolioEditorPage.AuditHistory,
      PortfolioEditorPage.TradeHistory,
      PortfolioEditorPage.TradeBlock,
      PortfolioEditorPage.Notes,
      PortfolioEditorPage.Cash,
      PortfolioEditorPage.GainLoss,
      PortfolioEditorPage.Holdings
    ]
      .includes(this._activePage.getValue());
  }

  public buildActionsMenu(): void {
    const options = {
      reverseSyncPortfolioPreference: this.reverseSyncPortfolioPreference,
    };
    this.actionItems = this.getActionsMenu(this._portfolioEditorService.portfolio, options);
  }

  ngOnInit() {
    this.contentContainer.clear();
    this._portfolioEditorService.portfolioSave$.subscribe(value => {
      if (value && this.closeOnSuccess) {
        this.visible = false;
      }
    });

    let initialTab: PortfolioEditorPage = PortfolioEditorPage.Summary;
    const currentParams = { ...this._activatedRoute.snapshot.queryParams };
    if (!!currentParams.tab) {
      switch (currentParams.tab.toLowerCase()) {
        case 'cash':
          initialTab = PortfolioEditorPage.Cash;
          break;
        case 'accounts':
          initialTab = PortfolioEditorPage.Accounts;
          break;
        case 'tradeBlock':
          initialTab = PortfolioEditorPage.TradeBlock;
          break;
        case 'notes':
          initialTab = PortfolioEditorPage.Notes;
          break;
        case 'model':
          initialTab = PortfolioEditorPage.Model;
          break;
        case 'gainloss':
          initialTab = PortfolioEditorPage.GainLoss;
          break;
        case 'holdings':
          initialTab = PortfolioEditorPage.Holdings;
          break;
        case 'preferences':
          initialTab = PortfolioEditorPage.Preferences;
          break;
        case 'comparetool':
          initialTab = PortfolioEditorPage.CompareTool;
          break;
        case 'details':
        case 'summary':
        default:
          break;
      }
    }

    this.loadComponent(initialTab);
    if (!this.entityEditorConfig.skipAddingQueryParameter) {
      this._entityEditorService.addQueryParameter();
    }
    this._portfolioEditorService.portfolioId$.next(this.entityEditorConfig?.data?.id);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public closeEditor(): void {
    //send message portfolio is deleted
    if (this.deletedPortfoliosCount > 0) {
      this._entityEditorService.entityChangedMessage({relatedType: EntityType.Portfolio, relatedId: this.deletedPortfolioId, entityAction: EntityAction.PortfolioDeleted });
    }
    this._entityEditorService.close();
  }

  public savePortfolio(closeOnSuccess: boolean = false): void {
    this._portfolioEditorService.savePortfolio()
      .pipe(
        catchError(errors => {
          return throwError(() => errors);
        }),
        tap(result => {
          if (this.isPortfolioDetailsModelFFEnabled) {
            result = result?.[0] || result;
          }
          this.closeOnSuccess = closeOnSuccess;
          if (result) {
            if (closeOnSuccess) {
              this.visible = false;
              return;
            }
            this._portfolioEditorService.portfolioId$.next(result.id);
          }
        })
      ).subscribe();
  }

  public onTreeEntityChanged(entity: IEntityChangedEvent): void {
    switch (entity.entityType) {
      case EntityType.Portfolio:
        this._entityEditorService.show({
          entityType: EditorEntityType.Portfolio,
          data: { id: entity.id, editMode: !!this.portfolioEditPermission },
        });
        break;
      case EntityType.Account:
        this._entityEditorService.show({
          entityType: EditorEntityType.Account,
          data: { id: entity.id, editMode: false }, // always go to View page by default
        });
        break;
      default:
        console.error('Unsupported entity type', entity);
    }
  }

  /**
   * Opens the portfolio in the new editor experience.
   */
  openInLegacyEditor(): void {
    this._entityEditorService.show({
      entityType: EditorEntityType.Portfolio,
      data: { newEditor: false, editMode: this.portfolioEditPermission, id: this._portfolioEditorService.portfolio?.id }
    });
  }

  private getActionsMenu(portfolio: PortfolioEntity, options: any): MenuItem[] {
    if (!portfolio?.id) {
      return null;
    }

    const portfolioPrivilege = this._sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS);
    const accountPrivilege = this._sessionHelper.getPermission(Consts.PRIV_ACCOUNTS);

    const menuItems: MenuItem[] = [
      {
        label: 'Assign Portfolio',
        icon: 'fas fa-fw fa-briefcase-arrow-right',
        visible: !!portfolioPrivilege.canUpdate && !!accountPrivilege.canUpdate && !portfolio.isDisabled && !portfolio.isSleevePortfolio,
        command: () => {
          this.assignPortfolio(this._portfolioEditorService.portfolio.id);
        }
      },
      {
        label: 'Delete',
        icon: 'fas fa-fw fa-trash-can',
        disabled: !!this._portfolioEditorService.accounts?.length,
        visible: !!portfolioPrivilege.canDelete && !portfolio.isSleevePortfolio && this._portfolioEditorService.accounts?.length === 0,
        command: () => {
          this.deleteConfirm(this._portfolioEditorService.portfolio.id);
        }
      },
      {
        label: 'Preferences',
        icon: 'fas fa-fw fa-list-check',
        visible: !!portfolioPrivilege.canUpdate && !!this._portfolioEditorService.portfolioId$.getValue(), /*&& !portfolio?.hasSMAs */
        command: () => {
          this._router.navigate(['/eclipse/admin/preferences/portfolio', this._portfolioEditorService.portfolio.id]);
        }
      },
      {
        label: 'Exclude/Include Portfolio(s)',
        icon: 'fas fa-fw fa-object-exclude',
        visible: !!portfolioPrivilege.canUpdate,
        command: () => {
          this.openExcludeFilterPopUp();
        }
      },
      {
        label: 'Edit Sleeves',
        icon: 'fas fa-fw fa-pencil',
        visible: !!portfolioPrivilege.canUpdate && !!portfolio.isSleevePortfolio,
        command: () => {
          this.navigateToOCSleeveEdit(portfolio.id);
        }
      },
      {
        label: 'Sleeve Transfer',
        icon: 'fas fa-fw fa-swap',
        visible: !!portfolioPrivilege.canUpdate && !!portfolio.isSleevePortfolio,
        command: () => {
          this.navigateToOCSleeveTransfer([portfolio.registrationId], portfolio.orionConnectFirmId);
        }
      },
      {
        separator: true
      },
      {
        label: 'Run Portfolio\'s Analytics',
        icon: 'fas fa-fw fa-bolt-lightning',
        disabled: portfolio.needAnalyticsStatus === 'In Progress',
        command: () => {
          this.runPortfolioAnalysis(portfolio.id);
        }
      },
      {
        label: 'Reverse Sync Portfolio',
        icon: 'fas fa-fw fa-reply-clock',
        visible: options.reverseSyncPortfolioPreference,
        command: () => {
          this.openReverseSyncPortfoliosPopUp();
        }
      },
      {
        label: 'Sync Accounts from Orion',
        icon: 'fas fa-fw fa-arrow-right-from-arc',
        command: () => {
          this.openPortfolioSyncFromOrionConfirmationDialog([portfolio.id]);
        }
      },
      {
        separator: true
      },
      {
        label: 'Trade Tools',
        icon: 'fas fa-fw fa-display-chart-up-circle-dollar',
        items: [
          {
            label: 'Cash Needs',
            visible: Util.hasTradeToolRight(Consts.PRIV_CASHNEEDS),
            command: () => this._portfolioEditorService.navigateToTradeTool('CashNeeds'),
          },
          {
            label: 'Global Trades',
            visible: Util.hasTradeToolRight(Consts.PRIV_GLOBALTRADES),
            command: () => this._portfolioEditorService.navigateToTradeTool('GlobalTrades'),
          },
          {
            label: 'Option Trading',
            visible: Util.hasTradeToolRight(Consts.PRIV_OPTION_TRADING),
            command: () => this._portfolioEditorService.navigateToTradeTool('Options'),
          },
          {
            label: 'Raise Cash',
            visible: Util.hasTradeToolRight(Consts.PRIV_RAISECASH),
            command: () => this._portfolioEditorService.navigateToTradeTool('RaiseCash'),
          },
          {
            label: 'Rebalancer',
            visible: !portfolio.isDisabled && Util.hasTradeToolRight(Consts.PRIV_REBALANCER),
            command: () => this._portfolioEditorService.navigateToTradeTool('Rebalancer'),
          },
          {
            label: 'Spend Cash',
            visible: Util.hasTradeToolRight(Consts.PRIV_SPENDCASH),
            command: () => this._portfolioEditorService.navigateToTradeTool('SpendCash'),
          },
          {
            label: 'Tactical Tool',
            visible: Util.hasTradeToolRight(Consts.PRIV_TACTICAL),
            command: () => this._portfolioEditorService.navigateToTradeTool('Tactical'),
          },
          {
            label: 'Tax Harvesting',
            visible: Util.hasTradeToolRight(Consts.PRIV_TAXHARVESTING),
            command: () => this._portfolioEditorService.navigateToTradeTool('TLH'),
          },
          {
            label: 'Liquidate',
            visible: Util.hasTradeToolRight(Consts.PRIV_LIQUIDATE),
            command: () => this._portfolioEditorService.navigateToTradeTool('Liquidate'),
          },
        ]
      }
    ];
    return menuItems;
  }

  private getSidebarMenuItems() {
    let menuItems: MenuItem[] = [
      {
        label: 'Portfolio Details',
        items: [
          {
            id: PortfolioEditorPage.Summary,
            label: 'Summary',
            styleClass: 'editor-active-nav-menu-item',
            command: (evt) => this.loadComponent(evt.item.id),
          },
          {
            id: PortfolioEditorPage.Cash,
            label: 'Cash',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: !this.portfolioCashEnabled || !this._portfolioEditorService.portfolio?.id,
            visible: this.portfolioCashEnabled && !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.Accounts,
            label: 'Accounts',
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: (evt) => this.loadComponent(evt.item.id),
            visible: !!this._portfolioEditorService.portfolio?.id && this.accountAccessPermission,
          },
          {
            id: PortfolioEditorPage.Model,
            label: 'Model',
            icon: this._portfolioEditorService.portfolio?.flagDispApprovalReject ? 'fas fa-octagon-xmark' : null,
            iconClass: 'menu-item-model',
            escape: false,
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: !this._portfolioEditorService.portfolio?.id,
            visible: this.isPortfolioDetailsModelFFEnabled && !!this._portfolioEditorService.portfolio?.id && this.modelAccessPermission,
          },
          {
            id: PortfolioEditorPage.TradeBlock,
            label: 'Trade Block',
            command: (evt) => this.loadComponent(evt.item.id),
            visible: !!this._portfolioEditorService.portfolio?.id && this.multiTradeBlockFF,
          },
          {
            id: PortfolioEditorPage.Notes,
            label: 'Notes',
            badge: this._portfolioNotesCount.toString(),
            badgeStyleClass: this._portfolioEditorService.notes ? 'badge primaryAlt' : null,
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: !(!!this._portfolioEditorService.portfolio?.id && this.canReadNotes),
            visible: !!this._portfolioEditorService.portfolio?.id && this.canReadNotes,
          },
          {
            id: PortfolioEditorPage.GainLoss,
            label: 'Gain/Loss',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: false,
            visible: this.gainLossFF && !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.Holdings,
            label: 'Holdings',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: false,
            visible: this.holdingsFF && Util.hasTradeToolRight(Consts.PRIV_HOLDINGS) && !!this._portfolioEditorService.portfolio?.id,
          },
          {
            id: PortfolioEditorPage.CompareTool,
            label: 'Compare Tool',
            command: (evt) => this.loadComponent(evt.item.id),
            disabled: !this.compareToolEnabled || !this.compareToolEnabledPreference || !this._portfolioEditorService.portfolio?.id,
            visible: !this.compareToolEnabled || !this.compareToolEnabledPreference || !!this._portfolioEditorService.portfolio?.id,
          },
        ],
      }, {
        label: 'Portfolio History',
        items: [
          {
            id: PortfolioEditorPage.TradeHistory,
            label: 'Trade History',
            visible: false, // !!this._portfolioEditorService.portfolio?.id,
            disabled: true,
          },
          {
            id: PortfolioEditorPage.AuditHistory,
            label: 'Audit History',
            //command: (evt) => this.loadComponent(evt.item.id),
            command: () => { // Temporary until Audit History editor page is created
              this._router.navigate(['/eclipse/portfolio/audit', this._portfolioEditorService.portfolio.id]);
            },
            disabled: !this._portfolioEditorService.portfolio?.id,
            visible: !!this._portfolioEditorService.portfolio?.id,
          },
        ],
      }, {
        label: 'Portfolio Configuration',
        items: [
          {
            id: PortfolioEditorPage.SleeveMaintenance,
            label: 'Sleeve Maintenance',
            visible: false, // Disabled for now until Sleeve Maintenance page is created //!!this._portfolioEditorService.portfolio?.isSleevePortfolio,
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: () => {
              const portfolioId = this._portfolioEditorService.portfolio.id;
              this._portfolioService.getPortfolioAccounts(portfolioId)
                .pipe(
                  switchMap((accounts: IAccount[]) => {
                    const accountOrionFirmId = accounts[0].ocFirmId;
                    return this._loginAsService.getFirmToken(accountOrionFirmId);
                  }),
                  switchMap((token: IToken) => {
                    return this._portfolioService.getPortfolioById(portfolioId)
                      .pipe(
                        tap((portfolio: IPortfolioDetails) => {
                          const registrationId = portfolio.general.registrationId;
                          const connectSleeveEditUrl = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html?p=/portfolio/edit/registrations/${registrationId}?tabCode=sleeveSetup&m=crm&t=${token.orion_access_token}`;
                          this._window.open(connectSleeveEditUrl);
                        })
                      );
                  })
                ).subscribe();
            }
          },
          {
            id: PortfolioEditorPage.Preferences,
            label: 'Preferences',
            visible: false, // Hide until Preferences component is added to the editor
            disabled: !this._portfolioEditorService.portfolio?.id,
            command: () => {
              this._router.navigate(['/eclipse/admin/preferences/portfolio', this._portfolioEditorService.portfolioId$.value]);
              this.visible = false;
            },
          },
        ],
      },
    ];

    // If a root menu item has child items and they're all hidden, hide the root menu item too.
    menuItems = menuItems.filter(rootMenuItem => {
      return rootMenuItem.items?.length // must have child items
        && rootMenuItem.items.some(childItem => childItem.visible === undefined || childItem.visible); // items must be visible
    });

    // Set the active menu style on the item for the current page
    const setMenuItemStyle = (item: MenuItem) => {
      if (item.items?.length) {
        item.items.forEach(setMenuItemStyle);
        return;
      }
      item.styleClass = this._activePage.getValue() === item.id ? 'editor-active-nav-menu-item' : null;
    };
    menuItems.forEach(setMenuItemStyle);

    return menuItems;
  }

  private navigateToOCSleeveTransfer(registrationIds: number[], firmId: number): void {
    this._portfolioService.getOCSleeveTransferUriForRegistrations(registrationIds, firmId)
      .subscribe((connectTransferUri: string) => {
        if (connectTransferUri) {
          this._window.open(`${connectTransferUri }&isFullPage=true`);
        }
      });
  }

  private navigateToOCSleeveEdit(portfolioId) {
    this._portfolioService.getPortfolioAccounts(portfolioId)
      .subscribe((accounts: IAccount[]) => {
        if (accounts?.length) {
          const accountOrionFirmId = accounts[0].ocFirmId;
          this._loginAsService.getFirmToken(accountOrionFirmId)
            .subscribe((token: IToken) => {
              if (portfolioId > 0) {
                this._portfolioService.getPortfolioById(portfolioId)
                  .subscribe((portfolio: IPortfolioDetails) => {
                    const registrationId = portfolio.general.registrationId;
                    const connectSleeveEditUrl = `${ConfigService.settings.orionAdvisorEndPoint}orionconnectapp/integration.html?p=/portfolio/edit/registrations/${registrationId}?tabCode=sleeveSetup&isFullPage=true&m=crm&t=${token.orion_access_token}`;
                    this._window.open(connectSleeveEditUrl);
                  });
              }
            });
        }
      });
  }

  private assignPortfolio(portfolioId) {
    if (portfolioId > 0) {
      this._portfolioService.getPortfolioById(portfolioId)
        .subscribe((portfolio: IPortfolioDetails) => {
          const assignTeam = portfolio.teams.find(m => m.isPrimary);
          const assignPortfolio = <IPortfolio>{
            id: portfolio.id,
            name: portfolio.general.portfolioName,
            team: assignTeam?.name,
            model: portfolio.general.modelName,
            managedValue: portfolio.summary.AUM.managedValue,
            excludedValue: portfolio.summary.AUM.excludedValue,
            reserveCashActual: portfolio.summary.reserveCashActual,
            reserveCashTarget: portfolio.summary.reserveCashTarget,
            currentCash: portfolio.summary.currentCash,
            pendingCash: portfolio.summary.pendingCash,
            targetCash: portfolio.summary.targetCash
          };
          this.assignComponent.initFromPortfolio([assignPortfolio]);
          this.showAssignPopup = true;
        });
    }
  }

  private runPortfolioAnalysis(portfolioId) {
    this._portfolioService.runPortfolioAnalysis([portfolioId])
      .subscribe(responceData => {
      });
  }

  private openReverseSyncPortfoliosPopUp(){
    this.showConfirmReverseSyncPortfolios = true;
  }

  confirmReverseSyncPortfolios(){
    this._portfolioService.runReverseSyncPortfolios([this._portfolioEditorService.portfolio.id])
      .subscribe(() => {
          this.showConfirmReverseSyncPortfolios = false;
          this.showReverseSyncPortfoliosMessage = true;
        },
        error => {
          this.showConfirmReverseSyncPortfolios = false;
        });
  }

  openPortfolioSyncFromOrionConfirmationDialog(portfolioIds: number[]) {
    this.shouldShowPortfolioSyncFromOrionConfirmationDialog = true;
    this.syncFromOrionPortfoliosCount = portfolioIds.length;
  }

  confirmSyncFromOrionPortfolios() {
    this.shouldShowPortfolioSyncFromOrionConfirmationDialog = false;
    this.shouldEnableRsyncStatusOkButton = false;
    this.setResyncStatus('Sending request to initiate sync...');
    this.shouldShowRsyncStatusDialog = true;
    this._portfolioService.runSyncAccountsFromOrionByPortfolioId([this._portfolioEditorService.portfolio.id])
      .subscribe({
        next: () => {
          this.setResyncStatus('The sync was succesfully requested.');
        },
        error: (e) => {
          if (e.status === 403) {
            this.rSyncErrorMessage = e.error.Message;
            this.setResyncStatus('This feature is not available.');
            return;
          }
          if (e?.error.isResyncAllowed === false){
            this.rSyncErrorMessage = e.error.resyncValidationMessage;
            this.setResyncStatus('Unable to sync accounts from this grid. Please sync from Orion Connect or run a Delta.');
            return;
          }

          this.rSyncErrorMessage = e.message;
          this.setResyncStatus('The sync request failed.');
        }
      }).add(() => {
      this.shouldEnableRsyncStatusOkButton = true;
    });
  }

  private setResyncStatus(status: string){
    this.rsyncStatus = status;
  }

  openExcludeFilterPopUp() {
    this.showExcludeFilterPopup = true;
    this.portfolioIdsToExclude = [this._portfolioEditorService.portfolio.id];
  }

  resetExcludeFilter() {
    this.showExcludeFilterPopup = false;
  }

  /*** Display child page assign popup */
  callbackAssignPopup() {
    this.showAssignPopup = false;
  }

  /** Delete check based on accounts length */
  private deleteConfirm(portfolioId: number) {
    if (portfolioId === undefined) {
      return;
    }
    this._portfolioService.getPortfolioAccountsCountSummary(portfolioId)
      .subscribe(portfolioAccounts => {
        if (portfolioAccounts.count > 0) {
          this.deleteCheck = true;
        } else {
          this.displayConfirm = true;
        }
      });
  }

  /** Function to pass record index value to delete and make dialog to close*/
  deletePortfolio() {
    this.unDeletedPortfoliosCount = 0;
    this.deletedPortfoliosCount = 0;
    this.displayConfirm = false;

    this._portfolioService.deletePortfolio(this._portfolioEditorService.portfolio.id)
      .subscribe({
        next: (response) => {
          this.deletedPortfolioId = this._portfolioEditorService.portfolio.id;
          this._portfolioEditorService.portfolioId$.next(null);
          this.deletedPortfoliosCount = response.deletedCount;
        },
        complete: () => {
          this.deleteMessage = true;
        },
      });
  }

  hideDeleteMessage() {
    this.deleteMessage = false;
    if (this.deletedPortfoliosCount > 0) {
      this.closeEditor();
    }
  }

  refreshData(analyticsNotification: IAnalyticsNotification): void {
    if (!analyticsNotification?.portfolioFlags?.length) {
      return;
    }
    const portfolio = analyticsNotification.portfolioFlags.find(flag => flag.portfolioId === +this._portfolioEditorService.portfolio?.id);
    if (portfolio && (portfolio.needAnalytics !== AnalyticsStatus.Running && analyticsNotification?.isAnalyticsCompleted)) {
      if (!!this._portfolioEditorService.portfolio?.id) {
        this._portfolioEditorService.portfolioId$.next(this._portfolioEditorService.portfolio?.id);
      }
    }
  }
}
