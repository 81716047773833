<p-dialog header="Confirm Action" [(visible)]="showModelClearConfirm && isPortfolioDetailsModelFFEnabled"
  modal="true" class="ui-dialog-md">
  <div>Are you sure you want to unassign model "{{(portfolioEditorService.portfolio$ | async)?.modelName}}" from this
    portfolio?</div>
  <div>Clicking NO means that no portfolio settings will be saved.</div>
  <p-footer>
    <button type="button" pButton icon="fas fa-times" (click)="portfolioEditorService.closeModelClearConfirmDialog()"
      class="p-button-secondary" label="No"></button>
    <button type="button" pButton icon="fas fa-check" (click)="portfolioEditorService.checkMacStatusAndUnAssignModel()"
      label="Yes"></button>
  </p-footer>
</p-dialog>
