import { Injectable } from "@angular/core";
import { OEHttpClient, SessionHelper } from "../core";
import { Observable } from "rxjs";
import { ITradeBlockReason } from "../models/trade-block-reasons";

@Injectable({
  providedIn: 'root',
})
export class TradeBlockReasonsService{
  private _tradeBlockReasonsEndpoint = 'v2/tradeblockreasons';
  constructor(private _httpClient: OEHttpClient, private _sessionHelper: SessionHelper){
  }
  getTradeBlockReasons() : Observable<ITradeBlockReason[]> {
    return this._httpClient.getData(`${this._tradeBlockReasonsEndpoint}`);
  }
}
