import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FileHandlerService} from '../../services/filehandler.service';
import {BaseComponent} from '../../core/base.component';
import {IModelExcelImport} from '../../models/modeling/modelexcelimport';
import {SecuritySetService} from '../../services/securityset.service';
import {AlertService} from '../../core';
import { FILE_MIME_TYPES, IMPORT_EXPORT_MODULE_NAME } from '../../libs/app.constants';
import { Workbook } from 'exceljs';
import {IEquivalentExportFileData} from '../../models/preferences/securityPreferenceEquivalents';

@Component({
  selector: 'eclipse-file-import-export',
  templateUrl: './file.importexport.component.html',
  styleUrls: ['./file.importexport.component.scss'],
  providers: [FileHandlerService]
})
export class FileImportExportComponent extends BaseComponent {

  showImportPopUp = false;
  moduleName: string;
  fileUploadError: string;
  showFileUploadError: boolean = false;
  file: IModelExcelImport = <IModelExcelImport>{};
  disableUploadBtn: boolean = true;
  dragFileName: string;
  checkDragFile: boolean = false;
  jsonArray: any[] = [];
  checkUploadFile: boolean = true;
  showCloseBtn: boolean = true;
  uploadErrors: boolean = false;
  areErrors: boolean = true;
  isError: boolean = false;
  subscription: any;
  @Input() ids;
  @Input() path;
  @Input() downloadFileName: string;
  @ViewChild('fileImportInput') fileImportInput: any;
  @Output() parentExportCallBack = new EventEmitter();
  @Output() parentImportCallBack = new EventEmitter();
  @Output() parentBlankTemplateCallBack = new EventEmitter();
  @Output() parentAboutImportCallBack = new EventEmitter();
  private errorLog: any;

  constructor(private _securitySetService: SecuritySetService,
              private _fileHandlerService: FileHandlerService,
              private _alertService: AlertService) {
    super();
  }

  exportToExcel(fileData: IEquivalentExportFileData[], headers: string[]): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.addRow(headers);
    fileData.forEach(data => worksheet.addRow(Object.values(data)));

    workbook.xlsx.writeBuffer().then(buffer => {
      this.downloadFile(buffer, `${this.downloadFileName}.xlsx`, FILE_MIME_TYPES.EXCEL);
    });
  }

  showPopUp(tab): void {
    this.moduleName = tab;
    this.showImportPopUp = true;
  }

  exportFileData(): void {
    if (this.ids?.length) {
      if (this.moduleName === IMPORT_EXPORT_MODULE_NAME.SECURITY_SET) {
        this.exportSecuritySetData();
      } else {
        this.parentExportCallBack.emit();
      }
    } else {
      this._alertService.alert.emit([{typeId: 4, message: `Please select ${this.moduleName}.`}]);
    }
  }

  importCsvFile(event): void {
    let file;
    if (event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
    } else if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      file = event.dataTransfer.files[0];
    }
    this.disableUploadBtn = false;
    this.showFileUploadError = false;
    const selectedFile = file;
    if (selectedFile && this.isValidExcelFile(selectedFile.type)) {
      this.file.file = selectedFile;
      this.file.name = selectedFile.name;
    } else {
      this.fileUploadError = 'Only (* .xlsx or .xls)  file can be uploaded.';
      this.showFileUploadError = true;
      this.disableUploadBtn = true;
    }
  }

  /** Fires when file is dragged */
  dragFile(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  /** Fires when file is dropped */
  dropFile(event): void {
    if (event.dataTransfer.files.length !== 1) {
      this.fileUploadError = 'Only one file can be uploaded at a time.';
      this.showFileUploadError = true;
    } else {
      this.checkDragFile = true;
      this.dragFileName = event.dataTransfer.files[0].name;
      this.importCsvFile(event);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  /** Fires when cancel is clicked */
  cancelImport(): void {
    this.fileImportInput.nativeElement.value = '';
    this.showImportPopUp = false;
    this.disableUploadBtn = true;
    this.checkDragFile = false;
    this.showFileUploadError = false;
    this.checkUploadFile = true;
    this.showCloseBtn = true;
    this.uploadErrors = false;
    this.areErrors = false;
    this.isError = true;
    this.isError = false;
    this.areErrors = true;
    this.jsonArray = [];
  }

  uploadTemplate(): void {
    this.cancelImport();
    if (this.moduleName === IMPORT_EXPORT_MODULE_NAME.SECURITY_SET) {
      this.importSecuritySet();
    } else {
      this.parentImportCallBack.emit(this.file);
    }
  }

  /** Check whether json object is array or not */
  isArray(what): boolean {
    return Object.prototype.toString.call(what) === '[object Array]';
  }

  private exportSecuritySetData(): void {
    const ids = this.ids.map(s => s.id);
    this._securitySetService.getAllSecuritySetDetail({securitySetIds: ids})
      .subscribe(model => {
        this.downloadXLSXFile(model, this.downloadFileName);
      });
  }

  private downloadXLSXFile(data, fileName): void {
    const blob = new Blob([data], {type: 'text/vnd.ms-excel'});
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', `${fileName}.xlsx`);
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private importSecuritySet(): void {
    this._fileHandlerService.uploadSecuritySetTemplate(this.file)
      .subscribe(data => {
          const message = data.message;
          if (data.status) {
            this._alertService.alert.emit([{typeId: 1, message: message}]);
          } else {
            this._alertService.alert.emit([{typeId: 4, message: message}]);
          }
        },
        error => {
          this.disableUploadBtn = false;
          this.checkUploadFile = false;
          this.showCloseBtn = false;
          this.uploadErrors = true;
          const isjSnArray = this.isArray(JSON.parse(error).message);
          if (isjSnArray) {
            this.areErrors = true;
            this.isError = false;
          } else {
            this.isError = true;
            this.areErrors = false;
          }
          this.errorLog = JSON.parse(error).message;
          this._alertService.alert.emit([{typeId: 4, message: this.errorLog}]);
        });
  }
}
