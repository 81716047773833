<section style="display: flex; flex-direction: column; height: 100%;"
  *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingMeta">
  <div class="page-header-row">
    <h3>Model</h3>
  </div>

  <div class="section-row">
    <div class="section-column">
      <p-card>
        <div class="section-content-row" style="display: grid; grid-template-columns: minmax(0, 2fr) 1fr; gap: 1rem"
          *ngIf="portfolioEditorService.portfolio$ | async as portfolio; else loadingDetails">
          <section *ngIf="!portfolio.isSleevePortfolio" class="section-content-column">
            <div class="content-inputs-column">
              <div>
                <label id="modelLabel" for="model">Model</label>
                <div class="flex gap-1">
                    <div class="flex-grow-1 form-group">
                      <p-autoComplete id="model" class="autocomplete-search" ariaLabelledBy="model"
                        [(ngModel)]="portfolio.model" ngDefaultControl [suggestions]="modelSuggestions"
                        placeholder="Search Model" panelStyleClass="overlay-width-lg" [minLength]="1" field="name"
                        (onSelect)="portfolioEditorService.onModelSelect($event)"
                        (keyup)="portfolioEditorService.onModelUnSelect($event)" [disabled]="!modelsPermission"
                        (completeMethod)="searchModels($event)">
                        <ng-template let-selectedItem pTemplate="item">
                          <div class="flex align-items-center" style="gap: .5rem">
                            <i class="fas fa-flip-vertical fa-code-branch" aria-hidden="true"></i>
                            <span class="wrap">{{ selectedItem.displayFormat }}</span>
                          </div>
                        </ng-template>
                      </p-autoComplete>
                    </div>
                    <button pButton label="Unassign Model" class="p-button-secondary white-space-no-wrap"
                    (click)="portfolioEditorService.modelCleared()" [disabled]="portfolio.disableUnAssignModel"></button>
                </div>
              </div>
              <div>
                <label for="model-description">Model Description</label>
                <textarea readonly id="model-description" rows="5" cols="30" pInputTextarea
                  [(ngModel)]="portfolio.modelDescription"></textarea>
              </div>
              <div *ngIf="portfolio.flagDispApprovalReject">
                <span><i class="fas fa-octagon-xmark text-warning m-r-5 pull-left"></i></span>
                <span class="pending-model-message">
                  Pending assignment to <strong>{{portfolio.pendingApprovalModelName}}</strong> model. Please click Review to approve or reject the model assignment.
                </span>
                <button pButton label="Review" class="p-button-secondary pull-right p-button-sm"
                  (click)="showPortfolioModelApprovalPopup()" [disabled]="portfolio.isLinkDisabled" size="small">
                </button>
              </div>
              <div class="summary-box-footer">
                <button pButton label="View Eclipse Models" class="p-button-secondary" (click)="gotoModel()"
                  [disabled]="!modelsPermission"></button>
                <button pButton label="Find Community Models" class="p-button-secondary" (click)="openCommunityPopup()"
                  [disabled]="!modelsPermission"></button>
              </div>
            </div>
          </section>
          <section class="section-content-column">
            <div class="content-data-column">
              <div style="display: flex;align-items: flex-start;align-self: stretch;">
                <div class="content-data-list">
                  <div class="detail-field-box">
                    <label for="cagtegoryDeviation">Category Deviation</label>
                    <div id="cagtegoryDeviation">
                      {{portfolio?.categoryDeviationPercent}}
                    </div>
                  </div>
                  <div class="detail-field-box">
                    <label for="classDeviation">Class Deviation</label>
                    <div id="classDeviation">
                      {{portfolio?.classDeviationPercent}}
                    </div>
                  </div>
                  <div class="detail-field-box">
                    <label for="subClassDeviation">Subclass Deviation</label>
                    <div id="subClassDeviation">
                      {{portfolio?.subclassDeviationPercent}}
                    </div>
                  </div>
                  <div class="detail-field-box">
                    <label for="securitySetDeviation">Security Set Deviation</label>
                    <div id="securitySetDeviation">
                      {{portfolio.securitySetDeviationPercent}}
                    </div>
                  </div>
                  <div class="detail-field-box">
                    <label for="securityDeviation">Security Deviation</label>
                    <div id="securityDeviation">
                      {{portfolio?.securityDeviationPercent}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style="display: grid; grid-template-columns: 1fr 2fr; gap: 1rem;">
            <div>
              <label for="autoRebalance">Auto Rebalance</label>
              <p-dropdown id="autoRebalance" [options]="autoRebalanceList" [(ngModel)]="portfolio.rebalanceTypeId"
                (ngModelChange)="onAutoRebalanceChange(portfolio.rebalanceTypeId)" optionLabel="rebalanceType"
                optionValue="id">
              </p-dropdown>
            </div>

            <div *ngIf="portfolio.rebalanceTypeId !== 1" style="display: grid; grid-template-columns: 1fr 0.5fr; gap: 1rem">
              <div>
                <label for="month">Month</label>
                <p-dropdown id="month" [options]="autoRebalanceOptionsList" [(ngModel)]="portfolio.rebalanceOptionId"
                  optionLabel="rebalanceMonth" optionValue="id">
                </p-dropdown>
              </div>
              <div>
                <label for="day">Day</label>
                <input pInputText id="day" type="number" [(ngModel)]="portfolio.rebalanceDay" class="form-control"
                  min="1" max="31" (keypress)="checkRebalanceDay($event, portfolio.rebalanceDay)"
                  (blur)="validateDayValue(portfolio.rebalanceDay)">
              </div>
            </div>
          </section>
          <p-dialog header="Portfolio - Model Assignment Approval" [resizable]="false"
            [(visible)]="portfolio.displayPfMdlApprvlSec" class="ui-dialog-lg model-assign" [draggable]=false
            modal="true">
            <table class="model-approval table">
              <caption class="hidden-element">Model Approval Table</caption>
              <thead>
                <tr>
                  <th scope="col">Portfolio</th>
                  <th scope="col">Old Model</th>
                  <th scope="col">New Model</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{portfolio.name}}</td>
                  <td>{{portfolio.model?.id ? portfolio.model.name : ''}}</td>
                  <td>{{portfolio.unApprovedModel?.name ?? ''}}</td>
                </tr>
              </tbody>
            </table>
            <p-footer class="ui-dialog-buttonpane ui-helper-clearfix" style="float: right;">
              <button type="button" pButton icon="fas fa-check"
                (click)="portfolioEditorService.approveOrRejectModelPortfolio('approve')" label="Approve"></button>
              <button type="button" pButton class="p-button-secondary" icon="fas fa-times"
                (click)="portfolioEditorService.approveOrRejectModelPortfolio('reject')" label="Reject"></button>
            </p-footer>
          </p-dialog>
          <p-dialog header="Find Community Models" [(visible)]="displayCommunityModel" modal="true" [resizable]=false
            class="ui-dialog-lg" [closable]="false">
            <div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label class="col-md-4 control-label" for="strategist">Strategist</label>
                    <div class="col-md-8">
                      <select id="strategist" class="form-control watermarked gray"
                        (change)="onStrategistSelection($event.target.value)" [(ngModel)]="selectedStrategist">
                        <option value="0" selected>Approved Strategists</option>
                        <option *ngFor="let cs of strategistList" id="{{cs.id}}" value="{{cs.id}}">{{cs.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="isFTJStrategist" class="form-group row">
                    <label class="col-md-4 control-label" for="manager">Manager</label>
                    <div class="col-md-8">
                      <select id="manager" class="form-control watermarked gray" [(ngModel)]="selectedManager"
                        (change)="managerChange()">
                        <option value="0" selected>Select Manager</option>
                        <option *ngFor="let ml of managerList" id="{{ml.communityManagerId}}"
                          value="{{ml.communityManagerId}}">
                          {{ml.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="!isFTJStrategist" class="form-group row">
                    <label class="col-md-4 control-label" for="managementStyle">Management Style</label>
                    <div class="col-md-8">
                      <select id="managementStyle" class="form-control watermarked gray" [(ngModel)]="selectedManagementStyle"
                        (change)="managementStyleChange($event.target.value)">
                        <option value="" selected="selected">Select Management Style</option>
                        <option *ngFor="let ms of managementStyleList" value="{{ms}}">{{ms}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-4 control-label" for="minimumAmount">Minimum Amount</label>
                    <div class="col-md-8">
                      <input id="minimumAmount" type="text" [disabled]="isMinAmountInputDisabled" class="form-control"
                        placeholder="Enter Amount" id="minAmt" name="minAmt" [(ngModel)]="minAmount"
                        (keyup)="filterModelsGridByMinAmount()" (keypress)="validateAmount($event, minAmount)">
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>
              <div class="margin-top-15">
                <ag-grid-angular #agGrid style="width: 100%; height: 180px;" class="ag-theme-alpine"
                  (rowSelected)="onRowSelected()" [rowData]="modelList" [gridOptions]="modelGridOptions"
                  [columnDefs]="modelColDefs" rowSelection="single" (modelUpdated)="onModelUpdatedOfModelsGrid()"
                  [suppressContextMenu]="true" (gridReady)="onModelGridReady($event)">
                </ag-grid-angular>
              </div>
            </div>
            <p-footer>
              <div class="col-md-6">
                <span *ngIf="strategistValidation" class="dialog-error">Please Select Strategist</span>
                <span *ngIf="strategistValidation && communityModelValidation">,</span>
                <span *ngIf="communityModelValidation" class="dialog-error">Please Select Model</span>
                <span class="dialog-error">{{ modelImportErrorMsg }}</span>
              </div>
              <div class="col-md-6">
                <button type="button" pButton icon="fas fa-check" (click)="findCommunity()" [disabled]="disableCImpSave"
                  label="Select"></button>
                <button type="button" pButton class="p-button-secondary" icon="fas fa-times"
                  (click)="cancelFindCommunityModel()" label="Cancel"></button>
              </div>
            </p-footer>
          </p-dialog>
          <eclipse-model-unassign-confirm></eclipse-model-unassign-confirm>
          <eclipse-mac-warning></eclipse-mac-warning>
        </div>
        <ng-template #loadingDetails>
          <div class="summary-content-grid">
            <div class="summary-box">
              <div class="summary-box-item">
                <p-skeleton height="6rem" width="20rem"></p-skeleton>
              </div>
            </div>
            <div class="summary-box">
              <div class="summary-box-item">
                <p-skeleton height="6rem" width="20rem"></p-skeleton>
              </div>
            </div>
            <div class="summary-box">
              <div class="summary-box-item">
                <p-skeleton height="6rem" width="20rem"></p-skeleton>
              </div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>

  <div *ngIf="portfolioEditorService.isModelApproved">
    <h5 class="section-title">Model Substitutes</h5>
    <div class="section-row">
      <div class="section-column">
        <p-card>
          <div class="section-content-row p-a-0" *ngIf="portfolio.modelSubModel?.length > 0 else loadingSubstitutes">
            <table class="table sub-model-table">
              <tr class="sub-model-grid-template">
                <th>SECURITY SET</th>
                <th class="text-right padding-right-48">SECURITY SET TARGET</th>
                <th>ASSIGN ALTERNATIVE SECURITY SET</th>
              </tr>
              <tbody>
                <ng-template ngFor [ngForOf]="portfolio.modelSubModel" let-submodel="$implicit"
                  let-submodelIndex="index">
                  <tr class="sub-model-grid-template sub-model-heading-tr">
                    <td data-toggle="tooltip" title="{{submodel.name}}">{{submodel.name}}</td>
                    <td class="text-right padding-right-48">{{submodel.targetPercent}}%</td>
                    <td *ngIf="submodel.substituteSubNode !== null" class="text-warning-grid">
                      {{submodel.substituteSubNode.name}}</td>
                    <td *ngIf="submodel.substituteSubNode === null && submodel.haveSubsitute === true"
                      class="text-warning-grid">Substitute set at upper level</td>
                    <td
                      *ngIf="!(submodel.substituteSubNode !== null && submodel.haveSubsitute === true)  && !canPortfolioAssignToModel"
                      class="text-warning-grid">Model contains a deleted security; cannot set substitute</td>
                    <td
                      *ngIf="submodel.substituteSubNode === null && !submodel.haveSubsitute && canPortfolioAssignToModel">
                    </td>
                  </tr>

                  <tr *ngFor="let securitySet of submodel.securitySets; let setIndex=index"
                    class="sub-model-grid-template">
                    <td data-toggle="tooltip" title="{{securitySet.name}}">{{securitySet.name}}</td>
                    <td class="text-right padding-right-48">{{securitySet.targetPercent}}%</td>
                    <td>
                      <div class="form-group">
                        <p-autoComplete class="autocomplete-search" [(ngModel)]="securitySet.substitutedId"
                          panelStyleClass="overlay-width-lg" [suggestions]="securitySet.filteredSecuritySet"
                          placeholder="Search for a Security Set..." name="securitySetSearch{{setIndex}}"
                          (completeMethod)="autoSecuritySetSearch($event, submodelIndex, setIndex)" field="name"
                          [minLength]="1" (ngModelChange)="onChange(securitySet)"
                          [disabled]="(modelNeedsUpdate || !!submodel.substituteSubNode || submodel.haveSubsitute || !canPortfolioAssignToModel)"
                          (onSelect)="handleSecuritySet(securitySet)"
                          [showClear]="!modelNeedsUpdate && securitySet.substitutedId && securitySet.substitutedId.id && canPortfolioAssignToModel"
                          (onClear)="removeAlternateSecuritySet(securitySet)">
                        </p-autoComplete>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>

          <ng-template #loadingSubstitutes>
            <p-skeleton height="6rem" width="100%"></p-skeleton>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>

  <div *ngIf="portfolioEditorService.showMacWeightingsPanel && portfolioEditorService.isModelApproved">
    <h5 class="section-title">Multi Asset Class Settings</h5>
    <div class="section-row">
      <div class="section-column">
        <p-card>
          <eclipse-security-weightings (parentCallback)="onAction($event)" [weightingModel]="weightingNavModel"
            [showUpdatedPanel]="true">
          </eclipse-security-weightings>
        </p-card>
      </div>
    </div>
  </div>
</section>
<ng-template #loadingMeta>
  <div class="flex m-t-5" style="gap: 10px; align-items: center;">
    <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
    <p-skeleton width="10rem" height="1.55rem"></p-skeleton>
  </div>
</ng-template>
