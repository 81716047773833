import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  GridOptions,
  ColDef,
  ValueGetterParams,
  GridApi,
  GridReadyEvent,
  GetContextMenuItemsParams,
  CellClickedEvent,
  ICellRendererParams,
  RowDoubleClickedEvent
} from '@ag-grid-community/core';
import { BaseComponent } from '../../../core/base.component';
import { ITradeLogFileDownload, ITradeLogs } from '../../../models/tom';
import { TomService } from '../../../services/tom.service';
import { AlertService } from '../../../core';
import { SplitIoService } from '../../../core/feature-flag';

@Component({
  selector: 'eclipse-trade-log',
  templateUrl: './trade-log.component.html',
})

export class TradeLogComponent extends BaseComponent {
  @Input()
  showTradeLogs: boolean = false;

  @Output()
  showTradeLogsChange = new EventEmitter<boolean>();

  private _tradeLogs: ITradeLogs[] = [];
  isAutoRebalanceOnAccountLevelFFEnabled: boolean;

  @Input()
  public get tradeLogs(): ITradeLogs[] {
    return this._tradeLogs;
  }

  public set tradeLogs(value: ITradeLogs[]) {
    this._tradeLogs = value;
    this.hasTradeLogs = value && value.length > 0;
    this.tradeLogsChange.emit(value);
  }

  @Output()
  tradeLogsChange = new EventEmitter<ITradeLogs[]>();

  hasTradeLogs: boolean = false;

  gridApi: GridApi<ITradeLogs>;

  gridContext = {
    self: this,
  };

  tradeLogsGridOptions: GridOptions;
  tradeLogsColumnDefs: ColDef[];

  constructor(private _tomService: TomService,
              private _alertService: AlertService,
              private readonly _splitIoService: SplitIoService) {
    super();

    this.isAutoRebalanceOnAccountLevelFFEnabled = !!this._splitIoService.featureFlags['auto_rebal_account_level_b10975'];

    this.tradeLogsGridOptions = this.defaultFilterableGridOptions;

    this.createColumnDefs();
  }

  createColumnDefs() {
    const self = this;

    //expand the grid group by default
    self.tradeLogsGridOptions.groupDefaultExpanded = -1;

    this.tradeLogsColumnDefs = [
      <ColDef>{
        colId: 'downloadTradeLog',
        headerName: 'Download Trade Log',
        field: 'downloadTradeLog',
        width: 50,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
        cellClass: 'text-center',
        cellRenderer: this.downloadTradeLogCellRenderer,
        onCellClicked: this.onDownloadTradeLogCellClicked,
      },
      <ColDef>{
        colId: 'tradeInstanceId',
        headerName: 'Trade Instance ID',
        field: 'tradeInstanceId',
        width: 100,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
        rowGroup: true,
        groupHideOpenParents: true,
      },
      <ColDef>{
        colId: 'portfolioId',
        headerName: 'Portfolio ID',
        field: 'portfolioId',
        width: 100,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'portfolioName',
        headerName: 'Portfolio Name',
        field: 'portfolioName',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'createdDate',
        headerName: 'Created On',
        field: 'createdDate',
        width: 220,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
        filterParams: {dataType: 'date'},
        valueGetter: (params) => {
          return self.dateTimeValueGetter('createdDate', params);
        },
      },
      <ColDef>{
        colId: 'description',
        headerName: 'Description',
        field: 'description',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'accountId',
        headerName: 'Account ID',
        field: 'accountId',
        width: 100,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'accountName',
        headerName: 'Account Name',
        field: 'accountName',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'error',
        headerName: 'Error Message',
        field: 'error',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'tradeToolSelectionName',
        headerName: 'Selection Method',
        field: 'tradeToolSelectionName',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'tradeInstanceTypeName',
        headerName: 'Type',
        field: this.isAutoRebalanceOnAccountLevelFFEnabled ? 'tradeInstanceTypeName' : 'tradeInstanceSubTypeName',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
      <ColDef>{
        colId: 'tradeInstanceSubTypeName',
        headerName: 'Sub Type',
        field: 'tradeInstanceSubTypeName',
        width: 150,
        enableRowGroup: false,
        enablePivot: false,
        filter: 'agTextColumnFilter',
      },
    ];
  }

  dateTimeValueGetter(colId: string, params: ValueGetterParams<ITradeLogs>) {
    if (!!params.data) {
      return this.formatUTCToLocal(params.data.createdDate);
    }
  }

  hideDialog() {
    this.showTradeLogs = false;
    this.hasTradeLogs = false;
    this.tradeLogs = [];
    this.tradeLogsChange.emit(this.tradeLogs);
    this.showTradeLogsChange.emit(this.showTradeLogs);
  }

  getContextMenuItems(params: GetContextMenuItemsParams) {
    const self: TradeLogComponent = params.context.self;
    const contextResult = [];

    contextResult.push('export');

    //trade log download
    if (!+params.node?.group) {
      //only do this when we're not in a row group
      contextResult.push({
        name: 'Download Trade Log',
        icon: '<i class="fas fa-download" aria-hidden="true"></i>',
        action: () => {
          self.downloadTradeLog(+params.node.data.id);
        },
      });
    }

    return contextResult;
  }

  downloadTradeLogCellRenderer(params: ICellRendererParams) {
    if (!params.node?.group) {
      //only do this when we're not in a row group
      return '<span class="fas fa-download"></span>';
    }
  }

  onDownloadTradeLogCellClicked(event: CellClickedEvent) {
    if (!event.node?.group) {
      //only do this when we're not in a row group
      const self: TradeLogComponent = event.context.self;

      self.downloadTradeLog(+event.data.id);
    }
  }

  onRowDoubleClicked(event: RowDoubleClickedEvent) {
    if (!event.node?.group) {
      //only do this when we're not in a row group
      const self: TradeLogComponent = event.context.self;

      self.downloadTradeLog(+event.data.id);
    }
  }

  downloadTradeLog(id: number) {
    if (id !== null) {
      this._tomService.getTradeLogDetails(id)
        .subscribe((tradeLogFile: ITradeLogFileDownload) => {
          if (tradeLogFile.isSuccess) {
            this.downloadByteArrayFile(tradeLogFile.content, tradeLogFile.path, tradeLogFile.fileType);
          } else {
            this._alertService.alert.emit([{typeId: 4, message: 'File Not Found.'}]);
          }
        });
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  onFirstDataRendered() {
    this.gridApi.autoSizeAllColumns();
  }
}
