import {Component, Input, Output, EventEmitter} from '@angular/core';
import {QueryBuilderService} from '../../services/querybuilder.service';
import {BaseComponent} from '../../core/base.component';

@Component({
  selector: 'eclipse-trade-groups-auto-complete',
  templateUrl: './tradegroups.autocomplete.component.html'
})
export class TradeGroupsAutoCompleteComponent extends BaseComponent {
  suggestions: any[] = [];
  selectedItem: string;
  searchText: string;
  @Input() tradeGroupTypeId: number;
  @Output() parentCallback = new EventEmitter();
  @Input() list: any;

  constructor(private _queryBuilderService: QueryBuilderService) {
    super();
  }

  /** To search trade groups */
  loadSuggestions(event) {
    // eslint-disable-next-line eqeqeq
    if (this.tradeGroupTypeId == undefined || this.tradeGroupTypeId == null) {
      this.tradeGroupTypeId = 0;
    }
    this._queryBuilderService.searchTradeGroups(event.query.toLowerCase(), this.tradeGroupTypeId)
      .subscribe((model: any) => {
        this.suggestions = model;

        // OE-2894
        if (this.list && this.list.length > 0) {
          this.list.forEach(item => {
            // eslint-disable-next-line eqeqeq
            this.suggestions = this.suggestions.filter(m => m.id != item.id);
          });
        }
      });
  }

  /** Fires on item select */
  onTradeGroupSelect(item) {
    this.selectedItem = item;
    this.parentCallback.emit({value: this.selectedItem});
  }

}
