import { ISecuritySet } from '../../../models/securityset';
import { IModel, ISubModelDetails } from '../../../models/modeling/model';
import { IPortfolioAnalytics, IPortfolioTeam, IPortfolioModel } from '../../../models/portfolio';

export enum PortfolioEditorPage {
  Summary = 'Summary',
  Cash = 'Cash',
  Accounts = 'Accounts',
  Model = 'Model',
  Notes = 'Notes',
  GainLoss = 'GainLoss',
  Holdings = 'Holdings',
  TradeHistory = 'TradeHistory',
  AuditHistory = 'AuditHistory',
  SleeveMaintenance = 'SleeveMaintenance',
  Preferences = 'Preferences',
  CompareTool = 'CompareTool',
  TradeBlock = 'TradeBlock'
}

export type PortfolioEntity = {
  id: number;
  name: string;
  tags: string;
  modelId?: number;
  modelName: string;
  isSleevePortfolio: boolean;
  isDisabled: boolean;
  doNotTrade: boolean;
  registrationId?: number;
  totalValue: number;
  managedValue: number;
  custodialAccountNumber: string;
  sleeveStrategyAggregateName: string;
  sleeveDeviationPercent?: number;
  categoryDeviationPercent?: number;
  classDeviationPercent?: number;
  subclassDeviationPercent?: number;
  securitySetDeviationPercent?: number;
  securityDeviationPercent?: number;
  autoRebalance: string;
  hasPendingTrades: boolean;
  lastTradedDate: Date;
  lastRebalanceDate: Date;
  lastTLHDate: Date;
  contribution: number;
  distribution: number;
  outOfTolerance: boolean;
  cashOutOfTolerance: boolean;
  cashNeed: number;
  cashNeedPercentage: number;
  cashPercent: number;
  cashTargetPercent: number;
  actualSetAsideCash: number;
  targetSetAsideCash: number;
  hasTLHOpportunity: boolean;
  needAnalytics: number;
  needAnalyticsStatus: string;
  orionConnectFirmId: number;
  portfolioGroupId?: number;
  hasSubstitutes: boolean;
  hasUnassignedHoldings: boolean;
  underMaxGainAmount: boolean;
  createdBy: string;
  createdDate?: Date;
  editedBy: string;
  editedDate?: Date;
  analyticsEditedDate: Date;
  failedReason: string;
  teams: IPortfolioTeam[]; // The selected teams objects
  rebalanceDay: number;
  rebalanceTypeId: number;
  rebalanceOptionId: number;
  rebalanceOption: string;
  buyEmphasisOn: number;
  sellEmphasisOn: number;
  isMac: boolean;
  // Added for use in the UI
  primaryTeamId: number;
  teamIds: number[];
  analytics: IPortfolioAnalytics[];
  model: IPortfolioModel;
  showDeviationSection: boolean;
  rebalanceType: string;
  nextAutoRebalanceDate?: Date;
  modelDescription: string;
  isAssignModel: boolean;
  disableUnAssignModel: boolean;
  flagDispApprovalReject: boolean;
  pendingApprovalModelId?: number;
  unApprovedModel: IPortfolioModel;
  pendingApprovalModelName: string;
  pendingApprovalModelDescription: string;
  displayPfMdlApprvlSec: boolean;
  macStatus: number;
  initialModelId: number;
  substitutedModelId: number;
  error: boolean;
  errorMsg: string;
  modelDetail: IModel;
  saveModel: boolean;
  substitutedModelDetail: IModel;
  modelSubModel: ISubModelDetails[];
  substituteSubModel: any[];
  deleteSubstitutedModel: boolean;
  skipReverseSynch: boolean;
  showModelSpinner: boolean;
  isLinkDisabled: boolean;
  minSetAsideCash: number;
  maxSetAsideCash: number;
};

export type PortfolioTacticalValidation = {
  portfolioId: number;
  portfolioName: string;
  errors: {
    typeId: number;
    message: string;
  }[];
};

export type PortfolioModelSubModel = {
  haveSubstitute: boolean;
  id: number;
  isModelParentNode: boolean;
  name: string;
  securitySets: ISecuritySet[];
  substitutedOf: unknown;
  targetPercent: number;
};
