import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { IDropDownOnChangeEvent } from '../../models/event';

@Component({
  selector: 'eclipse-custom-select-renderer',
  templateUrl: './custom-select-renderer.component.html',
  styleUrls: ['./custom-select-renderer.component.scss']
})
export class CustomSelectRendererComponent implements ICellRendererAngularComp {
  inputValue = 0;
  private params: ICellRendererParams;
  options: unknown;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.inputValue = this.params.value;

    if (params.colDef.headerName === 'T' || params.colDef.headerName === 'TD' || params.colDef.headerName === 'TE') {
      this.inputValue = params.data[params.colDef.field] ?? '1';
      params.data[params.colDef.field] = this.inputValue;
    }

    const options = this.params['getOptions'];
    if (options instanceof Function) {
      this.options = options(params);
    } else {
      this.options = this.params['options'];
    }

  }

  refresh(): boolean {
    return true;
  }

  onOptionChange(event: IDropDownOnChangeEvent): void {
    this.params.context.component.onOptionChange(this.params, event.value);
  }
}
