import { inject, Injectable } from '@angular/core';
import {OEHttpClient} from '../core';
import { HttpParams } from '@angular/common/http';
import { MAX_SEARCH_RESULTS } from '../libs/app.constants';
import { SplitIoService } from '../core/feature-flag';
import { IAccountSimpleSearch } from '../models/account';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SleeveService {
  private _sleeveSimpleSearchEndPoint = 'account/accounts/simple';
  private readonly _splitIoService = inject(SplitIoService);

  constructor(private _httpClient: OEHttpClient) {  }

  /** Sleeve accounts search */
  getSleevedAccountSearch(searchString: string): Observable<IAccountSimpleSearch[]> {
    if (this._splitIoService.featureFlags['TEXP_search_results_11698']) {
      const params = new HttpParams()
        .set('excludeNeedAnalytics', true)
        .set('excludeSleeve', true)
        .set('excludeSMA', true)
        .set('inSleeve', true)
        .set('includevalue', true)
        .set('search', searchString)
        .set('limit', MAX_SEARCH_RESULTS)
        .set('offset', 0);
      return this._httpClient.get<IAccountSimpleSearch[]>(`${this._sleeveSimpleSearchEndPoint}`, {params});
    } else {
      return this._httpClient.getData(
        `${this._sleeveSimpleSearchEndPoint
        }?excludeNeedAnalytics=true&excludeSleeve=true&excludeSMA=true&inSleeve=true&includevalue=true&search=${searchString}`);
    }
  }
}
