import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  share,
  shareReplay,
  switchMap,
  tap,
  throwError,
  Subject,
  merge
} from 'rxjs';
import { PortfolioService } from '../../../services/portfolio.service';
import { IEditPortfolio, IPortfolioTeam, IMACWeighting, IMACWeightingsValidation, PortfolioCashDetails, PortfolioGainLossSummary, IPortfolioModel } from '../../../models/portfolio';
import { INote } from '../../../models/notes';
import { NotesService } from '../../../services/notes.service';
import { EntityType } from '../../../libs/preference.enums';
import { PortfolioEntity, PortfolioTacticalValidation } from './types';
import { ITeam } from '../../../models/team';
import { UserService } from '../../../services/user.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AlertService, SessionHelper } from '../../../core';
import { Router } from '@angular/router';
import { LIQUIDATE_CONSTANTS } from '../../../libs/app.constants';
import { Utils } from '../../../core/functions';
import { sortBy as _sortBy } from 'lodash';
import { IAccount } from '../../../models/account';
import * as Consts from '../../../libs/app.constants';
import { IApproveRejectModelPortfolios } from '../../../models/modeling/approverejectmodelportfolios';
import { ModelService } from '../../../services/model.service';
import { IIdName } from '../../../models/tom';
import { SplitIoService } from '../../../core/feature-flag';
import { SecurityWeightingComponent } from '../../../shared/macweightings/security.weighting.component';
import { IModelDetailChildernSave, IModelDetailSave, IModelStructureSaveUpdate, ISubModelDetails } from '../../../models/modeling/model';
import { ModelType } from '../../../libs/model.constants';
import { IModelDetails } from '../../../models/modeling/modeldetails';
import { AlertTypeEnum } from '../../../viewModels/alert';

@Injectable()
export class PortfolioEditorService {
  public portfolioId$ = new BehaviorSubject<number>(undefined);
  public accounts$: Observable<IAccount[]>;
  public teams$: Observable<IPortfolioTeam[]>;
  public notes$: Observable<INote[]>;
  public portfolioNotes$: Observable<INote[]>;
  public teamNotes$: Observable<INote[]>;
  public portfolio$: Observable<PortfolioEntity>;
  private readonly refreshNotes$ = new Subject();
  public refreshNotes() {
    this.refreshNotes$.next(true);
  }
  public cashDetails$: Observable<PortfolioCashDetails>;

  private readonly _userService: UserService = inject(UserService);
  private readonly _portfolioService: PortfolioService = inject(PortfolioService);
  private readonly _notesService: NotesService = inject(NotesService);
  private readonly _alertService: AlertService = inject(AlertService);
  private readonly _localStorageService: StorageMap = inject(StorageMap);
  private readonly _router: Router = inject(Router);
  private readonly _sessionHelper: SessionHelper = inject(SessionHelper);
  private readonly _modelService: ModelService = inject(ModelService);
  private readonly _splitIOService: SplitIoService = inject(SplitIoService);

  public canReadNotes = !!Utils.getPermission(Consts.PRIV_NOTES)?.canRead;
  public readonly rebalancerToolPrivilege: boolean = Utils.hasTradeToolRight(Consts.PRIV_REBALANCER);
  public readonly cashNeedToolPrivilege: boolean = Utils.hasTradeToolRight(Consts.PRIV_CASHNEEDS);
  public readonly tlhToolPrivilege: boolean = Utils.hasTradeToolRight(Consts.PRIV_TAXHARVESTING);

  public rebalancerToolVisible$: Observable<boolean>;
  public cashNeedToolVisible$: Observable<boolean>;
  public tlhToolVisible$: Observable<boolean>;
  showMacWeightingsPanel: boolean;
  securityWeightingComponent: SecurityWeightingComponent;
  portfolioSave$: BehaviorSubject<boolean>;
  isPortfolioDetailsModelFFEnabled: boolean;
  modelId: number;
  isModelApproved: boolean;

  private readonly refreshModelData = new Subject<void>();
  public refreshModelData$ = this.refreshModelData.asObservable();
  savePortfolioDetail: boolean;

  public showModelClearConfirm = new BehaviorSubject<boolean>(false);
  public displayMacPopUp = new BehaviorSubject<boolean>(false);
  public isSaveButtonDisabled = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isPortfolioDetailsModelFFEnabled = this._splitIOService.featureFlags['TEXP_portfolio_details_model_7522'];
    this.portfolioSave$ = new BehaviorSubject<boolean>(false);
    this.savePortfolioDetail = false;

    this.portfolio$ = this.portfolioId$
      .pipe(
        filter(id => !!id),
        switchMap(id => forkJoin({
            portfolio: this._portfolioService.getPortfolioSummary(id),
            accounts: this._portfolioService.getPortfolioAccounts(id),
          })
        ),
        tap(({portfolio}) => {
          portfolio.teamIds = portfolio.teams.map(team => team.id);
          portfolio.primaryTeamId = portfolio.teams.find(team => team.isPrimary)?.id;
          this.modelId = portfolio.modelId;
          portfolio.initialModelId = null;

          if (portfolio.modelId) {
            portfolio.model = {
              id: portfolio.modelId,
              name: portfolio.modelName,
              displayName: portfolio.modelName,
              description: portfolio.modelDescription
            };
            portfolio.initialModelId = portfolio.modelId;
            portfolio.disableUnAssignModel = false;
            this.isModelApproved = true;
          } else {
            portfolio.model = {};
            portfolio.disableUnAssignModel = true;
          }

          portfolio.analytics = [{
            portfolioId: portfolio.id,
            editedDate: portfolio.analyticsEditedDate,
            needAnalytics: portfolio.needAnalytics,
            failedReason: portfolio.failedReason,
          }];

          const hasAnyDeviationForNonSleeved = (portfolio.categoryDeviationPercent !== undefined && portfolio.categoryDeviationPercent !== null)
            || (portfolio.classDeviationPercent !== undefined && portfolio.classDeviationPercent !== null)
            || (portfolio.subclassDeviationPercent !== undefined && portfolio.subclassDeviationPercent !== null)
            || (portfolio.securitySetDeviationPercent !== undefined && portfolio.securitySetDeviationPercent !== null)
            || (portfolio.securityDeviationPercent !== undefined && portfolio.securityDeviationPercent !== null);

          portfolio.unApprovedModel = <IPortfolioModel>{};
          const unApprovedModelId = portfolio.pendingApprovalModelId;
          if (unApprovedModelId) {
            portfolio.unApprovedModel.id = unApprovedModelId;
            portfolio.unApprovedModel.name = portfolio.pendingApprovalModelName;
            portfolio.unApprovedModel.description = portfolio.pendingApprovalModelDescription;
            portfolio.flagDispApprovalReject = true;

            const permission = Utils.getPermission(Consts.PRIV_APPROVEMODELASS);
            portfolio.isLinkDisabled = !permission.canRead;
          }

          if (!portfolio?.model?.id) {
            portfolio.model = {};
            portfolio.model.name = portfolio.unApprovedModel.name ? `${portfolio.unApprovedModel.name} (PA)` : null;
            portfolio.modelDescription = portfolio.unApprovedModel.description ?? null;
            portfolio.disableUnAssignModel = true;
          }

          const hasAnyDeviationForSleeved = (portfolio.sleeveDeviationPercent !== undefined && portfolio.sleeveDeviationPercent !== null);

          portfolio.showDeviationSection = (portfolio.isSleevePortfolio && hasAnyDeviationForSleeved)
            || (!portfolio.isSleevePortfolio && hasAnyDeviationForNonSleeved);
        }),
        tap(({portfolio, accounts}) => {
          // Create a backup of the portfolio.  Used to determine if property values have changed.
          this._portfolioOriginal.next(Object.freeze(Utils.deepClone(portfolio)));
          this._portfolio.next(portfolio);
          this._accounts.next(accounts);
          if(portfolio.modelId) {
            this.refreshModelData.next();
          }
        }),
        map(({portfolio}) => portfolio), // destructure to just return the portfolio
        shareReplay());

    this.cashDetails$ = this.portfolioId$.pipe(
      filter((id) => !!id),
      switchMap(() => this._portfolioService.getPortfolioCashDetails(this.portfolioId$.value)),
      shareReplay()
    );

    this.accounts$ = this.portfolio$
      .pipe(
        map(() => this.accounts),
        shareReplay()
      );

    this.rebalancerToolVisible$ = this.portfolio$
      .pipe(
        map((portfolio) => !!portfolio.id && this.rebalancerToolPrivilege)
      );

    this.cashNeedToolVisible$ = this.portfolio$
      .pipe(
        map((portfolio) => !!portfolio.id && this.cashNeedToolPrivilege)
      );

    this.tlhToolVisible$ = this.portfolio$
      .pipe(
        map((portfolio) => !!portfolio.id && this.tlhToolPrivilege)
      );

    this.notes$ = merge(this.portfolioId$, this.refreshNotes$)
      .pipe(
        filter(() => !!this.portfolioId$.value),
        switchMap(() => {
          return this.canReadNotes
            ? this._notesService.getNotesByEntity(this.portfolioId$.value, EntityType.Portfolio)
            : [];
        }),
        map(notes => this.filterActiveNotes(notes)),
        tap(notes => this._notes.next(notes)),
        shareReplay());

    this.portfolioNotes$ = this.notes$
      .pipe(
        map(notes => notes.filter(note => note.relatedType === EntityType.Portfolio || note.relatedType === EntityType.Account)),
        share());

    this.teamNotes$ = this.notes$
      .pipe(
        map(notes => notes.filter(note => note.relatedType === EntityType.Team)),
        share());

    this.teams$ = this.portfolio$.pipe(
      switchMap(() => this._userService.getUserSpecificTeams()),
      map((teams: ITeam[]) => {
        const currentPortfolio = this._portfolio.getValue();
        currentPortfolio.teams.forEach(pTeam => {
          const team = <ITeam>{
            id: pTeam.id,
            name: pTeam.name,
            isPrimary: pTeam.isPrimary,
            portfolioAccess: pTeam.portfolioAccess
          };
          if (!teams.some(x => x.id === team.id)) {
            teams.push(team);
          }
        });
        return teams.map<IPortfolioTeam>(team => ({
          id: team.id,
          name: team.name,
          isPrimary: currentPortfolio.primaryTeamId === team.id,
          portfolioAccess: team.portfolioAccess,
        }));
      }),
      map(teams => _sortBy(teams, ['name'])), // sort alphabetically
      tap(teams => this._teams.next(teams)),
      tap(teams => {
        this.portfolio.teams = teams.filter(team => this.portfolio.teamIds?.includes(team.id));
      }),
      shareReplay());
  }

  private filterActiveNotes(notes: INote[]): INote[] {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return notes.filter(n =>
      (n.endDate !== null && n.startDate <= now && n.endDate >= now)
      || (n.endDate === null && n.startDate <= now));
  }

  private readonly _selectedTeams = new BehaviorSubject<IPortfolioTeam[]>([]);

  public get selectedTeams(): IPortfolioTeam[] {
    return this._selectedTeams.getValue();
  }

  private readonly _teams = new BehaviorSubject<IPortfolioTeam[]>([]);

  public get teams(): IPortfolioTeam[] {
    return this._teams.getValue();
  }

  private readonly _accounts = new BehaviorSubject<IAccount[]>(undefined);

  public get accounts(): IAccount[] {
    return this._accounts.getValue();
  }

  private readonly _notes = new BehaviorSubject<INote[]>(undefined);

  public get notes(): INote[] {
    return this._notes.getValue();
  }

  private readonly _portfolioOriginal = new BehaviorSubject<PortfolioEntity>(undefined);
  /**
   * The portfolio object as initially loaded.
   */
  public get portfolioOriginal(): PortfolioEntity {
    return this._portfolioOriginal.getValue();
  }

  private readonly _portfolio = new BehaviorSubject<PortfolioEntity>(undefined);

  public get portfolio(): PortfolioEntity {
    return this._portfolio.getValue();
  }

  private readonly _gainLossSummary = new BehaviorSubject<PortfolioGainLossSummary>(undefined);

  public get gainLossSummary(): PortfolioGainLossSummary {
    return this._gainLossSummary.getValue();
  }

  public validatePortfolio(): string[] {
    const errors: string[] = [];
    const portfolio = this.portfolio;
    if (!portfolio.name?.trim().length) {
      errors.push('Portfolio must have a name.');
    }
    if (!portfolio.teamIds?.length) {
      errors.push('Portfolio must be assigned to at least one team.');
    }
    if (!portfolio.primaryTeamId) {
      errors.push('Portfolio does not have a Primary Team assigned.');
    }
    if (portfolio.primaryTeamId && portfolio.teamIds?.length && !portfolio.teamIds.includes(portfolio.primaryTeamId)) {
      errors.push('Primary Team must be in the list of selected teams.');
    }
    return errors;
  }

  public savePortfolio(): Observable<any> {
    if (this.isPortfolioDetailsModelFFEnabled) {
      if (this.modelId && this.portfolio.modelId === undefined) {
        this.savePortfolioDetail = true;
        this.showModelClearConfirm.next(true);
        return of(null);
      }

      const savePortfolio = this.validateAndSaveMacWeightage();
      if (savePortfolio) {
        return this.savePortfolioDetails();
      }
    } else if (!this.isPortfolioDetailsModelFFEnabled) {
      return this.savePortfolioDetails();
    }
    return of(null);
  }

  savePortfolioDetails(): Observable<any> {
    const validationErrors = this.validatePortfolio();
    if (validationErrors.length) {
      this._alertService.alert.emit(validationErrors.map(err => ({ message: err, typeId: 4 })));
      return throwError(() => validationErrors);
    }

    const editPortfolio: IEditPortfolio = <IEditPortfolio>{
      doNotTrade: Number(this.portfolio.doNotTrade),
      isSleevePortfolio: this.portfolio.isSleevePortfolio,
      modelId: this.portfolio.modelId,
      name: this.portfolio.name,
      primaryTeamId: this.portfolio.primaryTeamId,
      isMac: this.portfolio.isMac,
      rebalanceDay: this.portfolio.rebalanceDay,
      rebalanceTypeId: this.portfolio.rebalanceTypeId,
      rebalanceOptionId: this.portfolio.rebalanceOptionId,
      rebalanceOption: this.portfolio.rebalanceOption,
      buyEmphasisOn: this.portfolio.buyEmphasisOn,
      sellEmphasisOn: this.portfolio.sellEmphasisOn,
      tags: this.portfolio.tags,
      teamIds: this.portfolio.teamIds
    };

    if (!this.portfolio.id) {
      return this._portfolioService.createPortfolio(editPortfolio);
    } else {
      const apiArray = [this._portfolioService.updatePortfolio(this.portfolio.id, editPortfolio)];
      if (this.isPortfolioDetailsModelFFEnabled) {
        apiArray.push(this.saveModelDetails(editPortfolio));
      }
      return forkJoin(apiArray);
    }
  }

  private saveModelDetails(editPortfolio: IEditPortfolio): Observable<any> {
    this.portfolio.error = false;
    this.validateModelDetail();

    if (!this.portfolio.error) {
      const modelStructure = <IModelStructureSaveUpdate>{};
      if (this.portfolio.modelDetail?.modelDetail && this.portfolio.modelDetail.id) {
        this.portfolio.saveModel = false;
        this.createModelStructure(modelStructure);
        this.checkSaveIsRequired(modelStructure.modelDetail.children);
        this.checkModelSubModelHaveSubstitute(this.portfolio.modelSubModel);
        if (!this.portfolio.substitutedModelDetail) {
          this.portfolio.deleteSubstitutedModel = false;
        }
        editPortfolio.deleteSubstitutedModel = this.portfolio.deleteSubstitutedModel;
      }
      if (!editPortfolio.modelId) {
        editPortfolio.modelId = 0;
      }
      editPortfolio.skipReverseSynch = this.portfolio.skipReverseSynch;
      editPortfolio.isMac = this.portfolio.modelDetail ? this.portfolio.modelDetail.isMAC : false;
      return this.saveAndDeleteSubstituteModel(modelStructure);
    } else {
      this._alertService.alert.emit([{ typeId: 4, message: this.portfolio.errorMsg }]);
      return of(null);
    }
  }

  onModelChangeConfirm(): void {
    if (this.portfolio.isAssignModel === null || this.portfolio.isAssignModel === undefined) {
      return;
    }
    this.displayMacPopUp.next(false);
    if (this.portfolio.isAssignModel) {
      this.onModelSelectConfirm(this.portfolio.model);
    } else {
      this.confirmModelClear();
    }
  }

  private onModelSelectConfirm(model: IIdName): void {
    this.portfolio.disableUnAssignModel = this.portfolioOriginal?.modelId !== model.id;
    this.modelId = model.id;
    this.portfolio.modelId = model.id;
    this.portfolio.flagDispApprovalReject = false;
  }

  onModelSelect(model: IPortfolioModel): void {
    this.portfolio.modelId = model.id;
    this.portfolio.modelName = model.name;
    this.portfolio.modelDescription = model.description;
    this.portfolio.model = model;
    this.portfolio.isAssignModel = true;
    const macStatus = this.securityWeightingComponent ? this.securityWeightingComponent.macStatus : this.portfolio.macStatus;

    if (this.isModelApproved && this.portfolio.initialModelId !== this.portfolio.modelId
      && macStatus === Consts.MACWeightingStatus.Portfolio.value) {
      this.displayMacPopUp.next(true);
    } else {
      this.onModelSelectConfirm(model);
      this.showMACWarningForNewlyAssignedModel(model.isMAC);
    }
  }

  approveOrRejectModelPortfolio(actionStatus: string): void {
    const approveRejectModelPortfolios = <IApproveRejectModelPortfolios>{
      portfolioIds: [this.portfolio.id]
    };
    this._modelService.approveOrRejectModelPortfolio(this.portfolio.unApprovedModel.id, actionStatus, approveRejectModelPortfolios)
      .subscribe(() => {
        this.portfolio.displayPfMdlApprvlSec = false;
        this.portfolio.flagDispApprovalReject = false;
        if (actionStatus === 'reject') {
          this.approveModel();
        } else {
          this.rejectModel();
        }
      });
  }

  private rejectModel(): void {
    this.portfolio.model = this.portfolio.unApprovedModel;
    this.portfolio.modelId = this.portfolio.unApprovedModel.id;
    this.portfolio.modelDescription = this.portfolio.unApprovedModel.description;
    this.portfolio.disableUnAssignModel = false;
    this.isModelApproved = true;
    this.refreshModelData.next();
  }

  private approveModel(): void {
    this.portfolio.unApprovedModel = null;
    this.portfolio.modelId = this.portfolioOriginal?.modelId ?? null;
    this.portfolio.model = this.portfolioOriginal?.model ?? null;
    this.portfolio.model.name = this.portfolioOriginal?.modelName ?? null;
    this.modelId = this.portfolioOriginal?.model?.id ?? null;
    this.portfolio.modelDescription = this.portfolioOriginal?.model?.description ?? null;
    this.isModelApproved = false;
  }

  private confirmModelClear(): void {
    this._modelService.unAssignPortfolioFromModel(this.modelId, this.portfolio.id)
      .pipe(
        tap(() => {
          this.showModelClearConfirm.next(false);
          this.portfolio.disableUnAssignModel = true;
          this.portfolio.modelId = undefined;
          this.portfolio.modelName = undefined;
          this.portfolio.modelDescription = undefined;
          this.portfolio.model = undefined;
          this.modelId = undefined;
          this.showMacWeightingsPanel = false;
          this.isModelApproved = false;
        }),
        switchMap(() => this.checkAndResumeSaveInProgress()))
      .subscribe();
  }

  private checkAndResumeSaveInProgress(): Observable<PortfolioEntity> {
    if (this.savePortfolioDetail) {
      return this.savePortfolio().
        pipe(
          tap(result => {
            if (result?.id) {
              this.savePortfolioDetail = false;
              this.portfolioSave$.next(true);
            }
          })
        );
    }
    return of(null);
  }

  checkMacStatusAndUnAssignModel(): void {
    if (this.securityWeightingComponent?.macStatus === Consts.MACWeightingStatus.Yes.value
      || this.portfolio.macStatus === Consts.MACWeightingStatus.Yes.value
    ) {
      this.showModelClearConfirm.next(false);
      this.displayMacPopUp.next(true);
      this.portfolio.isAssignModel = false;
    } else {
      this.confirmModelClear();
    }
  }

  modelCleared(): void {
    this.showModelClearConfirm.next(true);
  }

  closeModelClearConfirmDialog(): void {
    this.showModelClearConfirm.next(false);
  }

  private showMACWarningForNewlyAssignedModel(isMac: boolean): void {
    const macStatus = this.securityWeightingComponent ? this.securityWeightingComponent.macStatus : this.portfolio.macStatus;
    if (this.isModelApproved && !isMac && macStatus === Consts.MACWeightingStatus.Model.value) {
      this.isSaveButtonDisabled.next(true);
      this._alertService.alert.emit([{ typeId: AlertTypeEnum.ERROR, message: Consts.MACValidationRulesMessages.modelNotMACModel }]);
    }
  }

  onModelUnSelect(model: Event): void {
    if ((model.target as HTMLInputElement).value.trim() === '') {
      this.portfolio.modelId = undefined;
      this.portfolio.modelDescription = '';
      this.portfolio.disableUnAssignModel = true;
    }
  }

  navigateToTradeTool(tradeToolName: string): void {
    switch (tradeToolName) {
      case 'CashNeeds':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/cashneed'], {onSameUrlNavigation: 'reload'});
        break;
      case 'GlobalTrades':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/globaltrades', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Liquidate':
        this._sessionHelper.set(LIQUIDATE_CONSTANTS.SESSION_SELECTED_IDS_KEY, {
          type: LIQUIDATE_CONSTANTS.TRADE_FILTER_METHOD.PORTFOLIO,
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate([LIQUIDATE_CONSTANTS.TOOL_ROUTE], {onSameUrlNavigation: 'reload'});
        break;
      case 'Options':
        this._portfolioService.getAccountsSimpleListByPortfolioIds([this.portfolio.id])
          .subscribe(result => {
            const accountIds = result.filter(a => !a.isSMA && (!a.isSleeve || a.sleeveType === 'Normal')).map(a => a.id);
            this._sessionHelper.set('selectedIds', {type: 'account', ids: accountIds.join()});
            this._router.navigate(['/eclipse/tradetool/option', 'account'], {onSameUrlNavigation: 'reload'});
          });
        break;
      case 'RaiseCash':
        this._sessionHelper.set('selectedIds', {type: 'portfolio', ids: this.portfolio.id.toString()});
        this._router.navigate(['/eclipse/tradetool/raisecash', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'SpendCash':
        this._sessionHelper.set('selectedIds', {type: 'portfolio', ids: this.portfolio.id.toString()});
        this._router.navigate(['/eclipse/tradetool/spendcash', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Rebalancer':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/rebalancer', 'portfolio'], {onSameUrlNavigation: 'reload'});
        break;
      case 'TLH':
        this._sessionHelper.set('selectedIds', {
          type: 'portfolio',
          ids: this.portfolio.id.toString(),
        });
        this._router.navigate(['/eclipse/tradetool/tlh'], {onSameUrlNavigation: 'reload'});
        break;
      case 'Tactical':
        const validationResults = this.validatePortfoliosForTacticalTool(this.portfolio);
        if (validationResults.errors.length > 0) {
          this._alertService.alert.emit(validationResults.errors);
        } else {
          this._localStorageService.set('TacticalPortfolioIds', [{
            id: validationResults.portfolioId,
            name: validationResults.portfolioName
          }]).subscribe();
          this._router.navigate(['/eclipse/tradetool/tactical'], {onSameUrlNavigation: 'reload'});
        }
        break;
      case 'TradeToTarget':
        this._portfolioService.getAccountsSimpleListByPortfolioIds([this.portfolio.id])
          .subscribe(result => {
            const accountIds = result.filter(a => !a.isSMA).map(a => a.id);
            this._sessionHelper.set('selectedIds', {type: 'account', ids: accountIds.join()});
            this._router.navigate(['/eclipse/tradetool/tradetotarget', 'account'], {onSameUrlNavigation: 'reload'});
          });
        break;
    }
  }

  private validatePortfoliosForTacticalTool(portfolio: PortfolioEntity): PortfolioTacticalValidation {
    const errors: { typeId: number; message: string }[] = [];

    if (portfolio.managedValue <= 0) {
      errors.push({typeId: 4, message: 'Portfolio with no Accounts or Managed Value is not > $0.'});
    }
    if (!portfolio.primaryTeamId) {
      errors.push({typeId: 4, message: 'Portfolio does not have \'Primary Team\' assigned.'});
    }
    if (portfolio.isDisabled) {
      errors.push({typeId: 4, message: 'Portfolio is disabled.'});
    }

    return {portfolioId: portfolio.id, portfolioName: portfolio.name, errors: errors};
  }

  private validateAndSaveMacWeightage(): boolean {
    let savePortfolio = false;
    // MAC Weightings check
    if (this.showMacWeightingsPanel && this.securityWeightingComponent) {
      if (this.portfolio.initialModelId !== this.portfolioOriginal.modelId) {
        // If model is changed for portfolio which already has mac weightings, VALIDATE portfolio MAC weightings on New MODEL
        this.validateMacWeightingsOnNewModel();
      } else {
        savePortfolio = this.handleMacWeightingsValidation(savePortfolio);
      }
    } else {
      savePortfolio = true;
    }
    return savePortfolio;
  }

  private handleMacWeightingsValidation(savePortfolio: boolean): boolean {
    const isMacChanged = this.securityWeightingComponent.isMacDetailsChanged();
    if (isMacChanged) {
      const isValid = this.securityWeightingComponent.isValidTaxablesSelected();
      if (isValid) {
        // Validate MAC Weightings first then only save account data...
        savePortfolio = this.validateAndSaveMacWeightings();
      }
    } else {
      savePortfolio = true;
    }
    return savePortfolio;
  }

  private validateAndSaveMacWeightings(): boolean {
    if (this.securityWeightingComponent.validationLevel !== ModelType.SECURITY_SET) {
      this.validateMacWeightings();
    } else {
      this.handleRankValidation();
    }
    return false;
  }

  private handleRankValidation(): void {
    // When 4th rule failed and user enters ranks then click 'SAVE' button,
    // check whether ranks entered for all securities or not
    // As rank is optional, we have to check only if 4th validation fails
    if (this.securityWeightingComponent.rankValidationMessage) {
      this.securityWeightingComponent.rankValidationMessage = undefined;
      this.securityWeightingComponent.saveMACWeightings();
    } else {
      this.securityWeightingComponent.saveMACWeightings();
    }
  }

  private validateMacWeightings(): void {
    const ids = [];
    ids.push(Number(this.portfolio.id));
    const macWeightingsValidationObject = {
      type: 'portfolio',
      ids: ids,
      validationLevel: 1,
      weightings: [],
      macStatus: this.securityWeightingComponent.macStatus // for 'SAVE'
    };

    const securityWeighting = this.securityWeightingComponent.securitiesGridMasterData;
    for (const item of securityWeighting) {
      macWeightingsValidationObject.weightings.push({
        weightingId: Number(item.weightingId),
        securityId: Number(item.securityId),
        rank: item.rank ? Number(item.rank) : null,
        taxableSecurity: Number(item.taxableSecurity) === 1,
        taxDeferredSecurity: Number(item.taxDeferredSecurity) === 1,
        taxExemptSecurity: Number(item.taxExemptSecurity) === 1,
        action: item.action,
        editId: item.editId
      });
    }
    // validate
    this.securityWeightingComponent.validateAndSaveMACWeightings(macWeightingsValidationObject, true);
  }

  /**Validate New Model with current portfolio weightings */
  private validateMacWeightingsOnNewModel(): void {
    if (this.securityWeightingComponent) {
      const validationObject: IMACWeightingsValidation = {
        ids: [],
        weightings: [],
        macStatus: this.securityWeightingComponent.macStatus
      };

      if (this.securityWeightingComponent.macStatus === Consts.MACWeightingStatus.Yes.value) {
        this.validateWeightingsOnNewModel(validationObject);

      } else if (this.securityWeightingComponent.macStatus === Consts.MACWeightingStatus.Model.value
        || this.securityWeightingComponent.macStatus === Consts.MACWeightingStatus.No.value) {
        // save mac status
        this.saveMacStatus(validationObject);
      }
    }
  }

  private saveMacStatus(validationObject: IMACWeightingsValidation): void {
    validationObject.type = Consts.MacEntityTypes.Portfolio;
    validationObject.ids.push(Number(this.portfolio.id));
    // validate & save
    this.securityWeightingComponent.validateAndSaveMACWeightings(validationObject, true);
  }

  private validateWeightingsOnNewModel(validationObject: IMACWeightingsValidation): void {
    validationObject.type = Consts.MacEntityTypes.Model;
    validationObject.ids.push(Number(this.portfolio.modelId));
    validationObject.validationLevel = 1;

    const securitiesData = this.securityWeightingComponent.securitiesGridMasterData;
    for (const security of securitiesData) {
      validationObject.weightings.push({
        weightingId: Number(security.weightingId),
        securityId: Number(security.securityId),
        rank: security.rank ? Number(security.rank) : null
      } as IMACWeighting);
    }
    // validate only
    this.securityWeightingComponent.validateAndSaveMACWeightings(validationObject, false);
  }

  private saveAndDeleteSubstituteModel(modelStructure: IModelStructureSaveUpdate): Observable<any> {
    if (!this.portfolio.deleteSubstitutedModel) {
      if (this.portfolio.modelDetail?.modelDetail && this.portfolio.modelDetail.id
        && this.portfolio.saveModel && !this.portfolio.showModelSpinner) {
        modelStructure.isSaveFromPortfolio = true;
        return this._modelService.savemodeldetails(this.portfolio.modelDetail.id, modelStructure);
      }
    } else if (modelStructure?.substitutedModelId) {
      const details = {
        id: Number(this.portfolio.id),
        type: 'portfolio',
        substitutedModelId: Number(modelStructure.substitutedModelId)
      };
      return this._modelService.deleteSubstitutedModel(details);
    }
    return of(null)
  }

  private checkModelSubModelHaveSubstitute(subModel: ISubModelDetails[]): void {
    subModel.every(model => {
      if (model.haveSubsitute) {
        this.portfolio.deleteSubstitutedModel = false;
        return false;
      }
      return true;
    });
  }

  private checkSaveIsRequired(children: IModelDetailChildernSave[]): void {
    if (!children) {
      return;
    }

    for (const element of children) {
      if (element.children.length > 0) {
        this.checkSaveIsRequired(element.children);
      } else if (element.modelTypeId === ModelType.SECURITY_SET) {
        if (element.isNewlySubstitutedNode) {
          this.portfolio.saveModel = true;
        }
        if (element.isSubstituted) {
          this.portfolio.deleteSubstitutedModel = false;
        }
      }
    }
  }

  private createModelStructure(modelStructure: IModelStructureSaveUpdate): void {
    this.portfolio.modelDetail.modelDetail.id = null;
    modelStructure.substitutedModelId = this.portfolio.substitutedModelId ?? 0;
    modelStructure.substitutedFor = Number(this.portfolio.id);
    if (this.portfolio.substitutedModelDetail?.modelDetail) {
      modelStructure.modelDetail = this.prepareFinalModelDetail(this.portfolio.substitutedModelDetail.modelDetail);
    } else {
      modelStructure.modelDetail = this.prepareFinalModelDetail(this.portfolio.modelDetail.modelDetail);
    }

    this.portfolio.deleteSubstitutedModel = !this.hasSubstitutedModel(modelStructure.modelDetail.children);
    modelStructure.isSleeved = false;
    modelStructure.forcefulModelSave = false;
    modelStructure.targetPercentUpdated = true;
  }

  private hasSubstitutedModel(children: IModelDetailChildernSave[]): boolean {
    if (children.length) {
      for (const child of children) {
        if (child.isSubstituted) {
          return true;
        }
        if (child.children) {
          return this.hasSubstitutedModel(child.children);
        }
      }
    }
  }

  private prepareFinalModelDetail(modelDetail: IModelDetails): IModelDetailSave {
    if (modelDetail) {
      const model = {
        name: modelDetail.name,
        modelDetailId: modelDetail.modelDetailId,
        targetPercent: null,
        lowerModelTolerancePercent: null,
        upperModelTolerancePercent: null,
        toleranceTypeValue: null,
        lowerModelToleranceAmount: null,
        upperModelToleranceAmount: null,
        isNewlySubstitutedNode: false,
        rank: null,
        isEdited: null,
        isSubstituted: null,
        substitutedOf: null,
        isEditSubstituted: false,
        children: [],
        addedElements: [],
        modifiedElements: [],
        removedElements: [],
        draggedNodeElements: null
      };
      model.children = this.prepareFinalModelData(modelDetail.children);
      return model;
    } else {
      return null;
    }
  }

  private prepareFinalModelData(children: IModelDetails[]): IModelDetailChildernSave[] {
    return children.map(element => this.prepareChildModelData(element));
  }

  private prepareChildModelData(element: IModelDetails): IModelDetailChildernSave {
    const child: IModelDetailChildernSave = {
      id: element.modelTypeId === ModelType.SECURITY_SET ? null : element.id,
      name: element.name,
      isNewlySubstitutedNode: element.isNewlySubstitutedNode ?? false,
      modelTypeId: element.modelTypeId,
      modelDetailId: element.modelDetailId ?? null,
      targetPercent: element.targetPercent,
      lowerModelTolerancePercent: element.lowerModelTolerancePercent,
      upperModelTolerancePercent: element.upperModelTolerancePercent,
      toleranceTypeValue: element.toleranceTypeValue ?? null,
      lowerModelToleranceAmount: element.lowerModelToleranceAmount,
      upperModelToleranceAmount: element.upperModelToleranceAmount,
      rank: element.rank ?? null,
      securityAsset: element.securityAsset,
      isEdited: null,
      isSubstituted: element.isSubstituted ?? false,
      substitutedOf: element.substitutedOf,
      isEditSubstituted: element.isEditSubstituted ?? false,
      children: [],
      addedElements: [],
      modifiedElements: [],
      removedElements: []
    };

    if (element.children.length > 0) {
      child.children = this.prepareFinalModelData(element.children);
    }

    return child;
  }

  private validateModelDetail(): void {
    if (this.portfolio.modelSubModel) {
      for (const model of this.portfolio.modelSubModel) {
        for (const set of model.securitySets) {
          if (set.error?.length > 0) {
            this.portfolio.error = true;
          }
        }
      }
    }
  }
}

