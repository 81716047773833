import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseComponent } from 'src/app/core/base.component';
import moment from 'moment-timezone';
import { IExcludeFilter, IExcludeFilterSave } from 'src/app/models/excludefilter';
import { ExcludeFilterService } from 'src/app/services/excludefilter.service';
import { CustomSubService } from 'src/app/core';

@Component({
  selector: 'eclipse-exclude-filter',
  templateUrl: './exclude-filter.component.html',
  styleUrls: ['./exclude-filter.component.scss']
})
export class ExcludeFilterComponent extends BaseComponent {

  @Output() resetExcludeFilter = new EventEmitter();
  @Input() portfolioIds: number[];
  filters: IExcludeFilter[];
  DEFAULT_FILTERS: IExcludeFilter[] = [{
    field: 'outOfTolerance',
    name: 'Out Of Tolerance',
    value: false,
    options: [{
      field: 'untilManuallyReadded',
      name: 'Until Manual Re-added'
    },
    {
      field: 'untilDate',
      name: 'Until Date'
    }],
    selectedValue: null,
    showDateField: false,
    selectedOption: 'untilManuallyReadded'
  },
  {
    field: 'cashNeeds',
    name: 'Cash Needs',
    value: false,
    options: [{
      field: 'untilManuallyReadded',
      name: 'Until Manual Re-added'
    },
    {
      field: 'untilDate',
      name: 'Until Date'
    }],
    selectedValue: null,
    showDateField: false,
    selectedOption: 'untilManuallyReadded'
  },
  {
    field: 'taxLossHarvest',
    name: 'Tax Loss Harvesting',
    value: false,
    options: [{
      field: 'untilManuallyReadded',
      name: 'Until Manual Re-added'
    },
    {
      field: 'untilDate',
      name: 'Until Date'
    }],
    selectedValue: null,
    showDateField: false,
    selectedOption: 'untilManuallyReadded'
  }];
  en: any;
  errMessage: string;
  showExcludeFilter: boolean = false;
  todayDate: Date;

  constructor(private _excludeFilterService: ExcludeFilterService,  private _subService: CustomSubService) {
    super();
   }

  ngOnInit() {
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
      monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
    };
    this.getExcludeFilter();
    this.todayDate = new Date();
  }

  closeExcludeFilterPopUp(refresh: boolean = false) {
    this.showExcludeFilter = false;
    this.filters = null;
    this.resetExcludeFilter.emit(refresh);
  }

  saveExcludeFilter() {
    const filterData: IExcludeFilterSave = {};
    const isValid = this.validateAndManipulateFilterData(filterData);
    if (!isValid) {
      return;
    } else {
      this._excludeFilterService.saveOrUpdateExcludeFilter(filterData, this.portfolioIds)
      .subscribe((result: any) => {
        this.closeExcludeFilterPopUp(true);
      }, err => {
        this.closeExcludeFilterPopUp();
        this._subService.forkJoinError.next(err);
      });
    }

  }

  onOptionChange(filter: IExcludeFilter, selectedOption: string) {
    filter.selectedOption = selectedOption;
    filter.selectedValue = null;
    this.errMessage = null;
    // eslint-disable-next-line eqeqeq
    if (filter.selectedOption == 'untilDate') {
      filter.showDateField = true;
    } else {
      filter.showDateField = false;
    }
  }

  validateAndManipulateFilterData(filterData: IExcludeFilterSave): boolean {
    let isValidData = true;
    this.filters.forEach(filter => {
      // eslint-disable-next-line eqeqeq
      if (filter.selectedOption == 'untilDate' && !filter.selectedValue) {
        this.errMessage = 'There is no Until Date for ' + filter.name;
        isValidData = false;
      }
      filterData[filter.field] = {};
      filterData[filter.field].exclude = filter.value;
      if (filter.selectedValue) {
        filterData[filter.field].excludeDate = moment(filter.selectedValue).format('YYYY-MM-DD');
      } else {
        filterData[filter.field].excludeDate = null;
      }
    });
    return isValidData;
  }

  getExcludeFilter() {
    this.filters = this.DEFAULT_FILTERS;
    // eslint-disable-next-line eqeqeq
    if (this.portfolioIds && this.portfolioIds.length == 1) {
      this._excludeFilterService.getExcludeFilter(this.portfolioIds[0])
      .subscribe((result: any) => {
        if (result) {
          this.createFilterStructure(result);
        }
        this.showExcludeFilter = true;
      }, err => {
        this._subService.forkJoinError.next(err);
      });
    } else {
      this.showExcludeFilter = true;
    }
  }

  createFilterStructure(excludeFilters) {
    this.filters.forEach(filter => {
      filter.value = excludeFilters[filter.field];
      if (filter.value && excludeFilters[filter.field + 'Date']) {
        filter.selectedValue = excludeFilters[filter.field + 'Date'];
      }
      if (filter.selectedValue) {
        filter.showDateField = true;
        filter.selectedOption = 'untilDate';
      }
    });
  }

  setDefaultOption(filter: IExcludeFilter) {
    filter.selectedOption = 'untilManuallyReadded';
    filter.selectedValue = null;
    filter.showDateField = false;
  }

}
