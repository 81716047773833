<p-table #dt [value]="filteredTradeBlockDetails" responsiveLayout="scroll" [(selection)]="selectedTradeBlockDetail" dataKey="id"
         styleClass="p-datatable-sm" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
         [filterDelay]="0" [loading]="loading"
         [globalFilterFields]="['description', 'level', 'tradeBlockReasonName']">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
        <span class="p-input-icon-left">
        <i class="far fa-search"></i>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
               placeholder="Search Trade Block Details"/>
      </span>
        <span class="p-input-icon-right flex align-items-center">
            <p-radioButton label="Active" name="tradeBlockDetailsActiveFilter" class="m-r-5" value="Active"
                           [(ngModel)]="tradeBlockDetailsActiveFilter"
                           (onClick)="changeActiveFilter()"></p-radioButton>
            <p-radioButton label="All" name="tradeBlockDetailsActiveFilter" class="m-r-5" value="All"
                           [(ngModel)]="tradeBlockDetailsActiveFilter"
                           (onClick)="changeActiveFilter()" class="m-r-10"></p-radioButton>
            <button pButton label="Add New" icon="fas fa-plus" (click)="addNewTradeBlockDetail()"></button>
        </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem;">ID
      </th>
      <th pSortableColumn="level" style="width: 12rem;">Level
        <p-sortIcon field="level"></p-sortIcon>
      </th>
      <th pSortableColumn="tradeBlockReasonName" style="width: 12rem;">Type
        <p-sortIcon field="type"></p-sortIcon>
      </th>
      <th pSortableColumn="description" style="min-width: 15rem;">Description
        <p-sortIcon field="description"></p-sortIcon>
      </th>
      <th pSortableColumn="startDate" style="width: 9rem;">Start Date
        <p-sortIcon field="startDate"></p-sortIcon>
      </th>
      <th pSortableColumn="endDate" style="width: 9rem;">End Date
        <p-sortIcon field="endDate"></p-sortIcon>
      </th>
      <th pSortableColumn="editedDate" style="width: 9rem;">Edited By
        <p-sortIcon field="editedDate"></p-sortIcon>
      </th>
      <th style="width: 6rem;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tradeBlockDetail>
    <tr class="p-selectable-row">
      <td>
        <span class="p-column-title">ID</span>
        {{tradeBlockDetail.id}}
      </td>
      <td>
        <span class="p-column-title">Level</span>
        {{tradeBlockDetail.level}}
      </td>
      <td>
        <span class="p-column-title">Type</span>
        {{tradeBlockDetail.tradeBlockReasonName}}
      </td>
      <td>
        <span class="p-column-title">Description</span>
        <span class="trade-block-details-text">{{tradeBlockDetail.description}}</span>
      </td>
      <td>
        <span class="p-column-title">Start Date</span>
        <span>{{tradeBlockDetail.startDate | date:'M/d/yyyy'}}</span>
      </td>
      <td>
        <span class="p-column-title">End Date</span>
        <span>{{tradeBlockDetail.endDate | date:'M/d/yyyy'}}</span>
      </td>
      <td>
        <span class="p-column-title">Edited</span>
        <span>{{tradeBlockDetail.editedDate | date:'M/d/yyyy'}}</span><br/>
        <span>{{tradeBlockDetail.editedByUser}}</span>
      </td>
      <td>
        <div class="text-right">
          <button pButton icon="fas fa-fw fa-pencil" class=" p-button-sm mr-2"
                  (click)="editTradeBlockDetail(tradeBlockDetail)" *ngIf="!tradeBlockDetail.isDeleted"></button>
          <button *ngIf="!tradeBlockDetail.isDeleted" pButton icon="fas fa-fw fa-trash" class="p-button-danger p-button-sm" (click)="deleteTradeBlockDetail(tradeBlockDetail)"></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9">No trade block details found.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
    <tr>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
      <td><p-skeleton></p-skeleton></td>
    </tr>
  </ng-template>
</p-table>

<p-dialog [(visible)]="tradeBlockDetailsDialog" header="Trade Block Details" [modal]="true" class="ui-dialog-md" appendTo="body">
  <eclipse-trade-block-details-editor #tradeBlockDetailsEditor [entityId]="entityId" [entityType]="entityType" [relatedEntities]="relatedEntities" [tradeBlockReasons]="tradeBlockReasons"
                       (onTradeBlockDetailChanged)="onTradeBlockDetailChanged($event)"></eclipse-trade-block-details-editor>
  <ng-template pTemplate="footer">
    <button pButton label="Cancel" icon="fas fa-times" class="p-button-secondary" (click)="hideDialog()"></button>
    <button pButton label="Save" icon="fas fa-check" [disabled]="tradeBlockDetailsEditor?.isSaving || !tradeBlockDetailsEditor?.tradeBlockDetail?.canEdit" (click)="tradeBlockDetailsEditor.saveTradeBlockDetail()"></button>
  </ng-template>
</p-dialog>
