import { ValueSetterParams } from '@ag-grid-community/core';
import { TacticalViewType } from '../components/tradetool/tactical';
import { IAccountSMA, IModelTypes } from './account';
import { SubModelName } from '../libs/submodel.enums';

export enum TradeAction {
  Buy = 'BUY',
  Sell = 'SELL',
  JournalIn = 'JOURNAL IN',
  JournalOut = 'JOURNAL OUT'
}

export enum ModelType {
  Category = 0,
  Class = 1,
  SubClass = 2,
  SecuritySet = 3,
  Security = 4,
  Sleeve = 5,
  SleeveStrategy = 6
}

export enum TradeEditType {
  Amount = 'Amount',
  Shares = 'Shares',
  Percent = 'Percent'
}

export interface IPortfolioSimple {
  id: number;
  name: string;
}

export enum SecurityRestrictionType {
  Excluded = 'EXCLUDED',
  DoNotBuy = 'DO_NOT_BUY',
  DoNotSell = 'DO_NOT_SELL'
}

export enum EquivalenceDistributionMethod {
  ProRata = 'Pro-Rata',
  MostOutOfBalance = 'Most Underweight'
}

export enum OrderLocation {
  Open = 'Open',
  Pending = 'Pending',
  Block = 'Block',
  Allocation = 'Allocation',
  Awaiting = 'Awaiting Acceptance',
  FixedIncome = 'Fixed Income'
}

export enum DisplayFilter {
  Holdings,
  Lots
}

export enum ExcludedSecurityFilter {
  ShowExcludedSecurities,
  HideExcludedSecurities
}

export enum HoldingFilter {
  All,
  WithTrades
}

export enum HoldingWithoutValueFilter {
  HideSecurities,
  ShowSecurities
}

export enum SecurityType {
  Equity = 'Equity',
  MutualFund = 'Mutual Fund',
  Bond = 'Bond',
  Option = 'Option',
  Cash = 'Cash'
}

export enum SetAsideCashAmountType {
  Dollar = 'Dollar',
  Percent = 'Percentage'
}

export enum SetAsidePercentCalculationType {
  ManagedValue,
  TotalValue,
  ExcludedValue
}

export enum PercentOf {
  Account = 'Account',
  Portfolio = 'Portfolio'
}

export enum ReserveCashIndicator {
  Dollar = '$',
  Percent = '%'
}

export enum CashPreferenceNames {
  RebalanceCashPercentOf = 'rebalanceCashPercentOf',
  ReserveCashBillingAccount = 'reserveCashBillingAccount',
  ReserveCashBillingPercentOf = 'reserveCashBillingPercentOf',
  ReserveCashReplenish = 'reserveCashReplenish',
  ReserveCashTaxable = 'reserveCashTaxable',
  ReserveCashTaxDeferred = 'reserveCashTaxDeferred',
  ReserveCashTaxExempt = 'reserveCashTaxExempt',
  ReserveUsage = 'reserveUsage',
  TaxableRebalanceCashMin = 'taxableRebalanceCashMin',
  TaxExemptRebalanceCashMin = 'taxExemptRebalanceCashMin',
  TaxDeferredRebalanceCashMin = 'taxDeferredRebalanceCashMin'
}

export interface IPortfolioSummary {
  id: number;
  name: string;
  managedValue: number;
  contributionAmount: number;
  excludedValue: number;
  outOfTolerance: boolean;
  lastRebalancedOn?: Date;
  lastTradedDate?: Date;
  carryForwardLoss: number;
  maxGainAmount: number;
  maxShortTermGain: number;
  modelName: string;
  modelId: number;
  needsAnalytics: number;
  failedReason: string;
  rebalanceLevel: any;
  sleevePortfolioRebalanceLevel: string;
  tags: string;
  team: string;
  teamId: number;
  secondaryTeams: {
    portfolioId: number;
    teamId: number;
    teamName: string;
    isPrimaryTeam: boolean;
    portfolioAccess: number;
  }[];
  tradeApprovalLevels: string;
  tradingInstructions: string;
  notesCount: number;
  displayNotes: string;
  teamDisplayNotes: string;
  yearToDateGain?: number;
  pendingTrades: number;
  isSleevePortfolio: boolean;
  allowWashSales: boolean;
  equivalentDistributionMethod: EquivalenceDistributionMethod;
  longTermCapitalGainTax: number;
  ordinaryIncomeTax: number;
  doNotTrade: boolean;
  autoRebalance: boolean;
  nextAutoRebalanceDate?: Date;
  rebalanceDay?: number;
  rebalanceOptionId?: number;
  rebalanceOption?: string;
  rebalanceTypeId?: number;
  rebalanceType?: string;
  reviewLevel: string;
  includeLevelsAboveReview: boolean;
  sleeveStrategyAggregateName: string;
  accounts: IPortfolioSummaryAccount[];
  hasContribution: boolean;
  hasDistribution: boolean;
  // Below added by UI
  instanceNotes: string;
  latestPriceDateTime?: Date;
  awaitingTrade: number;
  fixedIncomeTrade: number;
  blockTrade: number;
  allocationTrade: number;
  needAnalytics?: number;
  orionConnectFirmId: number;
  registrationId: number;
  hasModelNodeSubstitution: boolean;
  analyticsEditedDate: Date;
}

export interface IPortfolioSummaryAccount {
  custodialAccountNumber?: string;
  modelId: number;
  modelName: string;
  sleeveStrategyName: string;
  accountId: number;
  modelManagementStyle: string;
  orionConnectFirmId: number;
}

export interface IModelAnalyzer {
  accountId: number;
  accountTaxType: string;
  /**
   * Current $ column in Tactical UI -
   * Model Tolerance Grid - Non-sleeved portfolios,
   * Security Grid - Sleeved portfolios - verify this
   * */
  aggregatedCurrentAmount: number;
  /** Current % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios. */
  aggregatedCurrentPercentage: number;
  /**
   * Post $ column in Tactical UI -
   * Model Tolerance Grid - Non-sleeved portfolios,
   * Security Grid - Sleeved portfolios - verify this
   * */
  aggregatedPostAmount: number;
  aggregatedPostPercentage: number;
  custodian: string;
  categoryName: string;
  className: string;
  categoryEffectiveLowerAmount: number;
  categoryEffectiveLowerTolerance: number;
  categoryEffectiveUpperAmount: number;
  /** Category Target $ in Tactial UI */
  categoryEffectiveTargetAmount: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios - Category level. */
  categoryEffectiveTargetPercentage: number;
  categoryEffectiveUpperTolerance: number;
  classEffectiveLowerAmount: number;
  classEffectiveLowerTolerance: number;
  /** Class Target $ in Tactical UI */
  classEffectiveTargetAmount: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios - Class level. */
  classEffectiveTargetPercentage: number;
  classEffectiveUpperAmount: number;
  classEffectiveUpperTolerance: number;
  equivalentLevel?: string;
  subClassEffectiveLowerAmount: number;
  subClassEffectiveLowerTolerance: number;
  /** Sub-Class Target $ in Tactical UI */
  subClassEffectiveTargetAmount: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios - Sub-Class level. */
  subClassEffectiveTargetPercentage: number;
  subClassEffectiveUpperAmount: number;
  subClassEffectiveUpperTolerance: number;
  securitySetEffectiveLowerAmount: number;
  securitySetEffectiveLowerTolerance: number;
  securitySetEffectiveUpperAmount: number;
  securitySetEffectiveUpperTolerance: number;
  /** Security Set Target $ in Tactical UI */
  securitySetEffectiveTargetAmount: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios - Security-Set level. */
  securitySetEffectiveTargetPercentage: number;
  securityEffectiveLowerAmount: number;
  securityEffectiveLowerTolerance: number;
  /** Security Target $ in Tactical UI */
  securityEffectiveTargetAmount: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios - Security level. */
  securityEffectiveTargetPercentage: number;
  securityEffectiveUpperAmount: number;
  securityEffectiveUpperTolerance: number;
  isMACSecurity: boolean;
  isSmaNode: boolean;
  isNewNode?: boolean;
  macWeightPercentage: number;
  modelName: string;
  parentModelDetailId: number;
  portfolioId: number;
  securityId: number;
  securityName: string;
  cusip: string;
  securitySetName: string;
  securitySetId: number;
  securityType: string;
  subClassName: string;
  symbol: string;
  targetSecurityId: number;
  targetSecurityName: string;
  targetSecuritySymbol: string;
  // Below added by UI
  sellAmount: number;
  buyAmount: number;
  /** Trade $ column in Tactical UI - Model Tolerance Grid - Non-sleeved portfolios. */
  tradeAmount: number;
  tradeCount: number;
  tolerance: number;
  preTradeNeededAmount: number;
  preTradeNeededPercent: number;
  preTradeNeededRelativePercent: number;
  postTradeNeededAmount: number;
  postTradeNeededPercent: number;
  postTradeNeededRelativePercent: number;
  /**
   * aggregate names - calculated - provides a unique name for each model level
   * ex.  Category=A, Class=1, SubClass=a, SecuritySet=9, Security=MSFT
   * aggCategoryName=A, aggClassName=A1, ..., aggSecurityName=A1a9MSFT
   */
  aggCategoryName: string;
  aggClassName: string;
  aggSubClassName: string;
  aggSecuritySetName: string;
  aggSecurityName: string;
  aggSleeveName: string;
  aggSleeveStrategyName: string;
  isCash: boolean;
  /** calculated by UI - need = Target $ - Current $ */
  need: number;
  modelJournalAmount: number;

  //Below are the new fields for Portfolio Tolerance
  sleeveStrategyName: string;
  suffix: string;
  orionAccountID: string;
  orionAccountNumber: string;
  orionAccountName: string;
  sleeveModelName: string;
  sleeveModelID: number;
  excludedSleeve: boolean;
  doNotTradeAccount: boolean;
  /** Current $ column in Tactical UI - Model Tolerance Grid - Sleeve AND Sleeve Strategy tab. */
  sleeveCurrentValue: number;
  /** Current % column in Tactical UI - Model Tolerance Grid - Sleeve tab. */
  sleeveCurrentPercentage: number;
  /** Target $ column in Tactical UI - Model Tolerance Grid - Sleeve tab. */
  sleeveTargetValue: number;
  /** Target % column in Tactical UI - Model Tolerance Grid - Sleeve tab. */
  sleeveTargetPercentage: number;
  /** Post $ column in Tactical UI - Model Tolerance Grid - Sleeve tab. */
  postAmount: number;
  postPercentage: number;
  currentTradeNeedAmount: number;
  currentTradeNeedPercentage: number;
  postTradeNeedAmount: number;
  postTradeNeedAmountPercentage: number;
  sleeveUpperToleranceAmount: number;
  sleeveUpperTolerancePercentage: number;
  sleeveLowerToleranceAmount: number;
  sleeveLowerTolerancePercentage: number;
  sleeveContributionPercentage: number;
  sleeveDistributionPercentage: number;
  journalAmount: number;
  journalPercentage: number;
  /** Trade $ column in Tactical UI - Model Tolerance Grid - Sleeve tab. */
  sleeveTradeAmount: number;
  sleeveTradePercentage: number;
  sleeveBuyAmount: number;
  sleeveBuyPercentage: number;
  sleeveSellAmount: number;
  sleeveSellPercentage: number;
  excludedValue: number;
  portfolioManagedValue: number;
  sleeveDivisor: number;
  /** Current $ column in Tactical UI - Model Tolerance Grid - Sleeve Strategy tab. */
  sleeveStrategyCurrentValue: number;
  sleeveStrategyCurrentPercentage: number;
  /** Target $ column in Tactical UI - Model Tolerance Grid - Sleeve Strategy tab. */
  sleeveStrategyTargetValue: number;
  /** Target % in Tactical UI - Model Tolerance Grid - Sleeve Strategy tab. */
  sleeveStrategyTargetPercentage: number;
  /** Post $ column in Tactical UI - Model Tolerance Grid - Sleeve Strategy tab. */
  sleeveStrategyPostAmount: number;
  sleeveStrategyPostPercentage: number;
  sleeveStrategyCurrentTradeNeedAmount: number;
  sleeveStrategyCurrentTradeNeedPercentage: number;
  sleeveStrategyPostTradeNeedAmount: number;
  sleeveStrategyPostTradeNeedAmountPercentage: number;
  sleeveStrategyUpperToleranceAmount: number;
  sleeveStrategyUpperTolerancePercentage: number;
  sleeveStrategyLowerToleranceAmount: number;
  sleeveStrategyLowerTolerancePercentage: number;
  sleeveStrategyContributionPercentage: number;
  sleeveStrategyDistributionPercentage: number;
  sleeveStrategyJournalAmount: number;
  sleeveStrategyJournalPercentage: number;
  /** Trade $ column in Tactical UI - Model Tolerance Grid - Sleeve Strategy tab. */
  sleeveStrategyTradeAmount: number;
  sleeveStrategyTradePercentage: number;
  sleeveStrategyBuyAmount: number;
  sleeveStrategyBuyPercentage: number;
  sleeveStrategySellAmount: number;
  sleeveStrategySellPercentage: number;
  sleeveStrategyExcludedValue: number;
  sleeveStrategyAggregateUpperTolerance: number;
  sleeveStrategyAggregateLowerTolerance: number;
  sleeveUpperTolerancePercentageAbsolute: number;
  sleeveLowerTolerancePercentageAbsolute: number;
  productCategoryName: string;
  productClassName: string;
  productSubClassName: string;
  securitySetModelIsSubstituted: boolean;
  subClassModelIsSubstituted: boolean;
  classModelIsSubstituted: boolean;
  categoryModelIsSubstituted: boolean;
  pendingTradeAmount: number;
  pendingBuyAmount: number;
  pendingSellAmount: number;
}

export interface IAccountAndCashDetail {
  accountId: string;
  accountName: string;
  accountNumber: string;
  accountTypeName: string;
  billingAccount: number;
  billPayMethod: string;
  cashNeedAmount: number;
  cashNeedPercent: number;
  contributionAmount: number;
  createdBy: string;
  createdDate: Date;
  currentCash: number;
  currentCashPercent: number;
  custodian: string;
  custodianRestrictions: string;
  disabledReason: string;
  distributionAmount: number;
  eclipseCreatedDate: Date;
  editedBy: string;
  editedDate: Date;
  excludedValue: number;
  excludeRebalanceSleeve: boolean;
  hasTaxLossHarvest?: boolean;
  id: number;
  isDisabled: boolean;
  isDiscretionary: string;
  isDoNotBuySell: string;
  hasContribution: boolean;
  hasDistribution: boolean;
  /** Sum of non-excluded taxlots post amounts minus net of
   * trades (do not confuse with grid "Managed Value" which
   * is managedAndExcludedValue...don't ask me why) */
  managedValue: number;
  mergeIn: number;
  mergeOut: number;
  model: string;
  pendingCashPercent: number;
  pendingCashValue: number;
  pendingTrades: number;
  pendingValue: number;
  pendingValuePercent: number;
  restrictedPlanName: string;
  /** "Reserve %" in UI */
  reserveCashPercent: number;
  /** "Billing $" in UI */
  billingAccountActualCash: number;
  /** "Billing %" in UI */
  billingAccountActualPercent: number;
  /** "Billing Target $" in UI */
  billingAccountTargetCash: number;
  /** "Billing Target %" in UI */
  billingAccountTargetPercent: number;
  /** "Set Aside %" in UI */
  setAsideCashPercent: number;
  /** "Set Aside Min %" in UI */
  setAsideCashMinPercent: number;
  /** "M&R Target $" in UI */
  modelPlusRebalanceCash: number;
  /** "M&R Target %" in UI */
  modelPlusRebalancePercent: number;
  minCashDollar: number;
  maxCashDollar: number;
  minCashToleranceAmount: number;
  maxCashToleranceAmount: number;
  portfolio: string;
  portfolioId: number;
  /** "Reserve $" in UI */
  reserveCashActual: number;
  reserveCashTarget: number;
  reserveUsageValue: number;
  restrictedPlanId: string;
  /** "Set Aside $" in UI */
  setAsideCashActual: number;
  /** "Set Aside Target $" in UI */
  setAsideCashTarget: number;
  /** "Set Aside Min $" in UI */
  setAsideCashMin: number;
  sleeveType: string;
  sma: string;
  status: string;
  statusReason: string;
  style: string;
  systematicAmount: number;
  systematicDate: Date;
  /** "Cash Target $" in UI */
  targetCash: number;
  /** "Cash Target %" in UI */
  targetCashPercent: number;
  taxTypeCode: string;
  value: number;
  modelName?: string;
  modelId?: number;
  sleeveTarget: number;
  sleeveToleranceLower: number;
  sleeveToleranceUpper: number;
  reviewLevel: string;
  rebalanceLevel: string;
  postCashValue: number;
  // Below added by UI
  tradeAmount: number;
  buyAmount: number;
  sellAmount: number;
  tradeCount: number;
  preTradeCashAmount: number;
  preTradeCashPercent: number;
  /** Account & Cash Details Post $ in UI */
  postTradeCashAmount: number;
  postTradeCashPercent: number;
  awaitingTrade: number;
  fixedIncomeTrade: number;
  blockTrade: number;
  allocationTrade: number;
  /** "M&R Target $" in UI */
  modelPlusRebalanceTargetCash: number;
  /** "M&R Target %" in UI */
  modelPlusRebalanceTargetPercent: number;
  /** "Managed Value" in UI */
  managedAndExcludedValue: number;
  excludedAndTradeValue: string;
  originalManagedValue: number;
  cashJournalAmount: number;
  securityJournalAmount: number;
  rebalJournalAmount: number;
  /** "Excluded Value" in UI */
  currentExcludedValue?: number;
  accountTags: string;
  /** "Set Aside Target %" in UI */
  setAsidePercentTarget: number;
  brokerDealerId?: number;
  riaId?: number;
  hasSMAWeighting?: boolean;
  accountSmaAllocations?: IAccountSmaAllocations[];
  originalModelPlusRebalanceCashPercent: number;
  originalManagedAndExcludedValue?: number;
  originalMinCashToleranceAmount?: number;
  originalMaxCashToleranceAmount?: number;
  originalModelPlusRebalanceCashPercentForPortfolio?: number;
}

export interface IAccountAndCashDetailDict {
  [id: string]: IAccountAndCashDetail;
}

export interface ITacticalTaxLotModelNode {
  categoryName: string;
  className: string;
  subClassName: string;
  securitySetName: string;
  securityName: string;
  symbol: string;
  /** Security Target $ in Tactical UI - Holding & Trade Details */
  targetAmount: number;
  targetPercent: number;
  isMacSecurity: boolean;
  securityId: number;
  securitySetId: number;
  targetSecurityId: number;
  accountId: number;
  equivalentLevel: string;
  uniqueId: string;
  categoryEquivalentAllocationAmt?: number;
  classEquivalentAllocationAmt?: number;
  subClassEquivalentAllocationAmt?: number;
  securitySetEquivalentAllocationAmt?: number;
  securityEquivalentAllocationAmt?: number;
  equivalentAccountId?: number;
}

export interface ITacticalTaxLotBase {
  portfolioId: number;
  accountNumber: string;
  accountId: number;
  taxableType: string;
  accountName: string;
  custodian: string;
  billingAccount?: boolean;
  restrictedPlanName: string;
  isDoNotBuySell: boolean;
  isExcludedSecurity: boolean;
  isSecurityDoNotBuySell: boolean;
  isSMA: boolean;
  securityId: number;
  /** Ticker */
  symbol: string;
  cusip?: string;
  /** Number of shares currently held in the taxlot */
  quantity: number;
  pendingQuantity: number;
  pendingMarketValue: number;
  /** costPerShare * quantity */
  costAmount: number;
  /** Same as taxLotPrice */
  costPerShare: number;
  /** Price of the security at the time of purchase */
  taxLotPrice: number;
  /** Current market value of the tax lot (price*shares) */
  marketValue: number;
  /** Current price of the security */
  securityPrice: number;
  /** Date used for the securityPrice */
  priceDate: Date;
  /** Equity, Mutual Fund */
  securityType: string;
  account: IAccountAndCashDetail;
  /** % of the total account's value made up by this tax lot  (marketValue/account value) */
  currentPercent: number;
  tradeAmount: number;
  /** $ amount that will be held after trades */
  postAmount: number;
  /** % of the total account's value that will be made up by this security after trades  (postAmount/account value) */
  postPercent: number;
  /** Number of shares held after trades */
  postShares: number;
  modelNodes: ITacticalTaxLotModelNode[];
  /** Name of the security being targeted, in the case of an equivalent security */
  targetSecurityName: string;
  targetSecurityId: number;
  targetSecuritySymbol: string;
  /** Name of the security held */
  securityName: string;
  unrealizedGainLoss: number;
  /** UI column Unrealized G/L$  - (Holding) Tactical Trade Holding and Trade Details */
  holdingUnrealizedGainLossPercent: number;
  stHoldingUnrealizedGainLossPercent: number;
  ltHoldingUnrealizedGainLossPercent: number;
  isCash: boolean;
  complianceApprovalStatus?: string;
  complianceMessage?: string;
  productCategoryName: string;
  productClassName: string;
  productSubClassName: string;
  equivalentLevel?: string;
  isTradableMoneyMarket?: boolean;
}

export interface ITacticalTaxLot extends ITacticalTaxLotBase {
  taxLotId: number;
  dateAcquired: Date;
  isLongTerm: boolean;
  rank: number;
  stUnrealizedGainLoss: number;
  ltUnrealizedGainLoss: number;
  stLotUnrealizedGainLossPercent: number;
  lotUnrealizedGainLossPercent: number;
  ltLotUnrealizedGainLossPercent: number;
  isSettled: boolean;
  // Below added in UI only
  hasDummyCashLotOnly: boolean;
  pendingTrade?: ITacticalTrade;
  trade: ITacticalTrade;
}

export interface ITacticalHolding extends ITacticalTaxLotBase {
  // Below added in UI only
  taxLots: ITacticalTaxLot[];
  holdingTradesGainLossPercent: number;
  stHoldingTradesGainLossPercent: number;
  ltHoldingTradesGainLossPercent: number;
  buyAmount?: number;
  sellAmount?: number;
}

export interface ITacticalTrade {
  tradeId: number;
  taxLotId: number;
  portfolioId: number;
  accountId: number;
  securityId: number;
  symbol: string;
  securityName: string;
  tradeInstanceId: number;
  custodian: string;
  custodianId: number;
  positionId: number;
  action: TradeAction;
  status: string;
  messages: { shortCode: string; arguments: string; accountId: number }[];
  warningMessage: string;
  rebalanceLevel: string;
  rebalanceLevelId: number;
  tradeCreatedDate: Date;
  isEnabled: boolean;
  tradeShares: number;
  price: number;
  tradeAmount: number;
  cashValuePostTrade: number;
  tradeGainLoss: number;
  tradeSTGainLoss: number;
  tradeSTGainLossPercent: number;
  tradeLTGainLoss: number;
  tradeLTGainLossPercent: number;
  tradeGainLossPercent: number;
  approvalStatus: string;
  approvalStatusId: number;
  redemptionFee: number;
  orderStatusId: number;
  transactionFee: number;
  totalCost: number;
  dateAcquired: Date;
  washAmount: number;
  washUnits: number;
  isSma: boolean;
  billingAccount?: number;
  restrictedPlanName: string;
  taxableType: string;
  costAmount?: number;
  taxLotPrice?: number;
  costPerShare?: number;
  priceDate?: Date;
  securityType: string;
  instanceNotes: string;
  /** added after trade has been validated */
  isValid: boolean;
  /** added after trade has been validated */
  validationMessage: string;
  /** added after trade has been validated */
  error: ITradeOrderValidationError[];
  isDeleted: boolean;
  tradePercent: number;
  // Below added in UI only
  originalAction: TradeAction;
  /** type of edit made on the trade (shares, amount, percent) */
  editType: TradeEditType;
  /** showing the location of order (block, allocation, pending) */
  orderLocation: string;
  /** represents if the trade is an aggregated trade. */
  isAggregatedTrade: boolean;
  /** trade-Ids of trades that were aggregated to form the aggregated trade. */
  aggregateOfTradeIds: number[];
  /** taxLotId of trade that was aggregated */
  taxLotIdBeforeAggregation: number;
  /** orderQty of trade that was aggregated */
  orderQtyBeforeAggregation: number;
  /** trade amount of trade that was aggregated */
  tradeAmountBeforeAggregation: number;
  /** represents if the trade is used for aggregation to form an aggregated trade */
  isUsedForAggregation: boolean;
  /** Flag to store if the aggregated trade was modified */
  isTradeModified: boolean;
  /** true if the trade is a VSP trade. */
  isVsp: boolean;
  /** rank of the taxLot associated with a sell trade. */
  rank: number;
  isExcludedHolding: boolean;
  tradeOrderMessageIds: string;
  complianceTradeId?: string;
  complianceApprovalStatus?: string;
  complianceMessage?: string;
  isComplianceValidation?: boolean;
  tradeIdentityUuid?: string;
  requestId?: string;
  isTradeFromOtherTradeToolInTactical?: boolean;
  productCategoryName: string;
  productClassName: string;
  productSubClassName: string;
  isCustodialCash?: boolean;
  isTradableMoneyMarket?: boolean;
  isCashSecurity?: boolean;
}

export interface ITacticalMostUnderweightDistribution {
  fromSecurityId: number;
  toSecurityId: number;
  toSecurityIdUnique: string;
  taxType: string;
  custodian: string;
  tradeAmount: number;
  tradeWeight: number;
  action: TradeAction;
  accountId: number;
}

export interface ITacticalProRataDistribution {
  sourceSecurityId: number;
  targetSecurityId: number;
  targetSecurityUniqueId: string;
  taxType: string;
  custodian: string;
  weightPercentage: number;
  parentModelDetailId: number;
  accountId?: number;
  equivalentLevel?: string;
}

export interface ITradeOrderValidationError {
  errorMessageArguments: string;
  errorMessageId: number;
  tradeId: number;
  // Below added in UI only
  errorMessage: string;
}

export interface ITacticalTradesToBeValidated {
  application: string;
  trades: ITacticalTrade[];
}

export interface ITacticalTradeSave extends ITacticalTradesToBeValidated {
  instanceNotes: string;
  instanceIdForTradeToolsInTactical: number;
}

export interface ITradeOrderErrorMessage {
  severity: number;
  code: string;
  errorMessage: string;
  accountId: string;
  accountNumber: string;
  symbol: string;
}

export interface ITradeOrderMessage {
  id: number;
  severityId: number;
  shortCode: string;
  message: string;
}

export interface IRestrictedSecurity {
  securityId: number;
  symbol: string;
  status: string;
  level: string;
  portfolioId: number;
  accountId: number;
}

export interface ITacticalLayout {
  modelTolerance: any;
  tradeDetail: any;
}

export interface ITacticalView {
  id: number;
  viewTypeId: number;
  gridColumnDefs: string | ITacticalLayout;
  view?: {
    columnState: any[];
    filters: any;
  };
}

export interface ICashDetail {
  name: string;
  dollarValue?: number;
  percentValue?: number;
  data?: any;
  cellClassRules?: any;
}

export interface ITacticalComponentState {
  gridState: string;
  data?: any;
  isMaximized?: boolean;
  isVisible?: boolean;
}

export interface ITacticalNodesForDistribution {
  securityId: number;
  aggSecurityName: string;
  need: number;
}

export interface ITacticalComponent { }

export interface ITacticalComponentInstance {
  name: string;
  component: any;
  state: ITacticalComponentState;
  instance: any;
  isUpdated?: boolean;
}

export interface ITacticalTaxLotGroupsForDuplicateTrades {
  taxLotId: string;
  tradeInfo: ITacticalTrade[];
}

export interface ITargetNeeds {
  securityId: number;
  uniqueId: string;
  need: number;
  alternates: any[];
  accountId: number;
  modelName: string;
}

export interface IModelSummary {
  id: number;
  name: string;
}

export interface IModelOption {
  modelType: ModelType;
  viewType: TacticalViewType;
  displayName: string;
  field: string;
  groupField: string;
  prefix: string;
  visible: boolean;
  isPortfolioRebalanceLevel: boolean;
  isPortfolioReviewLevel: boolean;
  sequence: number;
  isSleevePortfolioRebalanceLevel: boolean;
}

export interface ITaxLotsGroupBySecurityId {
  securityId: string;
  modelAssets: IModelAnalyzer[];
}

export interface ITradeToolTradesAndInstanceInsideTactical {
  instanceId: number;
  trades: ITacticalTrade[];
}

export interface ITradeToolAccount {
  accountId: string | number;
  accountNumber: string;
  amount: number;
  custodialAccountNumber: string;
  failedReason: string;
  hasTaxLossHarvest?: boolean;
  id: number;
  isSleeve: boolean | number;
  modelId: number;
  name: string;
  needAnalytics: number;
  portfolioId: number;
  portfolioName: string;
  sleeveType: string;
  hasContribution: boolean;
  hasDistribution: boolean;
}

export interface IEquivalent {
  accountId: number;
  taxType: string;
  custodian: string;
  sourceSecurityId: number;
  targetSecurityId: number;
  aggSecurityName: string;
}

export interface ITTSecurity {
  id: number;
  name: string;
  securityFullName: string;
  tradeOrderAction: string;
  tradeOrderShares: number;
  tradeOrderAmount: number;
  tradeOrderPrice: number;
  tradeOrderRedemptionFee: number;
  tradeCost: number;
  tradeOrderHoldUntil: string;
  postTradeShares: number;
  postTradeAmount: number;
  postTradePercentage: number;
  differenceAmount: number;
  differencePercentage: number;
  modelTargetShares: number;
  modelTargetAmount: number;
  modelTargetPer: number;
  currentShares: number;
  currentAmount: number;
  currentPercentage: number;
  gainLossCostShortTerm: number;
  gainLossCostLongTerm: number;
  gainAmount: number;
  gainPer: number;
  tradeGain: number;
  commentsTradeReason: string;
  commentsMessage: string;
  isSMA: boolean;
  isMACSecurity: boolean;
  exclude: boolean;
  /** UI related */
  collapsed: boolean;
  accounts: ITTAccount[];
  taxlots: ITTTaxlot[];
  finalTradeOrderAction: string;
  finalTradeOrderShares: number;
  finalTradeOrderAmount: number;
  commentsfullMessage: string;
  finalTradeCost: number;
  accountsViewOnly: boolean;
  isNewSecurity: boolean;
  tradeOrderId: number;
  /** UI related */
  removePending: boolean;
  /** UI related */
  isValidTrade: boolean;
  isAggregateSecurity: boolean;
  redemptionFee: number;
  buyTransactionFee: number;
  sellTransactionFee: number;
  isEquivalentSecurity: boolean;
  isLocationAlternate: boolean;
  isMultiple: boolean;
  distributedTradeAmount: number;
  securityCashNeed: number;
  securitySetName: string;
  nodeId: number;
  isQuickTrade: boolean;
  primarySecurity: number;
  alternative: string;
  responseTaxlots: ITTToolValidateResponseTaxLot[];
  openaccounts: ITTAccount[];
  opentaxlots: ITTTaxlot[];
  cashAccounts: IAccountCash[];
  securitySetId: number;
  status: number;
  lowerModelToleranceAmount: number;
  upperModelToleranceAmount: number;
  tempPostTrade: number;
  tempPostPer: number;
  tempPostShare: number;
  costAmount: number;
  isInAccount: boolean;
  modelDetailId: number;
  accountIds: number[];
  modelParentId: number;
  currentAction: string;
  macWeightings: any[];
}

export interface ITTAccount {
  id: number;
  name: string;
  accountNumber: string;
  custodian: string;
  type: string;
  currentShares: number;
  currentAmount: number;
  currentPercentage: number;
  tradeOrderAction: string;
  previousTradeOrderAction: string;
  tradeOrderAmount: number;
  tradeOrderShares: number;
  tradeOrderHoldUntil: string;
  costShortTerm: number;
  costLongTerm: number;
  gainAmount: number;
  gainPer: number;
  tradeGain: number;
  alternative: string;
  info: string;
  /** UI related */
  collapsed: boolean;
  taxlots: ITTTaxlot[];
  redemptionFee: number;
  tradeCost: number;
  transactionFee: number;
  responseTaxlots: ITTToolValidateResponseTaxLot[];
  viewOnly: boolean;
  isAggregateTradeOrder: boolean;
  isSMA: boolean;
  isMAC: boolean;
  vspInfo: boolean;
  tradeOrderId: number;
  /** UI related */
  removePending: boolean;
  /** UI related */
  isValidTrade: boolean;
  excludedValue: number;
  exclude: boolean;
  buyTransactionFee: number;
  sellTransactionFee: number;
  opentaxlots: ITTTaxlot[]; /** OEF-4652 */
  tradeActionLoad: string;
  tradeAmountLoad: number;
  Price: number;
  postTradeAmount: number;
  postTradePercentage: number;
  postTradeShares: number;
  macFundTradeParam: IMACFundTradeParam[];
  currentSelectedAction: string;
  accountId: number;
  notes: string;
  costAmount: number;
  previousTradeOrderAmount: number;
}

export interface ITTTaxlot {
  id: number;
  accountId: number;
  rank: number;
  acquiredDate: Date;
  taxlotShares: number;
  taxlotPrice: number;
  buyPrice: number;
  costShortTerm: number;
  costLongTerm: number;
  gainAmount: number;
  gainPer: number;
  redemptionFee: number;
  tradeCost: number;
  tradeShares: number;
  tradeAmount: number;
  canEdit: boolean;
  tradeOrderId: number;
  isAggregateTaxlots: boolean;
  isMACSecurity: boolean;
  taxlotTypeId: number;
  tradeOrderShares: number;
  tradeOrderAmount: number;
  acquiredDays: number;
  currentPrice: number;
  costPerShare: number;
  calculateGainLoss: boolean;
}

export interface IAccountCash {
  id: number;
  orionAccountId: number;
  name: string;
  currentValue: number;
  reserveValue: number;
  totalValue: number;
  targetValue: number;
  postTradeValue: number;
  needsValue: number;
  setAsideCash: number;
  lowerModelToleranceAmount: number;
  upperModelToleranceAmount: number;
  tradeAmount: number;
  isTaxableAccount: boolean;
  custodian: string;
  accountNumber: string;
  type: string;
  accountValue: number;
  excludedValue: number;
  cashNeedAmount: number;
  gainLossCostLongTerm: number;
  gainLossCostShortTerm: number;
  gainPercentage: number;
  gainAmount: number;
  realizedAndUnrealizedGainAmount: number;
  realizedGainLossAmount: number;
  costAmount: number;
  cashInTolerance: number;
  billingAccountCash: number;
  updatedReserveCash: number;
  modelAndRebalancerCash: number;
  SMA: boolean;
}

export interface ITacticalQuery {
  portfolioId: number;
  portfolioName: string;
  accountId: string;
  usePending: boolean;
  defaultAction: string;
  portfolios: any[];
}

export interface ITTToolValidateResponseTaxLot {
  id: number;
  taxLotQty: number;
  taxLotAmount: number;
  rank: number;
  taxlotTypeId: number;
  acquiredDays: number;
  currentPrice: number;
  costPerShare: number;
  isLongTerm: number;
}


export interface IMACFundTradeParam {
  accountId: number;
  securityId: number;
  accountName: string;
  accountNumber: string;
  securityName: string;
  securitySymbol: string;
  currentValue: number;
  postTradeValue: number;
  currentShares: number;
  postTradeShares: number;
  tradeAction: string;
  weightings: IMACweightings[];
  finalTradeAmt: number;
  modelId: number;
  tradeActionId: number;
  finalAmt: number;
}

export interface IMACweightings {
  effectiveTargetPercentage: number;
  macWeightingPercentage: number;
  weightingName: string;
  tradeAmount: number;
  target: number;
  originalPostTradeAmount: number;
  postTradeAmount: number;
  NeedsAmount: number;
  macParentLevelId: number;
  macSecurityId: number;
}

export interface IReverseSyncMessage {
  synchType: string;
  instanceId?: number;
  deletedTradeIds?: string;
}

export interface IReverseSyncResponse {
  message: string;
}

export interface ITacticalAggTrade {
  accountId: number;
  securityId: number;
  taxableType: string;
  custodian: string;
  tradeAmount: number;
  originalTrade: number;
  trade: ITacticalTrade;
}

export interface IGroupByDuplicateTrade {
  accountAndSecurityId: string;
  tradesData: ITacticalTrade[];
}

export interface ITacticalSecurity {
  id: number;
  name: string;
  symbol: string;
  securityType: string;
  cusip: string;
  price: number;
  productClassName: string;
  productCategoryName: string;
  productSubClassName: string;
}

export interface IAccountSetAsideCashSettings {
  accountId: number;
  accountNumber: string;
  setAsideCashAmountType: SetAsideCashAmountType;
  setAsideCashAmount: number;
  minCashAmount?: number;
  maxCashAmount?: number;
  transactionAmountTolerance?: number;
  setAsidePercentCalculationTypeId: SetAsidePercentCalculationType;
  isActive: boolean;
}

/**
 * Used to set the aggregated/calculated aside settings that will ultimately
 * get pushed to the cash target.
 */
export interface IAccountSetAsideTargetStageValues {
  cashTarget: number;
  minimumTargetDollar: number;
  maximumTargetDollar: number;
  isSetAsideMinMaxValueSet: boolean;
}

/**
 * Used to process iterative cash calculations
 */
export interface IAccountCashCounters {
  currentCash: number;
  lastPositiveCashAmount: number;
}

export interface IAccountCashPreference {
  accountId: number;
  preferenceName: string;
  preferenceValue: string;
  indicatorValue: string;
}

export interface ICashIndicatorValue {
  indicator: string;
  value: number;
}

export interface ICurrentCashAndManagedValues {
  currentCash: number;
  managedAndExcludedValueToUse: number;
  managedValueToUse: number;
  managedValueToUseForBilling?: number;
}

export interface ICashTargets {
  /** acount id the targets are tied to */
  accountId: number;
  /**  Target % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  targetCashPercent: number;
  /**  Target $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  targetCash: number;
  modelPlusRebalanceMinimumTargetPercent: number;
  /**  M&R Target $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  modelPlusRebalanceMinimumTargetCash: number;
  /**  M&R Target % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  modelPlusRebalanceTargetPercent: number;
  /**  M&R Target $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  modelPlusRebalanceTargetCash: number;
  /** Billing Target % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  billingAccountTargetPercent: number;
  /** Billing Target $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  billingAccountTargetCash: number;
  /** Reserve Target % -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  reserveTargetPercent: number;
  /** Reserve Target $ -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  reserveTargetDollar: number;
  /** Set Aside Target % -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  setAsidePercentTarget: number;
  /** Set Aside Target $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  setAsideCashTarget: number;
  /** Set Aside Min Target % -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  setAsideMinimumTargetPercent: number;
  /** Set Aside Min Target $ -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  setAsideMinimumTargetDollar: number;
  /** Set Aside Max Target % -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  setAsideMaximumTargetPercent: number;
  /** Set Aside Max Target $ -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  setAsideMaximumTargetDollar: number;
  replenishBillingTargetDollarToUse: number;
  replenishReserveTargetDollarToUse: number;
  isSetAsideMinMaxValueSet: boolean;
}

export interface ICashValues {
  /** acount id the values are tied to */
  accountId: number;
  /**  Current % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  currentCashPercent: number;
  /**  Current $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  currentCash: number;
  /** M&R % -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  modelPlusRebalancePercent: number;
  /** M&R $ -> There is no IAccountAndCashDetail property that is shown on UI, but we use for calculations */
  modelPlusRebalanceCash: number;
  /** Billing % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  billingAccountActualPercent: number;
  /** Billing $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  billingAccountActualCash: number;
  /** Reserve % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  reserveCashPercent: number;
  /** Reserve $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  reserveCashActual: number;
  /** Set Aside % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  setAsideCashPercent: number;
  /** Set Aside $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  setAsideCashActual: number;
  /** Set Aside Min % -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  setAsideCashMinPercent: number;
  /** Set Aside Min $ -> stores calculated value that gets mapped to IAccountAndCashDetail property that is shown on UI */
  setAsideCashMin: number;
  /** this flag will be true when set aside cash value is set to target */
  setAsideCashToTarget?: boolean;
  /** account sleeve type */
  accountSleeveType?: string;
}

export interface ICashTargetsAndValues {
  cashTargets: ICashTargets;
  cashValues: ICashValues;
}

export interface IValueSetterParamsWithTradeAction extends ValueSetterParams {
  tradeAction: string;
}

export interface IAccountSmaAllocations {
  id: number;
  accountId: number;
  modelId: number;
  modelDetailId: number;
  weightPercent: number;
  levelId: number;
  subModelId: number;
}

export interface ISwapTrade {
  accountIds: number[];
  security: ISwapSecurity[];
  isExcelImport: boolean;
  tradeToolSelection: number;
  TradeInstanceType: number;
  TradeInstanceSubType: number;
  isViewOnly: boolean;
}

export interface ISwapSecurity {
  accountId: number;
  sellSecurityId: number;
  buySecurityId: number;
  percent: number;
}

export interface ITacticalSmaWeightingsModel {
  disableSaveButton: boolean;
  isSmaValidationError: boolean;
  modelTypes: IModelTypes[];
  originalSmaList: IAccountSMA;
  selectedLevel: number;
  smaList: IAccountSMA;
  smaTotalPercent: number;
  smaValidationError: string;
  smaWeightings: ISmaWeighting[];
}

export interface ISmaWeighting {
  id: number;
  modelDetailId: number;
  modelId: number;
  name: string;
  percent: number;
  subModelId: number;
}

export interface ISmaWeightingSaveModel {
  id: number;
  modelDetailId: number;
  modelId: number;
  weightPercent: number;
  subModelId: number;
}

export type SMAWeightPostSaveActions = {
  saveTrades: boolean;
  runAnaltyics: boolean;
  reloadPortfolio: boolean;
}

export interface IBalanceTargetAmount {
  targetAmount: number;
  validatedTargetAmount: number;
}

export interface ISmaParentLevelAndChildren {
  level: SubModelName;
  children: IModelAnalyzer[];
}

export interface IPortfolioValues {
  portfolioTotalValue: number;
  portfolioExcludedHoldingsValue: number;
  portfolioAsideManagedValue: number;
}

export interface IModelAndRebalanceCashValues {
  modelPlusRebalanceTargetCash: number;
  modelPlusRebalanceTargetPercent: number;
  modelPlusRebalanceMinimumTargetCash: number;
  modelPlusRebalanceMinimumTargetPercent: number;
}

export type RecentSession = {
  timestamp: Date;
  portfolios: IPortfolioSimple[];
};
