import { SessionHelper } from './session.helper';
import { inject, Injectable } from '@angular/core';
import { ILogin, OrionAccessJWTToken } from '../login/login';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../models/user';
import { share } from 'rxjs/operators';
import { RouterHelperService } from './router.helper.service';

@Injectable()
export class AuthService {
  private readonly _routerHelper: RouterHelperService = inject(RouterHelperService);
  private readonly _sessionHelper: SessionHelper = inject(SessionHelper);

  private isLoggedIn: boolean = false;
  private tokenSubject: BehaviorSubject<ILogin>;
  token$: Observable<ILogin>;
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser$: Observable<IUser>;

  constructor() {
    this.isLoggedIn = this._sessionHelper.isAuthenticated();

    this.tokenSubject = new BehaviorSubject<ILogin>(this._sessionHelper.getAccessToken('accessTokenInfo'));
    this.token$ = this.tokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<IUser>(this._sessionHelper.get('user'));
    this.currentUser$ = this.currentUserSubject.asObservable().pipe(share());
  }

  isUserLoggedIn() {
    this.isLoggedIn = this._sessionHelper.isAuthenticated();
    return this.isLoggedIn;
  }

  setToken(tokenInfo: ILogin) {
    if (this.tokenSubject.value?.eclipse_access_token === tokenInfo?.eclipse_access_token) {
      return;
    }
    this._sessionHelper.set('accessTokenInfo', tokenInfo);
    this.tokenSubject.next(tokenInfo);
  }

  getToken = () => this.tokenSubject.getValue();

  public get currentUser(): IUser {
    return this._sessionHelper.getUser();
  }

  public set currentUser(value: IUser) {
    this._sessionHelper.set('user', value);
    this._sessionHelper.set('role', value?.role);
    this.currentUserSubject.next(this._sessionHelper.getUser());
  }

  public clearSession(): void {
    this._sessionHelper.removeAll();
    this._routerHelper.clearHistory();
    this.currentUser = null;
  }

  /**
   * The OrionConnect AL Client Id associated with the current auth token.
   */
  public get ocFirmId(): number {
    const decoded = this.decodeJWT<OrionAccessJWTToken>(this.tokenSubject.value.orion_access_token);
    return +decoded.A_C;
  }

  /**
   * Decodes a base64 string into a readable string.
   * @param base64Url
   * @private
   */
  private base64UrlDecode(base64Url: string): string {
    // Add padding if necessary
    const padding = '='.repeat((4 - base64Url.length % 4) % 4);
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return atob(base64 + padding);
  }

  /**
   * Decodes the `payload` from a JWT token into a JSON object of type T.
   * JWT tokens are a string with 3 parts:  header, payload, and signature.
   * @param token
   * @private
   */
  private decodeJWT<T>(token: string): T {
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('Invalid JWT token format');
    }

    const payload = parts[1];
    const decodedPayload = this.base64UrlDecode(payload);

    try {
      return JSON.parse(decodedPayload) as T;
    } catch(error) {
      throw new Error('Invalid JWT token');
    }
  }
}
