import { Component, Inject, inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import * as Consts from '../../../libs/app.constants';
import { Utils as Util, Utils } from '../../../core/functions';
import { PortfolioEditorService } from './portfolio-editor.service';
import { ModelService } from '../../../services/model.service';
import { IModel, IOriginalAndSubstitutedModel, ISubModelDetails } from '../../../models/modeling/model';
import { WINDOW } from '../../../providers/window.provider';
import { IApprovedCommunityModel, IApprovedCommunityStrategist } from '../../../models/community';
import { CommunityService } from '../../../services/community.service';
import { SecurityWeightingComponent } from '../../../shared/macweightings/security.weighting.component';
import { Subject, takeUntil, tap } from 'rxjs';
import { IWeightingNav } from '../../../models/blendedfund';
import { SecuritySetService } from '../../../services/securityset.service';
import { ModelType } from '../../../libs/model.constants';
import { IFilteredSecuritySet, ISecuritySet } from '../../../models/securityset';
import { IAutoRebalance, IRebalanceOptions, IPortfolioModel } from '../../../models/portfolio';
import { ColDef, GridApi, GridReadyEvent, GridOptions } from '@ag-grid-community/core';
import { RebalanceService } from '../../../services/rebalance.service';
import { BaseComponent } from '../../../core/base.component';
import { ModelToleranceComponent } from '../../tradeorder/shared/modeltolerance.component';
import { IModelDetails } from '../../../models/modeling/modeldetails';
import { ICompleteMethodEvent, IMACWeightingStatusChangeEvent } from '../../../models/event';
import { AlertTypeEnum } from '../../../viewModels/alert';
import { AlertService } from '../../../core';

@Component({
  selector: 'eclipse-portfolio-editor-model',
  templateUrl: './portfolio-model.component.html',
  styleUrls: ['./portfolio-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioModelComponent extends BaseComponent implements OnDestroy {
  private _securityWeightingComponent: SecurityWeightingComponent;
  @ViewChild(SecurityWeightingComponent) set securityWeightingComponent(securityWeighingComponent: SecurityWeightingComponent) {
    this._securityWeightingComponent = securityWeighingComponent;
    this.portfolioEditorService.securityWeightingComponent = securityWeighingComponent;
  };
  get securityWeightingComponent(): SecurityWeightingComponent {
    return this._securityWeightingComponent;
  }
  @ViewChild(ModelToleranceComponent) modelTolerance: ModelToleranceComponent;

  public readonly portfolioEditPermission: boolean = !!Util.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  private readonly _rebalanceService: RebalanceService = inject(RebalanceService);
  private readonly _modelService: ModelService = inject(ModelService);
  private readonly _communityService: CommunityService = inject(CommunityService);
  private readonly _securitySetService: SecuritySetService = inject(SecuritySetService);
  private readonly _alertService: AlertService = inject(AlertService);
  private readonly destroyed$: Subject<void> = new Subject<void>();
  private gridApiModel: GridApi;

  showModelSpinner: boolean;
  modelNeedsUpdate: boolean;
  modelsPermission: boolean;
  weightingNavModel: IWeightingNav;
  modelSuggestions: IModel[];
  displayCommunityModel: boolean;
  canPortfolioAssignToModel: boolean;
  selectedStrategist: number;
  selectedCommunityModel: number;
  errorMsg: string;
  modelList: IApprovedCommunityModel[];
  strategistList: IApprovedCommunityStrategist[];
  securitySetList: ISecuritySet[];
  filteredSecuritySet: IFilteredSecuritySet[];
  showMacWarningMessage: boolean;
  macWarningMessage: string;
  modelImportErrorMsg: string;
  strategistValidation: boolean;
  communityModelValidation: boolean;
  isFTJStrategist: boolean;
  managerList: unknown[];
  managementStyleList: string[];
  mandateCategoryList: string[];
  copyOfModelList: IApprovedCommunityModel[];
  minAmount: number;
  isMinAmountInputDisabled: boolean;
  selectedManagementStyle: string;
  selectedManager: number;
  disableCImpSave: boolean;
  autoRebalanceList: IAutoRebalance[];
  autoRebalanceOptionsList: IRebalanceOptions[];
  modelColDefs: ColDef[];
  modelGridOptions: GridOptions;

  constructor(
    @Inject(WINDOW) private readonly window: Window
  ) {
    super();

    const modelsPrivilege = Util.getPermission(Consts.PRIV_MODELS);
    this.modelColDefs = [];
    this.modelsPermission = !!modelsPrivilege?.canRead;
    this.isFTJStrategist = false;
    this.isMinAmountInputDisabled = true;
    this.selectedManager = 0;
    this.disableCImpSave = false;
    this.autoRebalanceList = [];
    this.autoRebalanceOptionsList = [];
    this.showModelSpinner = false;
    this.modelNeedsUpdate = false;
    this.modelSuggestions = [];
    this.displayCommunityModel = false;
    this.selectedStrategist = 0;
    this.selectedCommunityModel = 0;
    this.managerList = [];
    this.managementStyleList = [];
    this.mandateCategoryList = [];
    this.copyOfModelList = [];
    this.selectedManagementStyle = '';
    this.modelList = [];
    this.strategistList = [];
    this.securitySetList = [];
    this.filteredSecuritySet = [];
    this.modelGridOptions = {
      ...this.defaultFilterableGridOptions,
      sideBar: null
    };
    this.createModelColDefs();
    this.getRebalanceOptions();
  }

  ngOnInit(): void {
    this.populatePortfolioModelDetails();

    this.portfolioEditorService.refreshModelData$.subscribe(() => {
      this.populatePortfolioModelDetails();
    });
  }

  populatePortfolioModelDetails(): void {
    this.portfolioEditorService.portfolio$
      .pipe(
        takeUntil(this.destroyed$),
        tap((portfolio) => {
          if (portfolio.modelId && this.modelsPermission) {
            // Check MAC Status of the portfolio
            this.portfolioEditorService.showMacWeightingsPanel = true; // Whether to show MAC panel or not
            this.weightingNavModel = {
              id: portfolio.id,
              type: Consts.MacEntityTypes.Portfolio,
              isEdit: true,
              macStatus: this.portfolioEditorService.portfolio.macStatus ?? Consts.MACWeightingStatus.Model.value
            };
          }
        })
      )
      .subscribe();
    this.getSecuritySetList();
  }

  ngOnDestroy(): void {
    this.portfolioEditorService.showMacWeightingsPanel = false;
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  searchModels(event: ICompleteMethodEvent): void {
    this._modelService.getModelSearch(event.query.toLowerCase())
      .subscribe((models: IModel[]) => {
        this.modelSuggestions = models.filter(model => !model.isDeleted && model.statusId === Consts.modelStatusEnum.APPROVED);
        this.formatModelSuggestion(this.modelSuggestions, event);
      });
  }

  onAction(event: IMACWeightingStatusChangeEvent): void {
    if (event) {
      switch (event.action) {
        case 'rankValidation':
          this.portfolioEditorService.isSaveButtonDisabled.next(true);
          break;
        case 'save':
          if (event.status === 'success') {
            this.portfolioEditorService.savePortfolioDetails()
              .subscribe((result) => {
                if (result.id) {
                  this.portfolioEditorService.portfolioId$.next(result.id);
                  this.portfolioEditorService.portfolioSave$.next(true);
                }
              });
          }
          break;
        case 'statusChange':
          if (event.value && Number(event.value)) {
            this.macStatusChange(Number(event.value));
          }
          break;
        case 'disableSave':
          this.portfolioEditorService.isSaveButtonDisabled.next(Boolean(event.value));
          break;
        default:
          break;
      }
    }
  }

  macStatusChange(value: number): void {
    if (value === Consts.MACWeightingStatus.None.value) {
      this.portfolioEditorService.isSaveButtonDisabled.next(false);
    } else if (value === Consts.MACWeightingStatus.Model.value) {
      this.portfolioEditorService.isSaveButtonDisabled.next(this.isModelMacStatus);
      if(this.isModelMacStatus) {
        this._alertService.alert.emit([{ typeId: AlertTypeEnum.ERROR, message: Consts.MACValidationRulesMessages.modelNotMACModel }]);
      }
    } else {
      this.portfolioEditorService.isSaveButtonDisabled.next(!this.securityWeightingComponent?.securitiesGridData.length);
    }
  }

  private get isModelMacStatus(): boolean {
    return (this.portfolioEditorService.portfolio.modelDetail?.macStatus ?? this.portfolioEditorService.portfolio.macStatus)
    !== Consts.MACWeightingStatus.Model.value;
  }

  gotoModel(): void {
    this.window.open('#/eclipse/model/list');
  }

  private getStrategists(): void {
    this.strategistList = [];
    this._communityService.getApprovedCommunityStrategist()
      .subscribe((model: IApprovedCommunityStrategist[]) => {
        this.strategistList = model.filter(node => node.isDeleted === 0);
      });
  }

  openCommunityPopup(): void {
    this.getStrategists();
    this.selectedCommunityModel = 0;
    this.selectedStrategist = 0;
    this.modelList = [];
    this.displayCommunityModel = true;
  }

  onStrategistSelection(id: number): void {
    this.modelList = [];

    if (Number(id) === 0) {
      this.managerList = [];
      this.managementStyleList = [];
      this.mandateCategoryList = [];
      this.copyOfModelList = [];
      this.minAmount = null;
      this.isMinAmountInputDisabled = true;
      this.selectedManagementStyle = '';
      this.selectedCommunityModel = 0;
      this.selectedManager = 0;
      this.modelImportErrorMsg = undefined;
      this.isFTJStrategist = false;
      return;
    }
    this.selectedCommunityModel = 0;
    this.isMinAmountInputDisabled = false;
    this.minAmount = null;
    this.modelImportErrorMsg = undefined;
    this.selectedManagementStyle = '';
    this.selectedManager = 0;

    if (id !== 0) {
      this.strategistValidation = false;
    }
    this.selectedStrategist = Number(id);
    const strategists = this.strategistList.filter(strategist => strategist.id === this.selectedStrategist);

    if (strategists[0].name === 'FTJ Fund Choice') {
      this.isFTJStrategist = true;
      this.loadManagers(strategists[0].id);
    } else {
      this.isFTJStrategist = false;
      this.loadApprovedModelByStrategist(this.selectedStrategist);
    }
  }

  private loadManagers(strategistId: number): void {
    this._communityService.getStrategistManagers(strategistId)
      .subscribe(results => {
        this.managerList = results;
      });
  }

  private loadApprovedModelByStrategist(strategistId: number, managerId?: number): void {
    this._communityService.getApprovedCommunityModelsByStrategistId(strategistId, managerId)
      .subscribe((models: IApprovedCommunityModel[]) => {
        this.modelList = models.filter(model => model.isDeleted === 0);
        this.gridApiModel?.sizeColumnsToFit();
        this.copyOfModelList = this.modelList;
        this.managementStyleList = [];
        this.mandateCategoryList = [];
        for (const model of this.modelList) {
          if (model.communityManagementStyle !== null) {
            this.managementStyleList.push(model.communityManagementStyle);
          }
          this.managementStyleList = this.managementStyleList.filter((managementStyleListItem, itr) => {
            return this.managementStyleList.findIndex(management => management === managementStyleListItem) === itr;
          });
        }
      });
  }

  findCommunity(): void {
    this.modelImportErrorMsg = '';
    this.importModelValidation();

    if (this.strategistValidation || this.communityModelValidation || this.selectedCommunityModel === 0) {
      return;
    }

    const tempModel = this.gridApiModel.getSelectedRows();
    const selectedCommunityModel: IPortfolioModel = {
      id: tempModel[0].modelId,
      name: tempModel[0].name,
      description: tempModel[0].description,
      isMAC: tempModel[0].isMAC
    };
    this.displayCommunityModel = false;
    this.isFTJStrategist = false;
    this.portfolioEditorService.onModelSelect(selectedCommunityModel);
  }

  importModelValidation(): void {
    this.strategistValidation = this.selectedStrategist === 0;
    this.communityModelValidation = this.selectedCommunityModel === 0;
  }

  managerChange(): void {
    this.loadApprovedModelByStrategist(this.selectedStrategist, this.selectedManager);
  }

  managementStyleChange(style: string): void {
    this.selectedManagementStyle = style;
    this.modelList = this.copyOfModelList;

    if (this.selectedManagementStyle) {
      this.modelList = this.modelList.filter(model => model.communityManagementStyle === style);
    }

    if (this.minAmount) {
      this.modelList = this.modelList.filter(model => model.minimumAmount === this.minAmount);
    }
  }

  filterModelsGridByMinAmount(): void {
    this.modelList = this.copyOfModelList;
    if (this.minAmount) {
      this.modelList = this.modelList.filter(model => model.minimumAmount === this.minAmount);
    }

    if (this.selectedManagementStyle) {
      this.modelList = this.modelList.filter(model => model.communityManagementStyle === this.selectedManagementStyle);
    }
  }

  onRowSelected(): void {
    const model = this.gridApiModel.getSelectedRows();

    if (model.length === 1) {
      this.selectedCommunityModel = model[0].id;
      this.communityModelValidation = false;
      this.modelImportErrorMsg = '';
      this.disableCImpSave = false;
    } else {
      this.selectedCommunityModel = undefined;
      this.communityModelValidation = false;
    }
  }

  onModelUpdatedOfModelsGrid(): void {
    this.gridApiModel?.sizeColumnsToFit();
  }

  onModelGridReady(event: GridReadyEvent): void {
    this.gridApiModel = event.api;
  }

  cancelFindCommunityModel(): void {
    this.displayCommunityModel = false;
    this.strategistValidation = false;
    this.communityModelValidation = false;
    this.modelList = [];
    this.managerList = [];
    this.managementStyleList = [];
    this.mandateCategoryList = [];
    this.copyOfModelList = [];
    this.selectedManagementStyle = '';
    this.minAmount = null;
    this.modelImportErrorMsg = undefined;
    this.disableCImpSave = false;
    this.isFTJStrategist = false;
  }

  validateAmount(event: KeyboardEvent, val: number): boolean {
    return Utils.isValidDecimalInputKey(event, val, 2);
  }

  createModelColDefs(): void {
    this.modelColDefs = [
      <ColDef>{
        headerName: 'Model Name',
        field: 'name',
        width: 300,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter'
      },
      <ColDef>{
        headerName: 'Management Style',
        field: 'communityManagementStyle',
        cellClass: 'text-center',
        filter: 'agTextColumnFilter',
        width: 170, suppressSizeToFit: true,
      },
      <ColDef>{
        headerName: 'Min. Amount',
        field: 'minimumAmount',
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Advisor Fee (bps)',
        field: 'advisorFee',
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      },
      <ColDef>{
        headerName: 'Model ID',
        field: 'modelId',
        cellClass: 'text-right',
        filter: 'agNumberColumnFilter'
      }
    ];
  }

  /**
* Method to populate model data of substitute model assigned to portfolio.
* @param modelDetails - model details of substitute model assigned to portfolio.
*/
  populateSubstituteModelData(modelDetails: IModel): void {
    const levelNumber = 0;
    this.portfolioEditorService.portfolio.substituteSubModel = [];
    this.portfolioEditorService.portfolio.substitutedModelDetail = modelDetails;
    this.getSecuritySetsOfModel(modelDetails.modelDetail, true, levelNumber);
    this.modelNeedsUpdate = modelDetails.needsUpdate;
  }

  getOriginalAndSubstitutedModelDetailsByPortfolioId(): void {
    if (!this.modelsPermission) {
      return;
    }

    this.showModelSpinner = true;
    this._modelService.getOriginalAndSubstitutedModelDetailsByPortfolioId(this.portfolioEditorService.portfolioId$.value)
      .subscribe((modelsData: IOriginalAndSubstitutedModel) => {
        if (modelsData?.substitutedModel?.id) {
          this.populateSubstituteModelData(modelsData.substitutedModel);
        }
        if (modelsData?.originalModel?.id) {
          this.populateParentModelData(modelsData.originalModel);
          this.reloadMacDetails();
        }
        this.showModelSpinner = false;
      });
  }

  reloadMacDetails(): void {
    if (this.securityWeightingComponent) {
      this.securityWeightingComponent.ngOnInit();
    }
  }

  getSecuritySetsOfModel(children: IModelDetails, isSubstitutedModelId: boolean, levelNumber: number = 0): number {
    if (children !== undefined) {
      const subModel = {
        id: children.id,
        name: children.name,
        targetPercent: children.targetPercent,
        modelDetailId: children.modelDetailId,
        substitutedOf: children.substitutedOf,
        haveSubsitute: false,
        securitySets: [],
        isModelParentNode: false,
        substituteSubNode: null
      };
      this.processChildren(children, levelNumber, isSubstitutedModelId, subModel);
    }
    return 0;
  }

  private processChildren(children: IModelDetails, levelNumber: number, isSubstitutedModelId: boolean, subModel: ISubModelDetails): void {
    for (const element of children.children) {
      element.isEdited = null;
      if (element.children.length > 0) {
        this.processChildWithChildren(children, element, levelNumber, isSubstitutedModelId);
      } else {
        this.processChildWithoutChildren(element, children, subModel);
      }
    }
    if (subModel.securitySets.length > 0) {
      this.addSubModel(isSubstitutedModelId, subModel, levelNumber);
    }
  }

  private addSubModel(isSubstitutedModelId: boolean, subModel: ISubModelDetails, levelNumber: number): void {
    if (isSubstitutedModelId) {
      this.portfolioEditorService.portfolio.substituteSubModel.push(subModel);
    } else {
      subModel.isModelParentNode = !levelNumber;
      this.portfolioEditorService.portfolio.modelSubModel.push(subModel);
    }
  }

  private processChildWithoutChildren(element: IModelDetails, children: IModelDetails, subModel: ISubModelDetails): void {
    if (element.modelTypeId === ModelType.SECURITY_SET) {
      if (children.haveUpperLevelSubstitute) {
        subModel.haveSubsitute = children.haveUpperLevelSubstitute; // Check the substitute at upper level
      }

      if (element.securityAsset && this.securitySetList) {
        const securitySet = this.securitySetList.find(sec => sec.id === element.securityAsset.id);
        if (securitySet) {
          element.canEdit = !!securitySet.canEdit;
        }
      }
      subModel.securitySets.push(Util.deepClone(element));
    }
  }

  private processChildWithChildren(children: IModelDetails, element: IModelDetails, levelNumber: number, isSubstitutedModelId: boolean): void {
    if (children.isSubstituted === 1 || children.haveUpperLevelSubstitute) {
      element.haveUpperLevelSubstitute = true; // Check the substitute at upper level
    }
    levelNumber = levelNumber + 1;
    this.getSecuritySetsOfModel(element, isSubstitutedModelId, levelNumber);
  }

  private populateParentModelData(modelDetails: IModel): void {
    this.portfolioEditorService.portfolio.modelSubModel = [];
    this.portfolioEditorService.portfolio.modelDetail = modelDetails;
    this.canPortfolioAssignToModel = modelDetails.canPortfolioAssignToModel;
    this.getSecuritySetsOfModel(modelDetails.modelDetail, false);
    this.modelNeedsUpdate = modelDetails.needsUpdate;
    if (this.portfolioEditorService.portfolio.modelSubModel?.length && this.portfolioEditorService.portfolio.substituteSubModel?.length) {
      this.mergeMainModelWithSubstitutedModel();
    }
  }

  mergeMainModelWithSubstitutedModel(): void {
    for (const mainModel of this.portfolioEditorService.portfolio.modelSubModel) {
      mainModel.haveSubsitute = true;
      for (const substituteModel of this.portfolioEditorService.portfolio.substituteSubModel) {
        // Check the substitute at upper level
        const haveSubstitute = !((mainModel.id === substituteModel.id && !substituteModel.haveSubsitute) || mainModel.isModelParentNode);
        if (!haveSubstitute) {
          mainModel.haveSubsitute = false;
        }

        this.updateSecuritySetInfo(mainModel, substituteModel, haveSubstitute);
        if (mainModel.modelDetailId === substituteModel.substitutedOf) {
          mainModel.substituteSubNode = substituteModel;
        }
      }
    }
  }


  updateSecuritySetInfo(mainModel: ISubModelDetails, substituteModel: ISubModelDetails, haveSubstitute: boolean): void {
    for (const mainSet of mainModel.securitySets) {
      for (const substituteSet of substituteModel.securitySets) {
        if (mainSet.modelDetailId === substituteSet.substitutedOf) {
          mainSet.substitutedId = this.securitySetList.find(element => element.id === substituteSet.securityAsset.id);
          mainSet.haveSubsitute = true;
        }
        if (!haveSubstitute && mainSet.id === substituteSet.id) {
          mainSet.substituteModelDetailId = substituteSet.modelDetailId;
        }
      }
    }
  }

  removeAlternateSecuritySet(securitySet: ISecuritySet): void {
    securitySet.substitutedId = '';
    securitySet.haveSubsitute = false;
    this.handleSecuritySet(securitySet);
    this.removeFromSubstituteModel(securitySet);
  }

  handleSecuritySet(securitySet: ISecuritySet): void {
    if (this.portfolioEditorService.portfolio.substitutedModelDetail) {
      this.updateSubstituteModel(this.portfolioEditorService.portfolio.substitutedModelDetail.modelDetail.children, securitySet);
    } else {
      this.updateModelDetail(this.portfolioEditorService.portfolio.modelDetail.modelDetail.children, securitySet);
    }
  }

  removeFromSubstituteModel(securitySet: ISecuritySet): void {
    this.portfolioEditorService.portfolio.substituteSubModel.forEach(substituteModel => {
      const substituteSet = substituteModel.securitySets.find(secSet => secSet.modelDetailId === secSet.substitutedOf);
      if (substituteSet) {
        substituteSet.substitutedOf = null;
        substituteSet.isSubstituted = 0;
        securitySet.substituteModelDetailId = substituteSet.modelDetailId;
        return true;
      }
    });
  }

  updateSubstituteModel(children: IModelDetails[], securitySet: ISecuritySet): void {
    if (children) {
      for (const element of children) {
        if (element.children.length) {
          this.updateSubstituteModel(element.children, securitySet);
        } else if (element.modelTypeId === ModelType.SECURITY_SET) {
          this.updateSecuritySet(element, securitySet);
        }
      }
    }
  }

  private updateSecuritySet(element: IModelDetails, securitySet: ISecuritySet): void {
    if ((element.substitutedOf === securitySet.modelDetailId && element.isSubstituted)
      || (element.substitutedCheck === securitySet.modelDetailId && element.isSubstituted
        && securitySet.substitutedId !== '')) {
      this.updateSubstitutedElement(element, securitySet);
    } else if (element.modelDetailId === securitySet.substituteModelDetailId && !element.isSubstituted
      && securitySet.substitutedId !== '') {
      this.updateNonSubstitutedElement(element, securitySet);
    } else if (element.substitutedCheck === securitySet.modelDetailId && element.isSubstituted
      && securitySet.substitutedId === '') {
      this.resetSubstitutedElement(element, securitySet);
    }
  }

  private resetSubstitutedElement(element: IModelDetails, securitySet: ISecuritySet): void {
    element.isSubstituted = false;
    element.substitutedOf = null;
    element.isEditSubstituted = false;
    element.isNewlySubstitutedNode = false;
    element.name = securitySet.name;
    element.securityAsset = securitySet.securityAsset;
  }

  private updateNonSubstitutedElement(element: IModelDetails, securitySet: ISecuritySet): void {
    element.substitutedCheck = securitySet.modelDetailId;
    element.isSubstituted = true;
    if (element.isEditSubstituted) {
      element.substitutedOf = securitySet.modelDetailId;
    } else {
      element.substitutedOf = element.id;
    }
    element.isNewlySubstitutedNode = true;
    element.name = securitySet.substitutedId.name;
    element.securityAsset = { id: securitySet.substitutedId.id };
  }

  private updateSubstitutedElement(element: IModelDetails, securitySet: ISecuritySet): void {
    element.substitutedCheck = securitySet.modelDetailId;
    element.isSubstituted = true;
    element.substitutedOf = securitySet.modelDetailId;
    element.isEditSubstituted = true;
    element.isNewlySubstitutedNode = true;
    if (securitySet.substitutedId !== '') {
      element.name = securitySet.substitutedId.name;
      element.securityAsset = { id: securitySet.substitutedId.id };
    } else {
      element.substitutedOf = null;
      element.isSubstituted = false;
      element.name = securitySet.name;
      element.securityAsset = securitySet.securityAsset;
    }
  }

  updateModelDetail(children: IModelDetails[], securitySet: ISecuritySet): void {
    if (children) {
      for (const element of children) {
        if (element.children.length) {
          this.updateModelDetail(element.children, securitySet);
        } else {
          this.updateElementDetail(element, securitySet);
        }
      }
    }
  }

  private updateElementDetail(element: IModelDetails, securitySet: ISecuritySet): void {
    if (element.modelTypeId === ModelType.SECURITY_SET) {
      if (element.modelDetailId === securitySet.modelDetailId && securitySet.substitutedId !== '') {
        element.isSubstituted = true;
        element.substitutedOf = element.id;
        element.isNewlySubstitutedNode = true;
        element.name = securitySet.substitutedId.name;
        element.securityAsset = { id: securitySet.substitutedId.id };
      } else if (element.modelDetailId === securitySet.modelDetailId && element.isSubstituted
        && securitySet.substitutedId === '') {
        element.isSubstituted = false;
        element.substitutedOf = null;
        element.isNewlySubstitutedNode = false;
        element.name = securitySet.name;
        element.securityAsset = securitySet.securityAsset;
      }
    }
  }

  getSecuritySetList(): void {
    this._securitySetService.getSecuritySetData()
      .subscribe(securitySets => {
        this.securitySetList = securitySets;
        this.getOriginalAndSubstitutedModelDetailsByPortfolioId();
      });
  }

  autoSecuritySetSearch(event: ICompleteMethodEvent, subModelIndex: number, setIndex: number): void {
    const query = event.query;
    this.filteredSecuritySet = [];
    this.filterSecuritySetByQuery(query);
    this.filterExistingSecuritySets(subModelIndex);

    const subModelId = this.portfolioEditorService.portfolio.modelSubModel[subModelIndex].id;
    if (this.portfolioEditorService.portfolio.substitutedModelDetail?.modelDetail) {
      this.removeSecuritySet(this.portfolioEditorService.portfolio.substitutedModelDetail.modelDetail.children, subModelId);
    } else {
      this.removeSecuritySet(this.portfolioEditorService.portfolio.modelDetail.modelDetail.children, subModelId);
    }
    this.portfolioEditorService.portfolio.modelSubModel[subModelIndex].securitySets[setIndex].filteredSecuritySet = this.filteredSecuritySet
      .filter(securitySet => !!securitySet.isDynamic === !!this.portfolioEditorService.portfolio.modelDetail.isDynamic);
  }

  filterSecuritySetByQuery(query: string): void {
    for (const securitySet of this.securitySetList) {
      if (RegExp(`^.*${query.toLowerCase()}.*$`).exec(securitySet.name.toLowerCase())
        || securitySet.id === Number(query)
        || RegExp(`^.*${query}.*$`).exec(securitySet.name.toUpperCase())) {
        this.filteredSecuritySet.push({ id: securitySet.id, name: securitySet.name, isDynamic: securitySet.isDynamic });
      }
    }
  }

  filterExistingSecuritySets(subModelIndex: number): void {
    const securitySets = this.portfolioEditorService.portfolio.modelSubModel[subModelIndex].securitySets;
    for (const securitySet of securitySets) {
      this.filteredSecuritySet = this.filteredSecuritySet.filter(set => set.id !== securitySet.securityAsset.id);
    }
  }

  removeSecuritySet(children: IModelDetails[], id: number): void {
    if (children) {
      for (const element of children) {
        if (element.children.length > 0) {
          if (element.id === id) {
            element.children.forEach(s => {
              this.filteredSecuritySet = this.filteredSecuritySet.filter(set => set.id !== s.securityAsset.id);
            });
          } else {
            this.removeSecuritySet(element.children, id);
          }
        }
      }
    }
  }

  onChange(securitySet: ISecuritySet): void {
    securitySet.error = '';
    securitySet.isError = false;

    if (securitySet.haveSubsitute && securitySet.substitutedId === '') {
      this.removeAlternateSecuritySet(securitySet);
    } else if (!securitySet.haveSubsitute && securitySet.substitutedId === '') {
      this.handleSecuritySet(securitySet);
    } else if (!securitySet.substitutedId.id) {
      this.errorMsg = Consts.Messages.model.invalidSubstitute;
      securitySet.error = this.errorMsg;
      securitySet.isError = true;
    }
  }

  showPortfolioModelApprovalPopup(): void {
    this.portfolioEditorService.portfolio.displayPfMdlApprvlSec = true;
  }

  onAutoRebalanceChange(autoRebalanceId: number): void {
    if (this.portfolioEditorService.portfolio.rebalanceDay === null) {
      this.portfolioEditorService.portfolio.rebalanceDay = 1;
    }

    if (Number(autoRebalanceId) !== 0) {
      const rebalFilter = this.autoRebalanceList.filter(autoRebalance => autoRebalance.id === Number(autoRebalanceId));

      if (rebalFilter.length) {
        this.autoRebalanceOptionsList = this.autoRebalanceList
          .filter(autoRebalance => autoRebalance.id === Number(autoRebalanceId))[0]?.rebalanceOptions;
      }

      if (this.autoRebalanceOptionsList.length) {
        this.portfolioEditorService.portfolio.rebalanceOptionId = this.autoRebalanceOptionsList[0].id;
      }
    } else {
      this.autoRebalanceOptionsList = [];
    }
  }

  private getRebalanceOptions(): void {
    this._rebalanceService.getRebalanceOptions().subscribe({
      next: (model: IAutoRebalance[]) => {
        this.autoRebalanceList = model;

        if (this.portfolioEditorService.portfolio.rebalanceTypeId !== null
          && this.portfolioEditorService.portfolio.rebalanceTypeId !== undefined) {
          this.onAutoRebalanceChange(this.portfolioEditorService.portfolio.rebalanceTypeId);
        }
      },
      error: (error) => {
        console.error('Error fetching rebalance options:', error);
      }
    });
  }

  validateDayValue(value: number): void {
    if (value === 0 || value === null || value === undefined) {
      this.portfolioEditorService.portfolio.rebalanceDay = 1;
    }
  }

  checkRebalanceDay(event: KeyboardEvent, val: number): boolean {
    const value = Utils.convertIntoBooleanValue(val);
    if (!Utils.isValidIntegerInputKey(event, value)) {
      return false;
    }

    const numberCheck = Utils.isNumericKey(event, false);
    if (!numberCheck.valid) {
      return false;
    }

    const currentChar = numberCheck.numericValue;
    if (!isNaN(currentChar)) {
      const nextValue = val + currentChar.toString();

      if (parseInt(nextValue, 10) > 31) {
        this.portfolioEditorService.portfolio.rebalanceDay = 1;
        event.preventDefault();
        return false;
      }
    }
  }
}
