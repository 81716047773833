<div class="row" *ngIf="!isViewOnly && !showUpdatedPanel">
  <div class="col-md-2">
    <div class="form-group">
      <div class="col-md-4" *ngIf="displayMACLabel">
        <label class="control-label">MAC</label>
      </div>
      <div class="col-md-8">
        <select id="macOptions" class="form-control" [(ngModel)]="macStatus" (ngModelChange)="onMacStatusChangeUI()">
          <option *ngFor="let opt of macWeightingStatusList" [ngValue]="opt.id"> {{opt.name}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="col-md-3 col-sm-8 col-xs-12" *ngIf="!hideSearchAndGrid">
    <div class="form-group">
      <p-autoComplete class="autocomplete-search" name="selectedSecurity" [disabled]="disableSearch" [(ngModel)]="selectedSecurity"
                      [suggestions]="securitySuggestions" placeholder="Search Securities " (completeMethod)="getSecurities($event)"
                      (onSelect)="onSecuritySelect($event)" field="securityName" [minLength]="1">
        <ng-template let-selectedSecurity pTemplate="item">
          <ul class="ui-helper-clearfix show-hollding-dropdown">
            <li>
              <i class="lm lm-securities text-info" aria-hidden="true"></i>
              <span>{{ selectedSecurity.securityName }} (<strong>{{selectedSecurity.securitySymbol}}</strong>)</span>
            </li>
          </ul>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
  <div class="col-md-2" *ngIf="!hideSearchAndGrid">
    <button pButton class="p-button-success" [disabled]="disableAddButton" (click)="addSecurity()" label="Add"></button>
  </div>
</div>

<div *ngIf="!isViewOnly && showUpdatedPanel" style="display: grid; grid-template-columns: 3fr 1.5fr">
  <div class="col-md-2">
    <div class="form-group">
      <div class="col-md-4" *ngIf="displayMACLabel">
        <label class="control-label">MAC</label>
      </div>
      <div class="col-md-8 mac-dropdown">
        <p-dropdown [options]="macWeightingStatusList" [(ngModel)]="macStatus" (ngModelChange)="onMacStatusChangeUI()"
          optionLabel="name" optionValue="id">
        </p-dropdown>
      </div>
    </div>
  </div>

  <div style="display: flex;">
    <div class="col-md-10 col-sm-8 col-xs-12" *ngIf="!hideSearchAndGrid">
      <div class="form-group">
        <p-autoComplete class="autocomplete-search" name="selectedSecurity" [disabled]="disableSearch"
          [(ngModel)]="selectedSecurity" [suggestions]="securitySuggestions" placeholder="Search Securities "
          (completeMethod)="getSecurities($event)" (onSelect)="onSecuritySelect($event)" field="securityName"
          [minLength]="1">
          <ng-template let-selectedSecurity pTemplate="item">
            <ul class="ui-helper-clearfix show-hollding-dropdown">
              <li>
                <i class="lm lm-securities text-info" aria-hidden="true"></i>
                <span>{{ selectedSecurity.securityName }} (<strong>{{selectedSecurity.securitySymbol}}</strong>)</span>
              </li>
            </ul>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!hideSearchAndGrid">
      <button *ngIf="showUpdatedPanel" pButton class="p-button-secondary" icon="pi pi-plus"
        [disabled]="disableAddButton" (click)="addSecurity()" label="Add Security"></button>
    </div>
  </div>
</div>

<div class="row" *ngIf="!isViewOnly">
  <div class="col-md-12">
    <span *ngIf="taxableErrorMsg" class="text-danger">{{taxableErrorMsg}}</span>
    <span *ngIf="rankValidationMessage!=undefined" class="text-danger">{{rankValidationMessage}}</span>
  </div>
</div>

<div [ngClass]="{'m-t-10': showUpdatedPanel}" class="row" *ngIf="!hideSearchAndGrid">
  <div class="col-md-12">
    <div class="agcontainer" [ngClass]="{'custom-grid-row': showUpdatedPanel}">
      <ag-grid-angular #agGrid style="width: 100%; height: 300px;" class="ag-theme-alpine"
        [gridOptions]="securitiesGridOptions" rowSelection="single" [columnDefs]="securitiesColDefs"
        [rowData]="securitiesGridData" suppressContextMenu="true" (gridReady)="onGridReady($event)"
        [components]="frameworkComponents">
      </ag-grid-angular>
    </div>
  </div>
</div>

<!--Validation Warning Messages Popup [validationRuleLevel]="validationLevel" -->
<eclipse-macweighting-validation (weightingsCallback)="onActionFromValidation($event)"></eclipse-macweighting-validation>
