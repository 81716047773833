import { Injectable } from "@angular/core";
import { OEHttpClient, SessionHelper } from "../core";
import { EntityType } from "../libs/preference.enums";
import { Observable } from "rxjs";
import { IRelatedEntity, ITradeBlockDetail } from "../models/tradeBlockDetails";
import { tap } from "rxjs/operators";
import { IUser } from "../models/user";
import { INote } from "../models/notes";
import * as Consts from "../libs/app.constants";

@Injectable({
  providedIn: 'root'
})
export class TradeBlockDetailsService {
  private _tradeBlockDetailsEndpoint = 'v2/tradeblockdetails';
  constructor(private _httpClient: OEHttpClient, private _sessionHelper: SessionHelper) {
  }
  getRelatedEntities(entityId: number, entityType: EntityType): Observable<IRelatedEntity[]> {
    return this._httpClient.getData(`${this._tradeBlockDetailsEndpoint}/RelatedEntities?entityId=${entityId}&entityType=${entityType}`)
      .pipe(tap(response => {
        response.forEach(entity => {
          switch (entity.entityType){
            case EntityType.Account:
              entity.entityTypeName = 'Account';
              break;
            case EntityType.Portfolio:
              entity.entityTypeName = 'Portfolio';
              break;
            default:
              entity.entityTypeName = 'Unknown';
          }
        });
      }));
  }

  getTradeBlockDetailsByEntity(entityId: number, entityType: EntityType): Observable<ITradeBlockDetail[]> {
    return this._httpClient.getData(`${this._tradeBlockDetailsEndpoint}?relatedTypeId=${entityId}&relatedType=${entityType}`)
      .pipe(tap(tradeBlockDetails => {
        const user = this._sessionHelper.getUser();
        tradeBlockDetails.forEach(tbd => this.processTradeBlockDetails(tbd, user, entityId, entityType));
      }));
  }

  createTradeBlockDetails(tradeBlockDetails: ITradeBlockDetail[]): Observable<ITradeBlockDetail[]> {
    return this._httpClient.postData(`${this._tradeBlockDetailsEndpoint}/addList`, tradeBlockDetails);
  }

  updateTradeBlockDetails(tradeBlockDetails: ITradeBlockDetail[]): Observable<ITradeBlockDetail[]> {
    return this._httpClient.postData(`${this._tradeBlockDetailsEndpoint}/updateList`, tradeBlockDetails);
  }

  deleteTradeBlockDetails(ids: number[]): Observable<any> {
    return this._httpClient.postData(`${this._tradeBlockDetailsEndpoint}/deleteList`, ids);
  }

  /**
   * Processes trade block details for display.
   * Dates - converts UTC date to a local date.
   * Level - sets the readable version of the level
   * @param tradeBlockDetail
   * @param user
   * @param entityId
   * @param entityType
   */
  processTradeBlockDetails(tradeBlockDetail: ITradeBlockDetail, user: IUser, entityId: number, entityType: EntityType): void {
    tradeBlockDetail.startDate = new Date(tradeBlockDetail.startDate);
    tradeBlockDetail.endDate = tradeBlockDetail.endDate ? new Date(tradeBlockDetail.endDate) : null;
    this.setTradeBlockDetailsEntityLevel(tradeBlockDetail, entityId, entityType);
  }

  /**
   * Sets a readable version of the trade block detail's entity level.  Any trade block detail for an entity other than the current entity
   * will have the entity name appended to it.
   * Example:
   *  Current Entity:  Portfolio 123
   *    Trade Block Detail for Portfolio 123 will have a level of "Portfolio".
   * @param tradeBlockDetail
   * @param entityId
   * @param entityType
   */
  setTradeBlockDetailsEntityLevel(tradeBlockDetail: ITradeBlockDetail, entityId: number, entityType: EntityType): void {
    const isEntityTradeBlockDetail = tradeBlockDetail.relatedType === entityType && tradeBlockDetail.relatedTypeId === entityId; // Is this trade block detail assigned to the current entity
    switch (tradeBlockDetail.relatedType) {
      case EntityType.Account:
        tradeBlockDetail.level = `Account${isEntityTradeBlockDetail ? '' : ` - ${tradeBlockDetail.entityName}`}`;
        break;
      case EntityType.Portfolio:
        tradeBlockDetail.level = `Portfolio${isEntityTradeBlockDetail ? '' : ` - ${tradeBlockDetail.entityName}`}`;
        break;
      default:
        tradeBlockDetail.level = `Unknown Entity Type - ${tradeBlockDetail.entityName}`;
        break;
    }
  }

  /**
   * Returns true if the user can edit the trade block detail; false otherwise.
   * User must own the correct level Edit privilege (e.g. Portfolio CanEdit for Portfolio notes) to edit a note.
   * @param note
   * @param user
   * @param contextEntityType the type of entities the note was loaded from.  Example,
   * if this trade block detail was loaded with all the notes for a Portfolio, the contextEntityType is Portfolio.
   */
  public canUserEditTradeBlockDetail(tradeBlockDetail: ITradeBlockDetail, user: IUser, contextEntityType: EntityType): boolean {
    const canUserEditEntityType = this.canUserEditEntity(tradeBlockDetail.relatedType);
    switch (tradeBlockDetail.relatedType) {
      case EntityType.Portfolio:
      case EntityType.Account:
        return canUserEditEntityType;
      default:
        console.warn('Unknown note entity type:', tradeBlockDetail.relatedType);
        return false;
    }
  }

  /**
   * Returns true if the user can add/edit/delete trade block details for an entity type.
   * Based on user privileges.
   * @param entityType
   */
  public canUserEditEntity(entityType: EntityType): boolean {
    switch (entityType) {
      case  EntityType.Portfolio:
        return !!this._sessionHelper.getPermission(Consts.PRIV_PORTFOLIOS)?.canUpdate;
      case EntityType.Account:
        return !!this._sessionHelper.getPermission(Consts.PRIV_ACCOUNTS)?.canUpdate;
      default:
        console.warn('Unknown note entity type:', entityType);
        return false;
    }
  }
}
