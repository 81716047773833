import { Component, ViewChild } from '@angular/core';
import { AgEditorComponent} from '@ag-grid-community/angular';
import { BaseComponent } from './base.component';
import moment from 'moment-timezone';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'date-renderer',
  template: `<p-calendar #cal appendTo="body" [(ngModel)]="value" (onSelect)="valueChanged($event)" [monthNavigator]="true" [yearNavigator]="true" [minDate]="minimumDate" [yearRange]="getYearRange(50)"></p-calendar>`
})
export class DateRendererComponent extends BaseComponent implements AgEditorComponent {
  @ViewChild('cal') cal: Calendar;
  protected params: any;
  minimumDate: Date = new Date(); //this defaults to a min date of now unless otherwise specified
  value;

  constructor() {
    super();
  }

  isPopup(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    // eslint-disable-next-line eqeqeq
    if (this.params != undefined && this.params.value != undefined && this.params.value != '' && this.params.value != '0000-00-00T00:00:00Z') {
      //if the date is invalid - start from now when selected
      if (isNaN(Date.parse(params.value))){
        this.value = new Date();
        this.params.value = new Date();
      }
      this.value = new Date(moment(new Date(params.value)).tz('UTC').format('YYYY-MM-DD HH:mm:ss')); // cast value as a Date, removing time zone first
    }
    if (this.params !== undefined && this.params.min !== undefined && this.params.min !== ''){
      this.minimumDate = new Date(moment(new Date(params.min)).tz('UTC').format('YYYY-MM-DD HH:mm:ss')); // cast value as a Date, removing time zone first
    }
  }

  ngAfterViewInit() {
    this.cal?.toggle();
  }

  valueChanged(event) {
    // eslint-disable-next-line eqeqeq
    if (event != undefined) {
      this.params.value = event;
    }
  }

  getValue(): any {
    const val = this.value;
    // eslint-disable-next-line eqeqeq
    if (val != undefined) {
      return moment.utc(val).toISOString();
    } else {
      return null;
    }
  }
}
