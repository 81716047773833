<section style="display: flex; flex-direction: column; height: 100%;">
  <div class="page-header-row">
    <h3>Trade Block Details</h3>
  </div>
  <div style="margin-left: 1.5em">
    <eclipse-trade-block-details-list
      (onTradeBlockDetailTouched)="onTradeBlockDetailChanged()"
      [entityId]="portfolioEditorService.portfolioId$ | async"
      [entityType]="entityType">
    </eclipse-trade-block-details-list>
  </div>
</section>
