import { Component, OnDestroy, inject } from '@angular/core';
import { SplitIoService } from '../../../../../core/feature-flag';
import { PortfolioEditorService } from '../../portfolio-editor.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
  selector: 'eclipse-mac-warning',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './mac-warning.component.html'
})
export class MacWarningComponent implements OnDestroy {

  private readonly _splitIOService: SplitIoService = inject(SplitIoService);
  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);

  private readonly destroyed$: Subject<void> = new Subject<void>();

  isPortfolioDetailsModelFFEnabled: boolean;
  displayMacPopUp: boolean;

  constructor() {
    this.isPortfolioDetailsModelFFEnabled = this._splitIOService.featureFlags['TEXP_portfolio_details_model_7522'];
    this.portfolioEditorService.displayMacPopUp
      .pipe(
        takeUntil(this.destroyed$),
        tap((value: boolean) => {
          this.displayMacPopUp = value;
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
