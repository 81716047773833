<div *ngIf="!!(trades && trades.length === 0 && portfolioFlag === null)">
  <!-- No trades were created by the file that was imported -->
  <div class="text-center text-danger m-b-10">No trades were created.</div>
</div>

<div class="excel-import-body" *ngIf="(!trades || !trades?.length) && !portfolioFlag">

  <div class="choose-file-small-box file-upload-input-style p-v-20"
       (dragover)="dragFile($event)" (drop)="dropFile($event)">
    <span pButton class="btn-file"> Select File to Import
        <input type="file" name="model" id="model-file"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               (change)="selectedTemplate($event)" [(ngModel)]="file">
    </span>
    <br/> or drag and drop here
    <div>
      <p *ngIf="checkDragFile">{{dragFileName}}</p>
      <p *ngIf="!checkDragFile">{{file.name}}</p>
    </div>
    <br/>
    <em class="securityError danger" *ngIf="showFileUploadError">
      <strong>{{fileUploadError}}</strong>
    </em>
  </div>
</div>

<div *ngIf="(!trades || !trades.length) && !portfolioFlag" class="custom-tab-container m-t-10">
  <div class="tab-content">
    <h5 class="margin-top-minus5">
      <i class="far fa-lightbulb" aria-hidden="true"></i>
      <strong> A word about file formats</strong>
    </h5>
    <p>The imported file should include as "Account ID" or an "Account Number" column, in
      addition to columns for "Action", "Ticker", and either "Dollars" or "Shares".</p>
    <p>The file's extension can be xlsx or csv.</p>
    <p>Invalid trades will be highlighted
      <span class="text-danger">red</span> and discarded during the import</p>
  </div>
</div>
<div *ngIf="trades?.length || portfolioFlag?.length">
  <div class="text-center"><h4>Import Results</h4></div>
  <div [ngClass]="{ 'fixed-income-table': importType === 'FixedIncome' }" class="table-responsive">
    <ng-template [ngIf]="isQuickTradeImportBlockedAccountsFFEnabled && importType === 'QuickTrade' && trades.length">
      <eclipse-file-trade-import
        [tradeImportData]="trades">
      </eclipse-file-trade-import>
    </ng-template>
    <ng-template [ngIf]="!isQuickTradeImportBlockedAccountsFFEnabled || importType === 'FixedIncome'">
      <table *ngIf="trades?.length > 0 && !portfolioFlag?.length" class="table torder-excellist-table">
        <caption class="hidden-element">Import Results</caption>
        <thead>
        <tr>
          <th scope="col">Account ID</th>
          <th scope="col">Number</th>
          <th scope="col">Action</th>
          <th scope="col">Ticker</th>
          <th scope="col">Dollars</th>
          <th scope="col">Shares</th>
        </tr>
        </thead>
        <tbody *ngIf="importType === 'FixedIncome' && trades.length > 0">
        <tr *ngFor="let order of trades">
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.orionAccountId}}</td>
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}" class="fs-mask">{{order.accountNumber}}</td>
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.action}}</td>
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.security}}</td>
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.dollars}}</td>
          <td [title]="order.error !== null ? order.error : order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.shares}} </td>
        </tr>
        </tbody>
        <tbody *ngIf="!isQuickTradeImportBlockedAccountsFFEnabled && importType === 'QuickTrade' && trades.length > 0">
        <tr *ngFor="let order of trades">
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.orionAccountId}}</td>
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}" class="fs-mask">{{order.accountNumber}}</td>
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.action}}</td>
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.symbol || order.security}}</td>
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.tradeAmount}}</td>
          <td [title]="order.errorMessage || order.warningMessage" [ngClass]="{'danger': !order.isValid}">{{order.tradeShares}} </td>
        </tr>
        </tbody>

      </table>
    </ng-template>
    <div *ngIf="portfolioFlag && portfolioFlag.length > 0">
      <div class="text-center" style="margin-bottom: 10px; font-size: medium; color: lightblue"> One or more portfolio requires analytics - import aborted.</div>
      <table  class="table torder-excellist-table">
        <caption class="hidden-element">Portfolios requiring analytics</caption>
        <thead class="sr-only">
          <tr>
            <th scope="col">Portfolio - Requires Analytics</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let pFlag of portfolioFlag" class="text-warning-light">
          <td>{{pFlag.portfolioId}} - Requires Analytics</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="pull-right">
    <button type="button" pButton (click)="onSave()" [disabled]="disableTrade" label="Trade" icon="fas fa-bolt"></button>
    <button type="button" pButton (click)="onReset()" class="p-button-secondary" label="Reset" icon="far fa-trash"></button>
  </div>
</div>
