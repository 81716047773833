import {Component, Input, ViewChild} from '@angular/core';
import {
  IHiddenSecurityPreference,
  IPortfolioAccountPreferenceSecurity,
  ISecurityPreference
} from '../../../models/preferences/securityPreference';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import {SecurityPreferenceHelper} from '../shared/security-preference-helper';
import {BaseComponent} from '../../../core/base.component';
import {SecurityPreferenceHubService} from '../shared/security-preference-hub.service';
import {
  SecurityPreferenceAddEditComponent
} from '../shared/security-preference-add-edit/security-preference-add-edit.component';
import {Subscription} from 'rxjs';
import {IExitWarning, ISavedView} from '../../../viewModels/savedview';
import {BaseGridConfiguration} from '../../gridextensions/basegrid';
import {ViewTypeEnum} from '../../../libs/app.constants';
import { IExcelDataSheetExportable } from '../../../models/excel-export';
import { SplitIoService } from '../../../core/feature-flag/splitio.service';

@Component({
  selector: 'eclipse-esg-restricted-securities',
  templateUrl: './esg-restricted-securities.component.html',
  styleUrls: ['./esg-restricted-securities.component.scss']
})
export class ESGRestrictedSecuritiesComponent extends BaseComponent implements IExcelDataSheetExportable {
  @Input() preferenceLevel: string;
  @Input() preferenceLevelId: number;
  securityPreferencesHiddenMap: IHiddenSecurityPreference = {};
  private gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  savedView: ISavedView = <ISavedView>{};
  esgRestrictedSecurities: ISecurityPreference[];
  securityPreferences: ISecurityPreference[] = [];
  showPageContent: boolean;
  isTLHAlternatePreferenceFFEnabled: boolean;
  gridContext = {
    isGridModified: false,
    router: undefined,
    isNone: false,
    self: this,
    isFilterChanged: false
  };
  setFilter: boolean;
  @ViewChild(SecurityPreferenceAddEditComponent) securityPreferenceAddEditComponent: SecurityPreferenceAddEditComponent;
  private portfolioAccountPreferenceSecuritySubscription: Subscription;
  private updateSecurityPreferenceSubscription: Subscription;
  private addOrUpdateSecurityPreferenceSubscription: Subscription;

  constructor(private readonly _securityPreferenceHubService: SecurityPreferenceHubService,
    private readonly _splitIoService: SplitIoService
  ) {
    super();
    this.isTLHAlternatePreferenceFFEnabled = this._splitIoService.featureFlags['tlh_alternate_preference_s1784'];
  }

  ngOnInit(): void {
    this.initializeGridObjects();
    this.portfolioAccountPreferenceSecuritySubscription = this._securityPreferenceHubService.portfolioAccountPreferenceSecurity
      .subscribe((portfolioAccountPreferenceSecurityMap: IPortfolioAccountPreferenceSecurity) => {
        this.loadESGRestrictedSecurityData(portfolioAccountPreferenceSecurityMap.esgRestrictedSecurities);
        this.setFilter = true;
      });
    this.updateSecurityPreferenceSubscribe();
    this.addOrUpdateSecurityPreferenceSubscribe();
  }

  ngOnDestroy(): void {
    this.portfolioAccountPreferenceSecuritySubscription.unsubscribe();
    this.updateSecurityPreferenceSubscription.unsubscribe();
    this.addOrUpdateSecurityPreferenceSubscription.unsubscribe();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.savedView.parentGridApi = params.api;
  }

  loadESGRestrictedSecurityData(securityPreferences: ISecurityPreference[]): void {
    this.updateSecurityPreferences(securityPreferences);
  }

  getContextMenuItems(params): any[] {
    const contextResult = [];
    const self = params.context.self;
    if (params.node) {
      contextResult.push({
        name: 'Edit Preferences',
        action: function () {
          const securityPreference = Object.assign({}, self.esgRestrictedSecurities.find(x => x.id === +params.node.data.id));
          self.securityPreferenceAddEditComponent.editSecurityPreference(securityPreference);
        }.bind(params.context.self)
      });
    }
    return contextResult;
  }

  addToGrid(securityPreference: ISecurityPreference): void {
    this._securityPreferenceHubService.addUpdateSecurityPreference.next(securityPreference);
  }

  resetData(): void {
    this.esgRestrictedSecurities = [];
    this.securityPreferences = [];
  }

  private standardView = [
    {groupId: 'securityDetailsGroup', children: ['symbol', 'securityType', 'equivalent', 'doNotTLH']},
    {groupId: 'tradingGroup', children: ['excludeHolding']},
    {groupId: 'tradeSettingsGroup', children: ['sellPriority', 'buyPriority']},
    {groupId: 'tradeMinGroup', children: ['sellTradeMinAmtBySecurity', 'sellTradeMinPctBySecurity', 'buyTradeMinAmtBySecurity', 'buyTradeMinPctBySecurity']},
    {groupId: 'tradeMaxGroup', children: ['sellTradeMaxAmtBySecurity', 'sellTradeMaxPctBySecurity', 'buyTradeMaxAmtBySecurity', 'buyTradeMaxPctBySecurity']},
    {groupId: 'optionTradingGroup', children: ['coveredCallPercent', 'protectivePutPercent']},
    {groupId: 'astroRestrictionsGroup', children: ['astroRestrictionType', 'astroRestrictionMin', 'astroRestrictionMax', 'astroRestrictionTypeMarsValue', 'astroRestrictionMinMarsValue', 'astroRestrictionMaxMarsValue']},
  ];

  createColumnDefs(): void {
    const colDefs = SecurityPreferenceHelper.getSecurityPreferenceColumn();
    this.columnDefs = SecurityPreferenceHelper.applyStandardView(colDefs, this.standardView)
  }

  onModelUpdated(): void {
    if (this.setFilter) {
      this.setFilter = false;
      SecurityPreferenceHelper.onGridModelUpdates(this.gridApi, this.gridContext);
    }
  }

  private refreshESGRestrictedSecurityData(): void {
    if (this.esgRestrictedSecurities.length) {
      this.gridApi.setGridOption('rowData', this.esgRestrictedSecurities);
    }
    this.setFilter = true;
  }

  private initializeGridObjects(): void {
    this.gridOptions = BaseGridConfiguration.generateExtendedFilterGridOptions(this.defaultFilterableGridOptions, this,
      ViewTypeEnum.PortfolioESGRestrictedSecurityPreference, this.refreshESGRestrictedSecurityData.bind(this),
      null, this.savedView);
    (<SideBarDef>this.gridOptions.sideBar).defaultToolPanel = '';
    this.securityPreferencesHiddenMap = SecurityPreferenceHelper.getHideSecurityPreferenceMap(this.preferenceLevel, this.isTLHAlternatePreferenceFFEnabled);
    this.createColumnDefs();
    this.savedView = <ISavedView>{
      parentColumnDefs: this.columnDefs,
      parentGridOptions: this.gridOptions,
      exitWarning: <IExitWarning>{}
    };
    this.showPageContent = true;
  }

  private updateSecurityPreferenceSubscribe(): void {
    this.updateSecurityPreferenceSubscription = this._securityPreferenceHubService.securityPreferences
      .subscribe((securityPreferences: ISecurityPreference[]) => {
        this.securityPreferences = securityPreferences;
        this.securityPreferences.forEach(securityPreference => this.updateSecurityPreferenceData(securityPreference));
        this.updateRowData();
      });
  }

  private updateRowData(): void {
    if (this?.gridApi) {
      this.gridApi.setGridOption('rowData', this.esgRestrictedSecurities);
      this.setFilter = true;
    }
  }

  private updateSecurityPreferences(securityPreferences: ISecurityPreference[]): void {
    this.esgRestrictedSecurities = [];
    SecurityPreferenceHelper.esgSecurityIds = [];
    securityPreferences?.forEach(securityPreference => {
      const preference = this.securityPreferences.find(p => p.id === securityPreference.id);
      if (preference) {
        securityPreference = preference;
      } else {
        securityPreference = SecurityPreferenceHelper.bindSecurityEmptyData(securityPreference);
      }
      SecurityPreferenceHelper.esgSecurityIds.push(securityPreference.id);
      this.esgRestrictedSecurities.push(securityPreference);
    });
  }

  private addOrUpdateSecurityPreferenceSubscribe(): void {
    this.addOrUpdateSecurityPreferenceSubscription = this._securityPreferenceHubService.addUpdateSecurityPreference
      .subscribe((securityPreference: ISecurityPreference) => {
        this.updateSecurityPreferenceData(securityPreference);
        this.updateRowData();
      });
  }

  private updateSecurityPreferenceData(securityPreference: ISecurityPreference): void {
    const index = this.esgRestrictedSecurities.findIndex(p => p.id === securityPreference.id);
    if (index !== -1) {
      this.esgRestrictedSecurities[index] = securityPreference;
    }
  }

  exportToExcelDataSheet(sheetName?: string): string {
    return this.gridApi?.getSheetDataForExcel({ sheetName: sheetName ?? 'ESG Restricted' });
  }
}
