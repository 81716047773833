import { ColDef, GridApi, ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import {
  IEquivalent,
  IHiddenSecurityPreference,
  ISecurityPreference,
  ISecurityPreferenceLevel,
  ISecuritySellBuyPriority
} from '../../../models/preferences/securityPreference';
import { AstroHoldingRestrictionType } from '../../../models/astro';
import { PORTFOLIO_ACCOUNT_SECURITY_TYPE, PREFERENCE_LEVEL } from '../../../libs/app.constants';
import { ExtendedGridFilterToolPanel } from '../../gridextensions/extended-grid-filter.component';
import { ColGroupDef } from '@ag-grid-community/core';
import * as _ from 'lodash';

export interface IStandardViewLayout {
  groupId: string;
  children: string[];
}

export class SecurityPreferenceHelper {
  static securityPreferencesHiddenMap: IHiddenSecurityPreference;
  static preferenceLevel: string;
  static sellPriorityList: any[] = [];
  static buyPriorityList: any[] = [];
  static isSleevePortfolio: boolean;
  static esgSecurityIds: number[] = [];
  private static _securityIdsInModel = new Set<number>();
  static get securityIdsInModel(): Set<number> {
    return this._securityIdsInModel;
  }

  static set securityIdsInModel(value: Set<number>) {
    this._securityIdsInModel = value;
  }

  private static _equivalentIdsInModel = new Set<number>();
  static get equivalentIdsInModel(): Set<number> {
    return this._equivalentIdsInModel;
  }

  static set equivalentIdsInModel(value: Set<number>) {
    this._equivalentIdsInModel = value;
  }

  private static _equivalentIdsInSecurityPreference: Set<number>;
  static get equivalentIdsInSecurityPreference(): Set<number> {
    return this._equivalentIdsInSecurityPreference;
  }

  static set equivalentIdsInSecurityPreference(value: Set<number>) {
    this._equivalentIdsInSecurityPreference = value;
  }

  private static _securitySellBuyPriority: Map<number, ISecuritySellBuyPriority> = new Map<number, ISecuritySellBuyPriority>();
  static get securitySellBuyPriority(): Map<number, ISecuritySellBuyPriority> {
    return this._securitySellBuyPriority;
  }

  static set securitySellBuyPriority(value: Map<number, ISecuritySellBuyPriority>) {
    this._securitySellBuyPriority = value;
  }

  static getSecurityPreferenceColumn(isESGTab = false): ColDef[] {
    const securityDetailsGroup = {
      colId: 'securityDetailsGroup',
      groupId: 'securityDetailsGroup',
      headerName: 'Security Details',
      cellClass: 'text-center',
      children: [
        <ColDef>{
          colId: 'id',
          headerName: 'Security Id',
          field: 'id',
          width: 150,
          cellClass: 'text-center',
          filter: 'agNumberColumnFilter'
        },
        <ColDef>{
          colId: 'securityName',
          headerName: 'Security Name',
          field: 'securityName',
          width: 150,
          cellClass: 'text-center',
          filter: 'agTextColumnFilter'
        },
        <ColDef>{
          colId: 'symbol',
          headerName: 'Symbol',
          field: 'symbol',
          width: 137,
          cellClass: 'text-center',
          filter: 'agTextColumnFilter'
        },
        <ColDef>{
          colId: 'securityType',
          headerName: 'Type',
          field: 'securityType',
          width: 126,
          cellClass: 'text-center',
          filter: 'agTextColumnFilter'
        },
      ]
    };

    if (!isESGTab && !this.securityPreferencesHiddenMap.isHideInModel) {
      securityDetailsGroup.children.push(<ColDef>{
        colId: 'inModel',
        headerName: 'In Model',
        field: 'inModel',
        width: 135,
        valueGetter: this.yesOrNoRendererForInModel,
        cellClass: 'text-center',
        filter: 'agTextColumnFilter'
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideEquivalent) {
      securityDetailsGroup.children.push(<ColDef>{
        colId: 'equivalent',
        headerName: 'Equivalent',
        width: 118,
        headerTooltip: 'Equivalent',
        suppressHeaderMenuButton: true,
        sortable: false,
        valueGetter: SecurityPreferenceHelper.equivalenceValueGetter,
        cellRenderer: SecurityPreferenceHelper.addEquivalenceCellRenderer,
        cellClass: 'text-center ',
        filter: 'agTextColumnFilter'
      });
    }

    securityDetailsGroup.children.push(<ColDef>{
      colId: 'doNotTLH',
      headerName: 'Do Not TLH',
      width: 111,
      headerTooltip: 'Do Not TLH',
      valueGetter: (params: ValueGetterParams) => params.data?.doNotTLH ? 'Yes' : 'No',
      cellClass: 'text-center '
    });

    const columnDefs = [<ColDef>securityDetailsGroup];

    if (!this.securityPreferencesHiddenMap.isHideTrading) {
      columnDefs.push(<ColDef>{
        colId: 'tradingGroup',
        groupId: 'tradingGroup',
        headerName: 'Trading',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'excludeHolding',
            headerName: 'Exclude Holding',
            field: 'excludeHolding',
            width: 133,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },

        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideRedemptionFee) {
      columnDefs.push(<ColDef>{
        colId: 'redemptionFeeGroup',
        groupId: 'redemptionFeeGroup',
        headerName: 'Redemption Fee',
        cellClass: 'text-center',
        children: [
          <ColDef>{
            colId: 'redemptionFeeTypeId',
            headerName: 'Type',
            field: 'redemptionFeeTypeId',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.feeTypeValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'redemptionFeeAmount',
            headerName: 'Amount',
            field: 'redemptionFeeAmount',
            width: 180,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'redemptionFeeDays',
            headerName: 'Days',
            field: 'redemptionFeeDays',
            width: 110,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
        ]
      });
    }

    columnDefs.push(
      <ColDef>{
        colId: 'tradeMinGroup',
        groupId: 'tradeMinGroup',
        headerName: 'Trade MIN',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'sellTradeMinAmtBySecurity',
            headerName: 'Sell Amount',
            field: 'sellTradeMinAmtBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'sellTradeMinPctBySecurity',
            headerName: 'Sell Percent',
            field: 'sellTradeMinPctBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'buyTradeMinAmtBySecurity',
            headerName: 'Buy Amount',
            field: 'buyTradeMinAmtBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'buyTradeMinPctBySecurity',
            headerName: 'Buy Percent',
            field: 'buyTradeMinPctBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
        ]
      },
      <ColDef>{
        colId: 'tradeMaxGroup',
        groupId: 'tradeMaxGroup',
        headerName: 'Trade MAX',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'sellTradeMaxAmtBySecurity',
            headerName: 'Sell Amount',
            field: 'sellTradeMaxAmtBySecurity',
            width: 111,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'sellTradeMaxPctBySecurity',
            headerName: 'Sell Percent',
            field: 'sellTradeMaxPctBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'buyTradeMaxAmtBySecurity',
            headerName: 'Buy Amount',
            field: 'buyTradeMaxAmtBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'buyTradeMaxPctBySecurity',
            headerName: 'Buy Percent',
            field: 'buyTradeMaxPctBySecurity',
            width: 106,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
        ]
      });

    if (!this.securityPreferencesHiddenMap.isHideTradeSettings) {
      columnDefs.push(<ColDef>{
        colId: 'tradeSettingsGroup',
        groupId: 'tradeSettingsGroup',
        headerName: 'Trade Settings',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'sellPriority',
            headerName: 'Sell Priority',
            field: 'sellPriority',
            width: 106,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.sellPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'buyPriority',
            headerName: 'Buy Priority',
            field: 'buyPriority',
            width: 102,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.buyPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideOptionsTrading) {
      columnDefs.push(<ColDef>{
        colId: 'optionTradingGroup',
        groupId: 'optionTradingGroup',
        headerName: 'Option Trading',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'coveredCallPercent',
            headerName: 'Covered Call %',
            field: 'coveredCallPercent',
            width: 131,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'protectivePutPercent',
            headerName: 'Protective Put %',
            field: 'protectivePutPercent',
            width: 136,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          }
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideASTRORestrictions) {
      columnDefs.push(<ColDef>{
        colId: 'astroRestrictionsGroup',
        groupId: 'astroRestrictionsGroup',
        headerName: 'ASTRO Restrictions',
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'astroRestrictionType',
            headerName: this.preferenceLevel !== 'account' ? 'Type' : 'Type (Pref)',
            field: 'astroRestrictionType',
            width: 122,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.astroRestrictionTypeValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'astroRestrictionMin',
            headerName: this.preferenceLevel !== 'account' ? 'Min' : 'Min (Pref)',
            field: 'astroRestrictionMin',
            width: 123,
            cellClass: 'text-center',
            valueGetter: (params) => SecurityPreferenceHelper.astroRestrictionMixMaxRenderer(params, params.data?.astroRestrictionType),
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'astroRestrictionMax',
            headerName: this.preferenceLevel !== 'account' ? 'Max' : 'Max (Pref)',
            field: 'astroRestrictionMax',
            width: 118,
            cellClass: 'text-center',
            valueGetter: (params) => SecurityPreferenceHelper.astroRestrictionMixMaxRenderer(params, params.data?.astroRestrictionType),
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'astroRestrictionTypeMarsValue',
            headerName: 'Type (Astro)',
            field: 'astroRestrictionTypeMarsValue',
            width: 142,
            cellClass: 'text-center',
            hide: this.preferenceLevel !== 'account',
            valueGetter: SecurityPreferenceHelper.astroRestrictionTypeValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'astroRestrictionMinMarsValue',
            headerName: 'Min (Astro)',
            field: 'astroRestrictionMinMarsValue',
            width: 121,
            cellClass: 'text-center',
            hide: this.preferenceLevel !== 'account',
            cellRenderer: (params) => SecurityPreferenceHelper.astroRestrictionMixMaxRenderer(params, params.data.astroRestrictionTypeMarsValue),
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'astroRestrictionMaxMarsValue',
            headerName: 'Max (Astro)',
            field: 'astroRestrictionMaxMarsValue',
            width: 125,
            cellClass: 'text-center',
            hide: this.preferenceLevel !== 'account',
            cellRenderer: (params) => SecurityPreferenceHelper.astroRestrictionMixMaxRenderer(params, params.data.astroRestrictionTypeMarsValue),
            filter: 'agNumberColumnFilter'
          }
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideTaxAlternates) {
      columnDefs.push(<ColDef>{
        colId: 'taxAlternatesGroup',
        groupId: 'taxAlternatesGroup',
        headerName: 'Tax Alternates',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'taxableAlternateSymbol',
            headerName: 'Taxable',
            field: 'taxableAlternate.symbol',
            width: 100,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'taxDeferredAlternateSymbol',
            headerName: 'Tax Deferred',
            field: 'taxDeferredAlternate.symbol',
            width: 110,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'taxExemptAlternateSymbol',
            headerName: 'Tax Exempt',
            field: 'taxExemptAlternate.symbol',
            width: 110,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          }
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideDividendReinvest) {
      columnDefs.push(<ColDef>{
        colId: 'dividendReinvestGroup',
        groupId: 'dividendReinvestGroup',
        headerName: 'Dividend Reinvest',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'taxableDivReInvest',
            headerName: 'Taxable',
            field: 'taxableDivReInvest',
            width: 90,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'taxDefDivReinvest',
            headerName: 'Tax Deferred',
            field: 'taxDefDivReinvest',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'taxExemptDivReinvest',
            headerName: 'Tax Exempt',
            field: 'taxExemptDivReinvest',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideCapitalGainReinvest) {
      columnDefs.push(<ColDef>{
        colId: 'capitalGainReinvestGroup',
        groupId: 'capitalGainReinvestGroup',
        headerName: 'Capital Gain Reinvest',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'capGainReinvestTaxable',
            headerName: 'Taxable',
            field: 'capGainReinvestTaxable',
            width: 90,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'capGainsReinvestTaxDef',
            headerName: 'TaxDeferred',
            field: 'capGainsReinvestTaxDef',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'capGainsReinvestTaxExempt',
            headerName: 'TaxExempt',
            field: 'capGainsReinvestTaxExempt',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.yesOrNoRenderer,
            filter: 'agTextColumnFilter'
          },
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideCustodianSpecificRedemptionFee) {
      columnDefs.push(<ColDef>{
        colId: 'custodianSpecificRedemptionFeeGroup',
        groupId: 'custodianSpecificRedemptionFeeGroup',
        headerName: 'Custodian Specific Redemption Fee',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'custodianRedemptionFeeTypeId',
            headerName: 'Type',
            field: 'custodianRedemptionFeeTypeId',
            width: 110,
            cellClass: 'text-center',
            valueGetter: this.feeTypeValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'custodianRedemptionFeeAmount',
            headerName: 'Amount',
            field: 'custodianRedemptionFeeAmount',
            width: 110,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'custodianRedemptionDays',
            headerName: 'Days',
            field: 'custodianRedemptionDays',
            width: 110,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'custodianRedemptionFeeMaxAmount',
            headerName: 'MaxAmount',
            field: 'custodianRedemptionFeeMaxAmount',
            width: 110,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
          <ColDef>{
            colId: 'custodianRedemptionFeeMinAmount',
            headerName: 'MinAmount',
            field: 'custodianRedemptionFeeMinAmount',
            width: 110,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideTransactionFee) {
      columnDefs.push(<ColDef>{
        colId: 'transactionFeeGroup',
        groupId: 'transactionFeeGroup',
        headerName: 'Transaction Fee',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'sellTransactionFee',
            headerName: 'Sell Transaction Fee',
            field: 'sellTransactionFee',
            width: 90,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'buyTransactionFee',
            headerName: 'Buy Transaction Fee',
            field: 'buyTransactionFee',
            width: 110,
            cellClass: 'text-center',
            filter: 'agTextColumnFilter'
          }
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideTradeSettingsByAccountType) {
      columnDefs.push(<ColDef>{
        colId: 'tradeSettingsByAccountTypeGroup',
        groupId: 'tradeSettingsByAccountTypeGroup',
        headerName: 'Trade Settings By Account Type',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'tSellPriority',
            headerName: 'Taxable Sell Priority',
            field: 'tSellPriority',
            width: 90,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.sellPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'tBuyPriority',
            headerName: 'Taxable Buy Priority',
            field: 'tBuyPriority',
            width: 110,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.buyPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'teSellPriority',
            headerName: 'Tax Exempt Sell Priority',
            field: 'teSellPriority',
            width: 90,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.sellPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'teBuyPriority',
            headerName: 'Tax Exempt Buy Priority',
            field: 'teBuyPriority',
            width: 110,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.buyPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'tdSellPriority',
            headerName: 'Tax Def Sell Priority',
            field: 'tdSellPriority',
            width: 90,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.sellPriorityValueGetter,
            filter: 'agTextColumnFilter'
          },
          <ColDef>{
            colId: 'tdBuyPriority',
            headerName: 'Tax Def Buy Priority',
            field: 'tdBuyPriority',
            width: 110,
            cellClass: 'text-center',
            valueGetter: SecurityPreferenceHelper.buyPriorityValueGetter,
            filter: 'agTextColumnFilter'
          }
        ]
      });
    }

    if (!this.securityPreferencesHiddenMap.isHideMinimumInitialBuy) {
      columnDefs.push(<ColDef>{
        colId: 'minimumInitialBuyGroup',
        groupId: 'minimumInitialBuyGroup',
        headerName: 'Minimum Initial Buy',
        hide: true,
        cellStyle: {text: 'center'},
        children: [
          <ColDef>{
            colId: 'minInitialBuyAmount',
            headerName: 'Min Amount',
            field: 'minInitialBuyAmount',
            width: 150,
            cellClass: 'text-center',
            filter: 'agNumberColumnFilter'
          },
        ]
      });
    }
    return columnDefs;
  }

  static applyStandardView(baseColDefs: ColDef[], standardView: IStandardViewLayout[]): ColDef[] {
    const cloneDefs = <ColGroupDef[]>_.cloneDeep(baseColDefs);
    const result: ColGroupDef[] = [];

    standardView.forEach(standardViewColumn => {
      const sourceGroup = cloneDefs.find(cd => cd.groupId === standardViewColumn.groupId);
      if(!sourceGroup) {
        return;
      }
      const newGroup = _.cloneDeep(sourceGroup);
      newGroup.children = [];
      standardViewColumn.children.forEach(svChildColId => {
        const childCol = sourceGroup.children.find(ch => (<ColDef>ch).colId === svChildColId);
        if (childCol) {
          newGroup.children.push(childCol);
        }
      });
      result.push(newGroup);
    });
    // Up till now we've only created an array of visible columns, but there are still columns that can
    // exist but are not in the standard view.  Those missing columns have to be added to their original
    // parent group.
    // Get all the columns (visible and hidden)
    const allCols = _.flatten(result.map(c => (<ColGroupDef>c).children)).map(c => (<ColDef>c).colId);
    cloneDefs.forEach(gcd => {
      // loop over every available column in the grid, and if it doesn't exist in the standard view,
      // add it as a hidden column so the user has the option to show it.
      (<ColGroupDef>gcd).children.map(c => <ColDef>c).forEach(gch => {
        if(!allCols.includes(gch.colId)) {
          const parentGroup = this.getParentColDef(cloneDefs, gch.colId);
          let targetGroup = result.find(g => g.groupId === parentGroup.groupId);
          // standard view doesn't even contain this group, so add the group but hide it
          if(!targetGroup) {
            targetGroup = _.cloneDeep(parentGroup);
            (<any>targetGroup).hide = true;
            targetGroup.children = [];
            result.push(targetGroup);
          }
          gch.initialHide = true;
          targetGroup.children.push(gch);
        }
      });
    });
    return result;
  }

  static getParentColDef(columns: ColGroupDef[], colId: string): ColGroupDef {
    return columns.find(g => g.children.some(c => (<ColDef>c).colId === colId));
  }

  // TODO: 'tlh_alternate_preference_s1784': Remove parameter 'showTlhAlternates' from the function when feature flag is removed.
  static getHideSecurityPreferenceMap(level: string, showTlhAlternates = false): IHiddenSecurityPreference {
    this.preferenceLevel = level;
    const securityPreferencesWithLevels = SecurityPreferenceHelper.getSecurityPreferencesWithLevels(showTlhAlternates);
    this.securityPreferencesHiddenMap = {
      isHideRedemptionFee: !securityPreferencesWithLevels.redemptionFee.includes(level),
      isHideASTRORestrictions: !securityPreferencesWithLevels.astroRestrictions.includes(level),
      isHideCapitalGainReinvest: !securityPreferencesWithLevels.capitalGainReinvest.includes(level),
      isHideCustodianSpecificRedemptionFee: !securityPreferencesWithLevels.custodianSpecificRedemptionFee.includes(level),
      isHideDividendReinvest: !securityPreferencesWithLevels.dividendReinvest.includes(level),
      isHideMinimumInitialBuy: !securityPreferencesWithLevels.minimumInitialBuy.includes(level),
      isHideOptionsTrading: !securityPreferencesWithLevels.optionsTrading.includes(level),
      isHideTLHAlternate: !securityPreferencesWithLevels.tlhAlternate.includes(level),
      isHideTaxAlternates: !securityPreferencesWithLevels.taxAlternates.includes(level),
      isHideTradeSettings: !securityPreferencesWithLevels.tradeSettings.includes(level),
      isHideTradeSettingsByAccountType: !securityPreferencesWithLevels.tradeSettingsByAccountType.includes(level),
      isHideTrading: !securityPreferencesWithLevels.trading.includes(level),
      isHideTransactionFee: !securityPreferencesWithLevels.transactionFee.includes(level),
      isHideEquivalent: !securityPreferencesWithLevels.equivalent.includes(level),
      isHideEquivalentFromPopup: !securityPreferencesWithLevels.equivalentForPopUp.includes(level),
      isHideInModel: !securityPreferencesWithLevels.inModel.includes(level)
    };
    return this.securityPreferencesHiddenMap;
  }

  static astroRestrictionTypeDisplay(restrictionType: AstroHoldingRestrictionType): string {
    switch (restrictionType) {
      case AstroHoldingRestrictionType.DoNotBuy:
        return 'Do Not Buy';
      case AstroHoldingRestrictionType.DoNotSell:
        return 'Do Not Sell';
      case AstroHoldingRestrictionType.DoNotTrade:
        return 'Do Not Trade';
      case AstroHoldingRestrictionType.BringWeightTo:
      case AstroHoldingRestrictionType.Percent:
        return 'Percent';
      case AstroHoldingRestrictionType.SellAllShares:
        return 'Sell All Shares';
      default:
        return '';
    }
  }

  /** Bind empty data for securities */
  static bindSecurityEmptyData(security: any) {
    return <ISecurityPreference>{
      id: security.id,
      securityName: security.name || security.securityName,
      securityType: security.securityType,
      symbol: security.symbol,
      custodialCash: security.custodialCash,
      redemptionFeeTypeId: null,
      redemptionFeeAmount: null,
      redemptionFeeDays: null,
      sellTradeMinAmtBySecurity: null,
      sellTradeMinPctBySecurity: null,
      buyTradeMinAmtBySecurity: null,
      buyTradeMinPctBySecurity: null,
      buyTradeMaxAmtBySecurity: null,
      buyTradeMaxPctBySecurity: null,
      sellTradeMaxAmtBySecurity: null,
      sellTradeMaxPctBySecurity: null,
      taxableDivReInvest: null,
      taxDefDivReinvest: null,
      taxExemptDivReinvest: null,
      capGainReinvestTaxable: null,
      capGainsReinvestTaxExempt: null,
      capGainsReinvestTaxDef: null,
      sellTransactionFee: null,
      buyTransactionFee: null,
      custodianRedemptionFeeTypeId: null,
      custodianRedemptionDays: null,
      custodianRedemptionFeeAmount: null,
      custodianRedemptionFeeMinAmount: null,
      custodianRedemptionFeeMaxAmount: null,
      excludeHolding: null,
      sellPriority: null,
      buyPriority: null,
      tBuyPriority: 0,
      tSellPriority: 0,
      teBuyPriority: 0,
      teSellPriority: 0,
      tdBuyPriority: 0,
      tdSellPriority: 0,
      minInitialBuyAmount: null,
      alternatives: [],
      tlh: [], /** NEW PREFERENCE */
      doNotTLH: null,
      coveredCallPercent: null,
      protectivePutPercent: null,
      equivalents: []
    };
  }

  static onGridModelUpdates(gridApi: GridApi, gridContext: any): void {
    const savedViewComponent = ExtendedGridFilterToolPanel.getToolPanel(gridApi)?.savedViewComponent;
    if (savedViewComponent && savedViewComponent.model.id > 0) {
      gridApi.setFilterModel(savedViewComponent.filterModel);
      if (!!savedViewComponent.colState) {
        gridApi.applyColumnState(savedViewComponent.colState);
      }
      gridContext.isFilterChanged = true;
    } else {
      gridContext.isFilterChanged = false;
    }
    setTimeout(function () {
      gridContext.isGridModified = false;
    }.bind(this), 0);
  }

  static loadSavedViews(gridApi: GridApi): void {
    const savedViewComponent = ExtendedGridFilterToolPanel.getToolPanel(gridApi)?.savedViewComponent;
    savedViewComponent?.loadSavedViews();
  }

  static updateSellAndBuyPriorityOfSameSecurities(securityPreferences: ISecurityPreference[]): void {
    securityPreferences.forEach(securityPreference => {
      if (securityPreference?.equivalents?.length) {
        for (const equivalent of securityPreference.equivalents) {
          this.updateSellAndBuyPriority(equivalent, true);
        }
      }
      this.updateSellAndBuyPriority(securityPreference);
    });
  }

  static updateSellAndBuyPriority(securityPreference: ISecurityPreference | IEquivalent, isEquivalent = false): void {
    const sellBuyPriority = SecurityPreferenceHelper.securitySellBuyPriority.get(securityPreference.id);
    if (sellBuyPriority) {
      securityPreference.sellPriority = isEquivalent ? sellBuyPriority.sellPriority : sellBuyPriority.sellPriority.id;
      securityPreference.buyPriority = isEquivalent ? sellBuyPriority.buyPriority : sellBuyPriority.buyPriority.id;
    }
  }

  static updateSecuritySellBuyPriority(security: any): void {
    const sellPriority = SecurityPreferenceHelper.sellPriorityList.find(s => security.sellPriority?.id ? s.id === +security.sellPriority?.id : s.id === +security.sellPriority);
    const buyPriority = SecurityPreferenceHelper.buyPriorityList.find(s => security.buyPriority?.id ? s.id === +security.buyPriority?.id : s.id === +security.buyPriority);
    SecurityPreferenceHelper.securitySellBuyPriority.set(security.id, {sellPriority, buyPriority});
  }

  static createSecuritySellBuyPriorityMap(securityPreferences: ISecurityPreference[]): void {
    const securitySellBuyPriorityMap = new Map<number, ISecuritySellBuyPriority>();
    for (const securityPreference of securityPreferences) {
      const sellPriority = SecurityPreferenceHelper.sellPriorityList.find(s => s.id === +securityPreference.sellPriority);
      const buyPriority = SecurityPreferenceHelper.buyPriorityList.find(s => s.id === +securityPreference.buyPriority);
      securitySellBuyPriorityMap.set(securityPreference.id, {sellPriority, buyPriority});
    }
    SecurityPreferenceHelper.securitySellBuyPriority = securitySellBuyPriorityMap;
  }

  static deleteSecurityPreference(id: number, securityPreferences: ISecurityPreference[]): void {
    if (id) {
      const index = securityPreferences.findIndex(s => s.id === id);
      if (index > -1) {
        securityPreferences[index] = SecurityPreferenceHelper.bindSecurityEmptyData(securityPreferences[index]);
      }
    }
  }

  private static equivalenceValueGetter(params: any): string {
    if (!params.data) {
      return null;
    }
    const isShowAddEquivalent = SecurityPreferenceHelper.securityIdsInModel?.has(params.data.id) || SecurityPreferenceHelper.isFirmOrTeamLevel();
    if (params?.data?.equivalents?.length && isShowAddEquivalent && !SecurityPreferenceHelper.isSleevePortfolio) {
      const equivalents = params.data.equivalents.map(e => e.symbol).join(',');
      return equivalents;
    } else if (isShowAddEquivalent && !SecurityPreferenceHelper.isSleevePortfolio) {
      return null;
    } else {
      return PORTFOLIO_ACCOUNT_SECURITY_TYPE.NOT_TARGETED;
    }
  }

  private static addEquivalenceCellRenderer(params: any): string {
    let result: string;
    if (!params.node.data) {
      return null;
    }
    const isShowAddEquivalent = SecurityPreferenceHelper.securityIdsInModel?.has(params.data.id) || SecurityPreferenceHelper.isFirmOrTeamLevel();
    if (params?.data?.equivalents?.length && isShowAddEquivalent && !SecurityPreferenceHelper.isSleevePortfolio) {
      const equivalents = params.data.equivalents.map(e => e.symbol).join(',');
      result = `<span><a id = ${params.node.data.id} title="Add Equivalent">${equivalents}</a></span>`;
    } else if (isShowAddEquivalent && !SecurityPreferenceHelper.isSleevePortfolio) {
      result = `<span> <i class="fas fa-plus-square addEquivalenceClass cursor" aria-hidden="true" id = ${params.node.data.id} title="Add Equivalent"></i></span>`;
    } else {
      result = `<span class="equivalent-not-targeted" title="Equivalents can only be added to targeted securities">${PORTFOLIO_ACCOUNT_SECURITY_TYPE.NOT_TARGETED}</span>`;
    }
    return result;
  }

  private static feeTypeValueGetter(params): string {
    if (!params.data) {
      return null;
    }
    const col = params.colDef.field;
    const obj = params.data[col];
    return obj === 1 ? '$' : '%';
  }

  /**Yes no Renderer */
  private static yesOrNoRenderer(params: ICellRendererParams): string {
    if (!params.data) {
      return null;
    }
    const col = params.colDef.field;
    const obj = params.data[col];
    return obj === true ? 'Yes' : obj === false ? 'No' : '';
  }

  private static yesOrNoRendererForInModel(params: ValueGetterParams): string {
    const securityId = params?.data?.id;
    const isEquivalent = SecurityPreferenceHelper.equivalentIdsInSecurityPreference?.has(securityId) || SecurityPreferenceHelper.equivalentIdsInModel?.has(securityId);
    return SecurityPreferenceHelper.securityIdsInModel?.has(securityId) ? 'Yes'
      : isEquivalent ? PORTFOLIO_ACCOUNT_SECURITY_TYPE.EQUIVALENT : 'No';
  }

  /**sell priority Renderer */
  private static sellPriorityValueGetter(params): string {
    if (!params.data) {
      return null;
    }
    let result = '';
    const col = params.colDef.field;
    const obj = params.data[col];
    const sp = SecurityPreferenceHelper.sellPriorityList.filter(x => x.id === +obj);
    result = (sp.length > 0) ? sp[0].displayName : result;
    return result;
  }

  /**buy priority Renderer */
  private static buyPriorityValueGetter(params): string {
    if (!params.data) {
      return null;
    }
    let result = '';
    const col = params.colDef.field;
    const obj = params.data[col];
    const bp = SecurityPreferenceHelper.buyPriorityList.filter(x => x.id === +obj);
    result = (bp.length > 0) ? bp[0].displayName : result;
    return result;
  }

  private static astroRestrictionTypeValueGetter(params): string {
    if (!params.data) {
      return null;
    }
    const col = params.colDef.field;
    const obj = params.data[col];
    const restrictionType = obj as AstroHoldingRestrictionType;
    return SecurityPreferenceHelper.astroRestrictionTypeDisplay(restrictionType);
  }

  private static astroRestrictionMixMaxRenderer(params, restrictionType: AstroHoldingRestrictionType): string {
    if (!params.data) {
      return null;
    }
    const col = params.colDef.field;
    const obj = params.data[col];

    if (restrictionType === AstroHoldingRestrictionType.Percent || restrictionType === AstroHoldingRestrictionType.BringWeightTo) {
      return obj;
    }
    return '';
  }

  // TODO: 'tlh_alternate_preference_s1784': Remove parameter 'showTlhAlternates' from the function when feature flag is removed.
  private static getSecurityPreferencesWithLevels(showTlhAlternates: boolean): ISecurityPreferenceLevel {
    return {
      astroRestrictions: [PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
      capitalGainReinvest: [PREFERENCE_LEVEL.ACCOUNT],
      custodianSpecificRedemptionFee: [PREFERENCE_LEVEL.CUSTODIAN],
      dividendReinvest: [PREFERENCE_LEVEL.ACCOUNT],
      minimumInitialBuy: [PREFERENCE_LEVEL.CUSTODIAN],
      optionsTrading: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM, PREFERENCE_LEVEL.MODEL, PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
      redemptionFee: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.CUSTODIAN, PREFERENCE_LEVEL.TEAM],
      taxAlternates: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM],
      tlhAlternate: showTlhAlternates
        ? [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM]
        : [PREFERENCE_LEVEL.FIRM],
      tradeSettings: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM, PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
      tradeSettingsByAccountType: [PREFERENCE_LEVEL.FIRM],
      trading: [PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
      transactionFee: [PREFERENCE_LEVEL.CUSTODIAN, PREFERENCE_LEVEL.ACCOUNT],
      equivalent: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM, PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
      equivalentForPopUp: [PREFERENCE_LEVEL.FIRM, PREFERENCE_LEVEL.TEAM],
      inModel: [PREFERENCE_LEVEL.PORTFOLIO, PREFERENCE_LEVEL.ACCOUNT],
    };
  }

  static isFirmOrTeamLevel(): boolean {
    return SecurityPreferenceHelper.preferenceLevel === PREFERENCE_LEVEL.FIRM || SecurityPreferenceHelper.preferenceLevel === PREFERENCE_LEVEL.TEAM;
  }
}
