export enum AlertTypeEnum {
  NONE = 0,
  SUCCESS = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4
}

/**
 * model to display on success, info, warning and error messages
 */
export interface IAlert {
  typeId: number;
  type: string;
  code: string;
  message: string;
  description: string;
}
