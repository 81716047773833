import {
  ColDef,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { ITradeImport } from '../../models/tom';
import { BaseGridConfiguration } from '../gridextensions/basegrid';

@Component({
  selector: 'eclipse-file-trade-import',
  templateUrl: './filetradeimport.component.html',
  styleUrls: ['./filetradeimport.component.scss']
})
export class FileTradeImportComponent extends BaseComponent {

  @Input()
  tradeImportData: ITradeImport[];

  gridApi: GridApi<ITradeImport>;
  gridContext = {
    self: this
  };

  fileImportGridOptions: GridOptions;
  fileImportColumnDefs: ColDef[];

  constructor() {
    super();

    this.fileImportGridOptions = {
      ...this.defaultFilterableGridOptions,
      rowClassRules: {
        danger: (params: RowClassParams<ITradeImport>) => { return params.data?.isValid === false; }
      },
      sideBar: {
        toolPanels: [
          {
            ...BaseGridConfiguration.columnsToolPanel,
            toolPanelParams: {
              ...BaseGridConfiguration.toolPanelParamsAllHidden,
              suppressRowGroups: false
            }
          }
        ]
      },
      autoGroupColumnDef: {
        headerName: 'Group',
        cellRenderer: 'agGroupCellRenderer',
        filter: false,
        cellRendererParams: {
          innerRenderer: (params: ICellRendererParams<ITradeImport>) => params.node.key
        }
      }
    };

    this.createColumnDefs();
  }

  createColumnDefs(): void {
    this.fileImportColumnDefs = [
      <ColDef>{
        colId: 'orionAccountId',
        headerName: 'Account ID',
        field: 'orionAccountId',
        width: 100,
        sortable: true,
        filter: 'agTextColumnFilter',
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'accountNumber',
        headerName: 'Account Number',
        field: 'accountNumber',
        width: 150,
        sortable: true,
        filter: 'agTextColumnFilter',
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'action',
        headerName: 'Action',
        field: 'action',
        width: 100,
        sortable: true,
        filter: 'agTextColumnFilter',
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'symbol',
        headerName: 'Ticker',
        field: 'symbol',
        width: 100,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params: ValueGetterParams<ITradeImport>) => params.data?.symbol || params.data?.security,
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'tradeAmount',
        headerName: 'Dollars',
        field: 'tradeAmount',
        width: 100,
        sortable: true,
        filter: 'agTextColumnFilter',
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'tradeShares',
        headerName: 'Shares',
        field: 'tradeShares',
        width: 100,
        sortable: true,
        filter: 'agTextColumnFilter',
        enableRowGroup: true
      },
      <ColDef>{
        colId: 'warningMessage',
        headerName: 'Warning Message',
        field: 'warningMessage',
        width: 500,
        sortable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params: ValueGetterParams<ITradeImport>) => !params.data?.isValid
          ? params.data?.errorMessage ?? params.data?.warningMessage
          : params.data?.warningMessage,
        enableRowGroup: true
      }
    ];
  }

  onGridReady(params: GridReadyEvent<ITradeImport>): void {
    this.gridApi = params.api;
  }

  public exportToExcel(): void {
    const params = <ExcelExportParams>{
      fileName: 'TradeImport.xlsx',
      autoSize: true
    };
    this.gridApi.exportDataAsExcel(params);
  }
}
