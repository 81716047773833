<div class="flex flex-column align-items-end gap-2">
  <div class="inline-flex gap-2">
    <button type="button" pButton (click)="exportToExcel()"
      label="Export to Excel" icon="far fa-fw fa-arrow-circle-down"
      class="p-button-secondary m-b-5">
    </button>
  </div>
</div>
<ag-grid-angular style="height:500px;" class="ag-theme-alpine grid-height-autosize"
  [context]="gridContext"
  [gridOptions]="fileImportGridOptions"
  [columnDefs]="fileImportColumnDefs"
  (gridReady)="onGridReady($event)"
  [rowData]="tradeImportData">
</ag-grid-angular>
