<ng-container *ngIf="portfolio$ | async as portfolio; else loading">
  <eclipse-compare-tool-wrapper [orionConnectFirmId]="portfolio.orionConnectFirmId"
                                [entityType]="compareEntityType"
                                [entityId]="compareEntityId"
                                [portfolioName]="portfolio.name"
                                [portfolioId]="portfolio.id">
  </eclipse-compare-tool-wrapper>
</ng-container>
<ng-template #loading>
  <span>Loading portfolio, please wait</span>
</ng-template>
