<p-dialog header="Warning" [(visible)]="displayMacPopUp && isPortfolioDetailsModelFFEnabled" modal="true"
  class="ui-dialog-md">
  <div *ngIf="(portfolioEditorService.portfolio$ | async)?.isAssignModel">The portfolio has MAC weightings.
    <br />
    <br />
    Press OK to continue
  </div>
  <div *ngIf="!(portfolioEditorService.portfolio$ | async)?.isAssignModel">
    Portfolio ID {{(portfolioEditorService.portfolio$ | async)?.id}} has a Blend Fund set up and will be used for future
    model allocations.
    <br />
  </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-check" (click)="portfolioEditorService.onModelChangeConfirm()"
      label="OK"></button>
  </p-footer>
</p-dialog>
