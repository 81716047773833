import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as Consts from '../libs/app.constants';
import { Utils as Util } from '../core/functions';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { SECURITY_TYPE_ID } from '../libs/security.constants';

@Pipe({
  name: 'itemfilter'
})
@Injectable()
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string, subCategories: any[]): any[] {
    if (!items) {
      return [];
    }
    const preferences = items.filter(it => it[field] === value).sort((a, b) => a.displayOrder - b.displayOrder);

    const preferencesLst: any[] = [];
    subCategories.sort((a, b) => a.order - b.order); // sort() mutates the array
    subCategories.forEach(subCategory => {
      const preferenceBySubCat = preferences.filter(it => it['subCategoryType'] === subCategory.name);
      if (preferenceBySubCat.length > 0) {
        preferenceBySubCat.forEach(element => {
          preferencesLst.push(element);
        });
      }
    });

    const groupedObj = preferencesLst.reduce((prev, cur) => {
      if (!prev[cur['subCategoryType']]) {
        prev[cur['subCategoryType']] = [cur];
      } else {
        prev[cur['subCategoryType']].push(cur);
      }
      return prev;
    }, {});
    return Object.keys(groupedObj).map(key => ({key, value: groupedObj[key]}));
  }
}

@Pipe({
  name: 'itemSort'
})
@Injectable()
export class SortPipe implements PipeTransform {
  transform(items: any[]): any[] {
    if (!items) {
      return [];
    }
    return items.sort((a, b) => a.order - b.order);
  }
}

@Pipe({name: 'orderBy'})
@Injectable()
export class OrderBy implements PipeTransform {
  transform(obj: any, orderField: string): any {
    const orderType = 'ASC';
    obj.sort(function (a, b) {
      if (orderType === 'ASC') {
        if (a[orderField] < b[orderField]) {
          return -1;
        }
        if (a[orderField] > b[orderField]) {
          return 1;
        }
        return 0;
      } else {
        if (a[orderField] < b[orderField]) {
          return 1;
        }
        if (a[orderField] > b[orderField]) {
          return -1;
        }
        return 0;
      }
    });
    return obj;
  }
}

@Pipe({
  name: 'currencyOE'
})
@Injectable()
export class CurrencyOE implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, currencyCode: string = '$', symbolDisplay: string = 'code', digits: string = '1.2-2'): string {
    return this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
  }
}

@Pipe({
  name: 'currencyMB'
})
@Injectable()
export class CurrencyMB implements PipeTransform {
  max: number = 1000000;

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, classNames: string = '', isCash: boolean = false, currencyCode: string = '$', symbolDisplay: string = 'code', digits: string = '1.2-2'): string {
    if (isNaN(value) || value === null) {
      value = 0;
    }
    const isPositive = (value >= 0);
    if (isCash) {
      if (value < 0) {
        value = -value;
      }
      if (value !== 0) {
        classNames += isPositive ? ' text-success' : ' text-warning-light';
      }
    }
    let labelMB = '';
    let label = this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    if (value < this.max && value > -this.max) {
      if (value < 1000 && value > -1000) {
        if (isCash) {
          label = isPositive ? label : `(${label})`;
        }
        return value === 0
          ? `<span class="${classNames}">${label}</span>`
          : `<span title="${value}" class="${classNames}">${label}</span>`;
      }
      const thousands = value / 1000;
      labelMB = `${this.currencyPipe.transform(thousands, currencyCode, symbolDisplay, digits)}k`;
      if (isCash) {
        labelMB = isPositive ? labelMB : `(${labelMB})`;
      }
      return `<span title="${value}" class="${classNames}">${labelMB}</span>`;
    }
    const million = value / this.max;
    if (million >= 1000 || million <= -1000) {
      const billion = million / 1000;
      labelMB = `${this.currencyPipe.transform(billion, currencyCode, symbolDisplay, digits)}b`;
    } else {
      labelMB = `${this.currencyPipe.transform(million, currencyCode, symbolDisplay, digits)}m`;
    }
    if (isCash) {
      labelMB = isPositive ? labelMB : `(${labelMB})`;
    }
    return `<span title="${value}" class="${classNames}">${labelMB}</span>`;
  }
}

@Pipe({
  name: 'numeric'
})
@Injectable()
export class Numeric implements PipeTransform {
  percentage: string = '%';

  transform(value: any, symbol: string = '', showZero: boolean = true): string {
    if (isNaN(value) || value === null) {
      value = 0;
    }
    let label = '0';
    if (value !== 0) {
      if (symbol === this.percentage) {
        label = Util.toFixed(value, Consts.tacticalDecimalEnum.percentageDecimal);
      } else {
        label = Util.toFixed(value, Consts.tacticalDecimalEnum.quantityDecimal);
      }
      return `<span title="${value}">${label}${symbol}</span>`;
    } else {
      return showZero ? `<span>${label}${symbol}</span>` : '';
    }
  }
}

@Pipe({
  name: 'percentage',
  pure: false
})
@Injectable()
export class Percentage implements PipeTransform {
  transform(value: any, showZero: boolean = true, showColor: boolean = false, classNames: string = ''): string {
    const symbol: string = '%';
    if (isNaN(value) || value === null) {
      value = 0;
    }
    let label = '0';
    if (value !== 0) {
      label = Util.toFixed(value, 2);
      if (label.charAt(0) === '-') {
        label = `(${label.substring(1, label.length)}%)`;
        if (showColor) {
          classNames += ' text-warning-light';
        }
        return `<span class ="${classNames}" title="${value}">${label}</span>`;
      } else {
        label = `${label}%`;
        if (showColor) {
          classNames += ' text-success';
        }
        return `<span class ="${classNames}" title="${value}">${label}</span>`;
      }
    } else {
      return showZero ? `<span>${label}${symbol}</span>` : '';
    }
  }
}

const PADDING = '000000';
const CURRENCY = '$';

@Pipe({
  name: 'currencyMillionFilter'
})
@Injectable()
export class CurrencyMillionPipe implements PipeTransform {
  constructor() {
  }

  transform(value: number, fractionSize: number = 2): string {
    return Util.abbreviateCurrency(value, fractionSize);
  }
}

@Pipe({
  name: 'currencyAmountfilter'
})
@Injectable()
export class CurrencyAmountPipe implements PipeTransform {

  transform(value: number): string {
    return Util.currencyScale(value);
  }
}

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    if (value) {
      return Object.keys(value);
    }
    return [];
  }
}

@Pipe({
  name: 'arrayToCSV'
})
export class ArrayToCSVPipe implements PipeTransform {
  transform(value: any[], nameProp: string, separator: string, maxItems: number = 0): any {
    if (!nameProp?.length) {
      console.warn('Property name is required for Array pipe');
    }
    if (!value?.length) {
      return null;
    }
    let values = value.map(item => item[nameProp]);
    if (maxItems) {
      values = values.slice(0, maxItems);
      if (value.length > maxItems) {
        values = values.concat(`and ${value.length - maxItems} more`);
      }
    }
    return values.join(separator ?? ', ');
  }
}

@Pipe({name: 'milliSecondsToTime'})
export class MilliSecondsToTimePipe implements PipeTransform {
  transform(milliSecs): any {
    if (milliSecs) {
      let value = parseInt(milliSecs, 10) / 1000;
      const seconds = Math.floor(value % 60);   // converting value to seconds
      value /= 60;
      const minutes = Math.floor(value % 60);   // converting value to minutes
      value /= 60;
      const hours = Math.floor(value % 24);   // converting value to hours
      return `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(seconds)}:${this.padTime(milliSecs % 1000)}`;
    } else {
      return '00:00:00:00';
    }
  }

  padTime(t) {
    return t < 10 ? `0${t}` : t;
  }
}

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(date, format) {
    return moment(date).format(format);
  }
  transformWithFormat(date, incomingFormat, format) {
    return moment(date, incomingFormat).format(format);
  }
}

@Pipe({
  name: 'amountFormatCurrency'
})
export class AmountFormatCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return '$0.00';
    }
    const currencyFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    if (value < 0) {
      return `(${currencyFormat.format(-1 * value)})`;
    } else {
      return currencyFormat.format(value);
    }
  }
}

@Pipe({name: 'securityEquivalentFilter'})
export class SecurityEquivalentPipe implements PipeTransform {
  // Transform is the new "return function(value, args)" in Angular 1.x
  transform(values: any[], applyFilter: boolean) {
    if (!values) {
      return;
    }
    if (applyFilter && values) {
      const filteredList = values;
      return filteredList.filter((item) => {
        return !(item.isEquivalentSecurity && item.currentShares === 0 && item.currentAmount === 0 && item.postTradeAmount === 0 && item.postTradeShares === 0);
      });
    } else {
      return values;
    }
  }
}

@Pipe({name: 'accountViewFilter', pure: false})
export class AccountViewFilter implements PipeTransform {
  // Transform is the new "return function(value, args)" in Angular 1.x
  transform(values: any[], id: boolean, column: string) {
    if (!values) {
      return;
    }
    if (values && values.length > 0) {
      const account = values.find(acc => acc.id === id);
      if (account) {
        return account[column] ? account[column] : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

@Pipe({
  name: 'toleranceStatus'
})
@Injectable()
export class TolerancePipe implements PipeTransform {
  transform(value: any, classNames: string = ''): string {
    let label = '&nbsp;';
    if (isNaN(value) || value === null) {
      value = 0;
    } else {
      if (value === 0) {
        label = 'Balanced';
        classNames += ' text-success';
      } else if (value === 1) {
        label = 'OOT';
        classNames += ' text-warning-light';
      } else if (value === 2) {
        label = 'In Tolerance';
        classNames += ' text-success';
      }
    }

    return `<span title="${label}" class="${classNames}">${label}</span>`;
  }
}


@Pipe({
  name: 'needValueStatus'
})
@Injectable()
export class NeedValueStatus implements PipeTransform {
  max: number = 1000000;

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, classNames: string = '', status: number = 0, isCash: boolean = false, currencyCode: string = '$', symbolDisplay: string = 'code', digits: string = '1.2-2'): string {
    // 0 - balanced
    // 1- OUT
    // 2 - in tolerance
    if (isNaN(value) || value === null) {
      value = 0;
    }
    const originalValue = JSON.parse(JSON.stringify(value));
    const isPositive = (value >= 0);
    if (isNaN(status) || status === null) {
      status = 0;
    } else {
      if (status === 0 || status === 2) {
        classNames += ' text-success';
      } else if (status === 1) {
        classNames += ' text-warning-light';
      }
    }

    if (isCash) {
      if (value < 0) {
        value = -value;
      }
    }
    let labelMB = '';
    let label = this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    if (value < this.max && value > -this.max) {
      if (value < 1000 && value > -1000) {
        if (isCash) {
          label = isPositive ? label : `(${label})`;
        }
        return `<span title="${originalValue}" class="${classNames}">${label}</span>`;
      }
      const thousands = value / 1000;
      labelMB = `${this.currencyPipe.transform(thousands, currencyCode, symbolDisplay, digits)}k`;
      if (isCash) {
        labelMB = isPositive ? labelMB : `(${labelMB})`;
      }
      return `<span title="${originalValue}" class="${classNames}">${labelMB}</span>`;
    }
    const million = value / this.max;
    if (million >= 1000 || million <= -1000) {
      const billion = million / 1000;
      labelMB = `${this.currencyPipe.transform(billion, currencyCode, symbolDisplay, digits)}b`;
    } else {
      labelMB = `${this.currencyPipe.transform(million, currencyCode, symbolDisplay, digits)}m`;
    }
    if (isCash) {
      labelMB = isPositive ? labelMB : `(${labelMB})`;
    }

    return `<span title="${originalValue}" class="${classNames}">${labelMB}</span>`;
  }
}

@Pipe({
  name: 'percentageWithTolerance'
})
@Injectable()
export class PercentageWithTolerance implements PipeTransform {
  transform(value: any, showZero: boolean = true, showColor: boolean = false, classNames: string = '', status: number = 0): string {
    const symbol: string = '%';
    let statusClassName = '';
    if (isNaN(value) || value === null) {
      value = 0;
    }
    const originalValue = JSON.parse(JSON.stringify(value));
    if (isNaN(status) || status === null) {
      status = 0;
    } else {
      if (status === 0 || status === 2) {
        statusClassName = ' text-success';
      } else if (status === 1) {
        statusClassName = ' text-warning-light';
      }
    }
    let label = '0';
    if (value !== 0) {
      label = Util.toFixed(value, 2);
      if (label.charAt(0) === '-') {
        label = `(${label.substring(1, label.length)}%)`;
        if (showColor) {
          classNames += statusClassName;
        }
        return `<span class ="${classNames}" title="${originalValue}">${label}</span>`;
      } else {
        label = `${label  }%`;
        if (showColor) {
          classNames += statusClassName;
        }
        return `<span class ="${classNames}" title="${originalValue}">${label}</span>`;
      }
    } else {
      if (showColor) {
        classNames += statusClassName;
      }
      return showZero ? `<span class ="${classNames}" title="${originalValue}" >${label}${symbol}</span>` : '';
    }
  }
}

@Pipe({name: 'abs'})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value: number): number {
    return Math.abs(value);
  }
}

@Pipe({name: 'textHighlighting'})
export class TextHighlightingPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(item: string, searchText: string): any {
    if (!item || !searchText) {
      return item;
    }
    item = Util.escapeUnsafeText(item);
    // Regex flags:  g = global (finds all matches), i = case-insensitive search
    const value = item.replace(new RegExp(Util.escapeUnsafeText(searchText), 'gi'), '<strong class="autocomplete-text-highlight">$&</strong>');
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({name: 'subModelNameSplice', pure: false})
export class SubModelNameSplicePipe implements PipeTransform {
  transform(name: string, type: string): string {
    if (name !== undefined && name !== null && typeof name === 'string') {
      const size = (type === 'hover') ? 50 : (type === 'dnd') ? 36 : 30;
      if (name.length > size) {
        name = name.slice(0, size - 1);
        name = `${name  }..`;
      }
    }
    return name;
  }
}

@Pipe({
  name: 'percentageFormat'
})
export class PercentageFormatPipe implements PipeTransform {
  transform(value: number, restrictTo100Percent: boolean = true): string {
    if (!value) {
      return '0.00%';
    }

    if (value < 0) {
      value = value * -1;
      const label = Util.toFixed(value, 2);
      return `(${label})%`;
    } else if (value > 100 && restrictTo100Percent) {
      return '100%';
    } else {
      const label = Util.toFixed(value, 2);
      return `${label}%`;
    }
  }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'disableButton'
})
@Injectable()
export class DisableButtonPipe implements PipeTransform {
  transform<T>(...args: T[]): boolean {
    return args.some( arg => arg );
  }
}

@Pipe({
  name: 'hideTradableMoneyMarketOption'
})
@Injectable()
export class HideTradableMoneyMarketOptionPipe implements PipeTransform {
  transform<T>(securityTypeId: number, symbol: string, ...args: T[]): boolean {
    return securityTypeId === SECURITY_TYPE_ID.OPTION || securityTypeId === SECURITY_TYPE_ID.BOND
      || symbol === Consts.CUSTODIAL_CASH || symbol?.includes(Consts.SECURITY_NAME.SMA) || args.some(arg => arg);
  }
}

@Pipe({
  name: 'indexOf'
})
export class IndexOfPipe implements PipeTransform {
  transform(items:any[] , item:any): any {
    const retVal = items.indexOf(item);

    if (retVal < 0) {
      return -1;
    } else {
      return retVal + 1;
    }
  }
}
