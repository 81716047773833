import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { OEHttpClient } from '../core';
import { IActivities, IMenuNotification, INotificationVM } from '../models/notification';
import { NotifyEventEmitter } from '../core/emitter';
import { HubConnectionBuilder, HubConnection, HubConnectionState, HttpTransportType } from '@microsoft/signalr';
import { AuthService } from '../core';

@Injectable()
export class NotificationService {
  socket: Socket;
  private allNotificationEndPoint = 'v1/notification/notifications/topics/master';
  private subscribeNotificationEndPoint = 'v1/notification/notifications/topics';
  private notificationsEndPoint = 'v1/notification/notifications';
  private readNotificationsEndPoint = 'v1/notification/notifications/read';
  private activityEndPoint = 'v1/notification/activities';
  private unReadNotificationsEndPoint = 'v1/notification/notifications?readStatus=false&top=';
  private readTopNotificationsEndPoint = 'v1/notification/notifications?readStatus=true&top=';
  private readonly _url: string;

  notification: IMenuNotification;
  hubConnection: HubConnection;
  notificationUpdated = new EventEmitter();
  getTradeToolNotification = new NotifyEventEmitter();
  getProgressNotification = new NotifyEventEmitter();
  getNoMergeOCFirmErrMsgValue = new EventEmitter();
  refreshOverviewDashboardEmitter = new EventEmitter();
  analyticsDuartionEmitter = new EventEmitter();
  getAnalyticsStatus = new EventEmitter();
  getAnalyticsCount = new EventEmitter();
  notificationComplete = new EventEmitter();
  lastImportDateEmitter = new EventEmitter();
  tradeFileNotification = new EventEmitter();
  _batchPercentNotification = new EventEmitter();
  _modelUpdateNotification = new EventEmitter();
  blockUpdateNotification = new EventEmitter();

  get analyticsPortfolioStatus(): Subject<INotificationVM> {
    return this._analyticsPortfolioStatus;
  }
  private readonly _analyticsPortfolioStatus: Subject<INotificationVM>;

  get startAndStopFullAnalyticsTimer(): BehaviorSubject<void> {
    return this._startAndStopFullAnalyticsTimer;
  }
  private readonly _startAndStopFullAnalyticsTimer: BehaviorSubject<void>;

  constructor(private _httpClient: OEHttpClient, private _authService: AuthService) {
    this._url = _httpClient.getApiUrl('v2/notify');
    this._authService.token$.subscribe(() => {
      this.stopConnection().then(() => this.startConnection());
    });
    this._analyticsPortfolioStatus = new Subject<INotificationVM>();
    this._startAndStopFullAnalyticsTimer = new BehaviorSubject<void>(null);
  }

  ConnectSocket(_apiEndpoint: string, token: any) {
    this.socket = io(_apiEndpoint,
      {
        // query changed with the upgrade to socket.io
        query: { token: token.eclipse_access_token },
        transports: ['websocket', 'polling']
      });
    console.debug('v1 socket connected');
  }

  private startConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this._url, {
        accessTokenFactory: () => this._authService.getToken().eclipse_access_token,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets })
      .withAutomaticReconnect()
      .build();

    return this.hubConnection
      .start()
      .then(() => console.info('Connection started'))
      .catch(err => {
        console.error('Error starting v2 hub connection', err);
        setTimeout(this.startConnection.bind(this), 10000);
      });
  }

  private stopConnection() {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      return this.hubConnection.stop();
    }
    return Promise.resolve();
  }

  GetSubscribedNotificationTopics() {
    return this._httpClient.getData(this.subscribeNotificationEndPoint);
  }

  getNotifications() {
    const observable = new Observable(observer => {
      this.GetSubscribedNotificationTopics()
        .subscribe(model => {
          this.hubConnection.on('NotificationService', (message: INotificationVM) => {
            observer.next(JSON.stringify(message));
          });

          model.forEach(topic => {
            this.socket.on(topic.code, (data) => {
              observer.next(data);
            });
          });
          return () => {
            this.socket.disconnect();
            this.hubConnection.stop();
          };
        });
    });
    return observable;
  }

  /* Push trade tool notifications to components, where ever the event emitter subscribe has been using */
  pushTradeToolNotification(notification: any) {
    this.getTradeToolNotification.emit(notification);
  }

  /* Push progress notifications to components, where ever event emitter subscribe has been using */
  pushProgressNotification(notification: any) {
    this.getProgressNotification.emit(notification);
  }

  GetAllNotificationTopics() {
    return this._httpClient.getData(this.allNotificationEndPoint);
  }

  SubscribeNotificationTopic(ISubscribeNotificationTopic) {
    return this._httpClient.postData(this.subscribeNotificationEndPoint, ISubscribeNotificationTopic);
  }
  GetTopNotificationList(noOfNotifications: number) {
    return this._httpClient.getData(`${this.notificationsEndPoint}?top=${noOfNotifications}`, false);
  }
  GetNotificationList() {
    return this._httpClient.getData(this.notificationsEndPoint, false);
  }
  ReadNotification(ids) {
    return this._httpClient.updateData(this.readNotificationsEndPoint, ids, false);
  }
  DeleteNotification(id) {
    return this._httpClient.deleteData(`${this.notificationsEndPoint}/${id}`);
  }

  getAllNotificationCategories() {
    return this._httpClient.getData(this.allNotificationEndPoint);
  }

  getAllSubscribeNotificationTopic() {
    return this._httpClient.getData(this.subscribeNotificationEndPoint);
  }

  subscribeNotifications(notifications) {
    return this._httpClient.updateData(this.subscribeNotificationEndPoint, notifications);
  }

  searchNotification(notificationId: number) {
    return this._httpClient.getData(`${this.notificationsEndPoint}?search=${notificationId}`);
  }

  getActivities(topCount: number = 0) {
    if (topCount > 0) {
      return this._httpClient.getData(`${this.activityEndPoint}?top=${topCount}`, false);
    } else {
      return this._httpClient.getData(this.activityEndPoint, false);
    }
  }

  createActivity(activity: IActivities) {
    return this._httpClient.postData(this.activityEndPoint, activity);
  }
  AssignUsersToActivity(id: any, userIds) {
    const data = { userIds: userIds };
    return this._httpClient.postData(`${this.activityEndPoint}/${id}/users`, data);
  }
  getUsersAssignedToActivity(activityId: number) {
    return this._httpClient.getData(`${this.activityEndPoint}/${activityId}/users`);
  }
  updateActivity(activityId: number, activity: any) {
    return this._httpClient.updateData(`${this.activityEndPoint}/${activityId}`, activity);
  }
  publishNotification(notification: any) {
    this.notification = notification;
    this.notificationUpdated.emit(this.notification);
  }
  getMenuNotification() {
    return this.notification;
  }

  getAnalyticsNeedCount() {
    return this._httpClient.getData(`${this.notificationsEndPoint}/analyticNeed`);
  }

  readAllNotifications() {
    return this._httpClient.getData(`${this.notificationsEndPoint}/readAll`);
  }

  deleteAllNotifications() {
    return this._httpClient.deleteData(`${this.notificationsEndPoint}/deleteAll`);
  }

  getAllNotificationsCount() {
    return this._httpClient.getData(`${this.notificationsEndPoint}/count`);
  }

  getTopUnReadNotificationList(noOfNotifications: number) {
    if (noOfNotifications > 0) {
      return this._httpClient.getData(this.unReadNotificationsEndPoint + noOfNotifications, false);
    } else {
      return this._httpClient.getData(this.unReadNotificationsEndPoint, false);
    }
  }
  getTopReadNotificationList(noOfNotifications: number) {
    if (noOfNotifications > 0) {
      return this._httpClient.getData(this.readTopNotificationsEndPoint + noOfNotifications, false);
    } else {
      return this._httpClient.getData(this.readTopNotificationsEndPoint, false);
    }
  }
  /**push/emit value to eclipse component and based on the value, it will show/hide the no merged firms error message */
  emitNoMergeOCFirmErrMsgValue(value: boolean) {
    this.getNoMergeOCFirmErrMsgValue.emit(value);
  }

  /** Get progress notification status by process id */
  getProgressNotificationStatus(processId: string) {
    return this._httpClient.getData(`${this.notificationsEndPoint}/${processId}/status` );
  }

  /**Refresh dashboard when fallback is false */
  refreshOverviewDashboard() {
    return this.refreshOverviewDashboardEmitter.emit();
  }

  /** Get notifications based on search key */
  getNotificationsBySearch(searchKey) {
    return this._httpClient.getData(`${this.notificationsEndPoint}?name=${searchKey}`);
  }

  /** Get activities based on search key */
  getActivitiesBySearch(searchKey) {
    return this._httpClient.getData(`${this.activityEndPoint}?name=${searchKey}`);
  }

  /** Disconnecting current web socket   */
  DisconnectSocket() {
    this.socket?.disconnect();
    console.debug('v1 socket disconnected');
  }

  /** Get analytics duration */
  getAnalyticsDuration(notification: any) {
    this.analyticsDuartionEmitter.emit(notification);
  }

  /** Get analytics status */
  getNeedAnalyticsStatus(notification: any) {
    this.getAnalyticsStatus.emit(notification);
  }

  // get need analytics count
  getNeedAnalyticsCount(notification: any) {
    this.getAnalyticsCount.emit(notification);
  }

  onNotificationComplete() {
    this.notificationComplete.emit();
  }

  /*
    OE-3465 Fixed Last Import Date not updating without browser refresh.
    Created the new emitter for updating the last import date.
  */
  getLastImportDate(notification: any) {
    this.lastImportDateEmitter.emit(notification);
  }

  onTradeFileNotificationComplete(notification) {
    this.tradeFileNotification.emit(notification);
  }

  requestToUpdateBatchPercent(notification) {
    this._batchPercentNotification.emit(notification);
  }

  modelUpdateNotification(notification) {
    this._modelUpdateNotification.emit(notification);
  }

  onBlockUpdateNotification(notification) {
    this.blockUpdateNotification.emit(notification);
  }
}
